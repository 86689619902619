define("manage/components/manage/search-options", ["exports", "@ember/component", "@glimmer/component", "models/transforms/space-search-options", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _spaceSearchOptions, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{this.isDisabled}} @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{this.cancel}} ...attributes>
    <:view>
      <div class="row">
        {{#each this.pairs as |pair|}}
          <div class="col-6 col-md-3 mb-3">
            <div class="text-model-name me-2">
              {{pair.key}}
            </div>
            <div class="fw-bold text-model-search-value">
              {{#if pair.value}}
                {{t "yes"}}
              {{else}}
                {{t "no"}}
              {{/if}}
            </div>
          </div>
        {{/each}}
      </div>
    </:view>
  
    <:edit>
      <div class="row">
        {{#each this.pairs as |pair|}}
          <div class="col-6 col-md-3 mb-3">
            <div class="text-model-name me-2">
              {{pair.key}}
            </div>
            <div class="fw-bold text-model-search-value">
              <Input::Select @list={{this.options}} @value={{pair.value}} @onChange={{fn this.change pair.key}}
                class="value-{{dasherize pair.key}}" />
            </div>
          </div>
        {{/each}}
      </div>
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "7mpCBZxO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,0,[\"isDisabled\"]],[30,3],[30,4],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"col-6 col-md-3 mb-3\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"text-model-name me-2\"],[12],[1,\"\\n            \"],[1,[30,5,[\"key\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"fw-bold text-model-search-value\"],[12],[1,\"\\n\"],[41,[30,5,[\"value\"]],[[[1,\"              \"],[1,[28,[35,4],[\"yes\"],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,4],[\"no\"],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[5]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"col-6 col-md-3 mb-3\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"text-model-name me-2\"],[12],[1,\"\\n            \"],[1,[30,6,[\"key\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"fw-bold text-model-search-value\"],[12],[1,\"\\n            \"],[8,[39,5],[[16,0,[29,[\"value-\",[28,[37,6],[[30,6,[\"key\"]]],null]]]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"options\"]],[30,6,[\"value\"]],[28,[37,7],[[30,0,[\"change\"]],[30,6,[\"key\"]]],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[6]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@editablePanel\",\"@onEdit\",\"pair\",\"pair\"],false,[\"manage/field-container\",\"each\",\"-track-array\",\"if\",\"t\",\"input/select\",\"dasherize\",\"fn\"]]",
    "moduleName": "manage/components/manage/search-options.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageSearchOptionsComponent = _exports.default = (_class = class ManageSearchOptionsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "options", [{
        id: true,
        name: 'yes'
      }, {
        id: false,
        name: 'no'
      }]);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? new _spaceSearchOptions.SpaceSearchOptions();
    }
    get pairs() {
      const list = [];
      for (const key of _spaceSearchOptions.SpaceSearchOptions.fields) {
        list.push({
          key,
          value: this.args.value?.[key] ?? false
        });
      }
      return list;
    }
    change(key, newValue) {
      let value = this.value;
      value[key] = newValue.id;
      this._value = value;
    }
    cancel() {
      this._value = null;
      return this.args.onCancel();
    }
    save() {
      return this.args.onSave?.(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSearchOptionsComponent);
});