define("spaces/services/space-context-menu", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Selection = void 0;
  var _class, _descriptor, _descriptor2, _class2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Selection = _exports.Selection = (_class = class Selection {
    constructor(type, value) {
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      this.type = type;
      this.value = value;
    }
    toJSON() {
      return {
        type: this.type,
        value: this.value
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let SpaceContextMenuService = _exports.default = (_class2 = class SpaceContextMenuService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "contextX", _descriptor3, this);
      _initializerDefineProperty(this, "contextY", _descriptor4, this);
      _initializerDefineProperty(this, "selection", _descriptor5, this);
      _initializerDefineProperty(this, "active", _descriptor6, this);
    }
    get isVisible() {
      return this.contextX && this.contextY;
    }
    clear() {
      localStorage?.removeItem?.("space-clipboard");
      this.selection = null;
    }
    restore() {
      const value = localStorage?.getItem?.("space-clipboard");
      let deserialized;
      try {
        deserialized = JSON.parse(value ?? "");
      } catch (err) {
        this.selection = null;
        return;
      }
      if (deserialized?.type && deserialized?.value) {
        this.selection = new Selection(deserialized.type, deserialized.value);
      }
    }
    store() {
      localStorage?.setItem?.("space-clipboard", JSON.stringify(this.selection));
    }
    handle(id, type, ev) {
      if (!ev?.target.classList.contains("col-selectable") || ev?.target !== ev?.currentTarget) {
        return false;
      }
      this.restore();
      ev.preventDefault();
      this.contextX = ev.clientX;
      this.contextY = ev.clientY;
      this.active = new Selection(id, type);
      return false;
    }
    hide() {
      this.active = null;
      this.contextX = 0;
      this.contextY = 0;
      this.store();
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "contextX", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "contextY", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "restore", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "restore"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "store", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "store"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hide", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "hide"), _class2.prototype)), _class2);
});