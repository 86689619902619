define("manage/components/time-tracking-table", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @value}}
    <table class="table" ...attributes>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Hours</th>
          <th scope="col">Date</th>
          <th scope="col">Details</th>
        </tr>
      </thead>
      <tbody>
        {{#each @value as |record index|}}
          <tr class="row-record">
            <th scope="row">{{sum index 1}}</th>
            <td>{{record.hours}}</td>
            <td>
              <NiceDate @value={{record.date}} />
            </td>
            <td>{{record.details}}</td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  {{else}}
    <div class="alert alert-info alert-time-tracking-empty" role="alert">
      {{@emptyMessage}}
    </div>
  {{/if}}
  */
  {
    "id": "QXUJw1xI",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"table\"],[24,0,\"table\"],[17,2],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,\"scope\",\"col\"],[12],[1,\"#\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,\"scope\",\"col\"],[12],[1,\"Hours\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,\"scope\",\"col\"],[12],[1,\"Date\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,\"scope\",\"col\"],[12],[1,\"Details\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[14,0,\"row-record\"],[12],[1,\"\\n          \"],[10,\"th\"],[14,\"scope\",\"row\"],[12],[1,[28,[35,3],[[30,4],1],null]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,3,[\"hours\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@value\"],[[30,3,[\"date\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,3,[\"details\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-info alert-time-tracking-empty\"],[14,\"role\",\"alert\"],[12],[1,\"\\n    \"],[1,[30,5]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@value\",\"&attrs\",\"record\",\"index\",\"@emptyMessage\"],false,[\"if\",\"each\",\"-track-array\",\"sum\",\"nice-date\"]]",
    "moduleName": "manage/components/time-tracking-table.hbs",
    "isStrictMode": false
  });
  class TimeTrackingTableComponent extends _component2.default {}
  _exports.default = TimeTrackingTableComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TimeTrackingTableComponent);
});