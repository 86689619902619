define("spaces-view/components/page/edit-bar", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="bg-dark">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="page-title text-light m-1">
            {{@value.name}}
  
            {{#unless @value.visibility.isPublic}}
              <FaIcon @icon="eye-slash" />
            {{/unless}}
          </div>
        </div>
        <div class="col text-right page-controls">
          {{#if @value.canEdit}}
            <LinkTo @route="manage.dashboards.dashboard" @model={{@value.visibility.teamId}} class="btn-team-dashboard m-1 btn btn-sm btn-light float-end">
              {{t "team dashboard"}}
            </LinkTo>
  
            <LinkTo @route="manage.dashboards.space" @model={{@value.space.id}} class="btn-space-dashboard m-1 btn btn-sm btn-light float-end">
              {{t "space dashboard"}}
            </LinkTo>
  
            <LinkTo @route="manage.pages.content" @model={{@value.id}} @query={{hash selectedId=@selectedId}} class="btn-edit-page m-1 btn btn-sm btn-light float-end">
              <FaIcon @icon="pen" />
              {{t "edit page"}}
            </LinkTo>
          {{/if}}
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "22Syaeca",
    "block": "[[[10,0],[14,0,\"bg-dark\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"page-title text-light m-1\"],[12],[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n\\n\"],[41,[51,[30,1,[\"visibility\",\"isPublic\"]]],[[[1,\"            \"],[8,[39,1],null,[[\"@icon\"],[\"eye-slash\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col text-right page-controls\"],[12],[1,\"\\n\"],[41,[30,1,[\"canEdit\"]],[[[1,\"          \"],[8,[39,3],[[24,0,\"btn-team-dashboard m-1 btn btn-sm btn-light float-end\"]],[[\"@route\",\"@model\"],[\"manage.dashboards.dashboard\",[30,1,[\"visibility\",\"teamId\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,4],[\"team dashboard\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n          \"],[8,[39,3],[[24,0,\"btn-space-dashboard m-1 btn btn-sm btn-light float-end\"]],[[\"@route\",\"@model\"],[\"manage.dashboards.space\",[30,1,[\"space\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,4],[\"space dashboard\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n          \"],[8,[39,3],[[24,0,\"btn-edit-page m-1 btn btn-sm btn-light float-end\"]],[[\"@route\",\"@model\",\"@query\"],[\"manage.pages.content\",[30,1,[\"id\"]],[28,[37,5],null,[[\"selectedId\"],[[30,2]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,1],null,[[\"@icon\"],[\"pen\"]],null],[1,\"\\n            \"],[1,[28,[35,4],[\"edit page\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@value\",\"@selectedId\"],false,[\"unless\",\"fa-icon\",\"if\",\"link-to\",\"t\",\"hash\"]]",
    "moduleName": "spaces-view/components/page/edit-bar.hbs",
    "isStrictMode": false
  });
  class PageEditBarComponent extends _component2.default {}
  _exports.default = PageEditBarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageEditBarComponent);
});