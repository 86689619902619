define("spaces-view/components/view/event/location", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "models/transforms/page-col-list", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _pageColList, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord @isEditor={{@isEditor}} @path={{@path}} @model={{@model}} @value={{@value}} @onLoad={{this.loaded}}>
    {{#if this.textLocation}}
      <TextWithOptions @tag="div" class="event-location location-text" @deviceSize={{@deviceSize}} @options={{@value.data.title}}>
        <MarkDown @value={{this.textLocation}}/>
      </TextWithOptions>
    {{/if}}
  
    {{#if this.featureSource}}
      <View::Base::SourceRecord @isEditor={{@isEditor}} @model={{@model}} @value={{this.featureSource}} as |feature|>
        <Card::Any @value={{@value}} @record={{feature}} @deviceSize={{@deviceSize}} />
      </View::Base::SourceRecord>
    {{/if}}
  </View::Base::SourceRecord>
  */
  {
    "id": "dBQyo+OU",
    "block": "[[[8,[39,0],null,[[\"@isEditor\",\"@path\",\"@model\",\"@value\",\"@onLoad\"],[[30,1],[30,2],[30,3],[30,4],[30,0,[\"loaded\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"textLocation\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"event-location location-text\"]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,5],[30,4,[\"data\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@value\"],[[30,0,[\"textLocation\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"featureSource\"]],[[[1,\"    \"],[8,[39,0],null,[[\"@isEditor\",\"@model\",\"@value\"],[[30,1],[30,3],[30,0,[\"featureSource\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@value\",\"@record\",\"@deviceSize\"],[[30,4],[30,6],[30,5]]],null],[1,\"\\n    \"]],[6]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@isEditor\",\"@path\",\"@model\",\"@value\",\"@deviceSize\",\"feature\"],false,[\"view/base/source-record\",\"if\",\"text-with-options\",\"mark-down\",\"card/any\"]]",
    "moduleName": "spaces-view/components/view/event/location.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let PageColEventLocationComponent = _exports.default = (_class = class PageColEventLocationComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "space", _descriptor2, this);
      _initializerDefineProperty(this, "fastboot", _descriptor3, this);
      _initializerDefineProperty(this, "pageCols", _descriptor4, this);
      _initializerDefineProperty(this, "record", _descriptor5, this);
    }
    get textLocation() {
      if (this.record?.location?.type != 'Text') {
        return null;
      }
      return this.record?.location?.value;
    }
    get featureSource() {
      if (this.record?.location?.type != 'Feature') {
        return null;
      }
      return new _pageColList.PageCol({
        data: {
          source: {
            model: 'feature',
            id: this.record?.location?.value
          }
        }
      });
    }
    loaded(record) {
      this.record = record;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "space", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pageCols", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "record", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColEventLocationComponent);
});