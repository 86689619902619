define("map/components/map-interactive", ["exports", "@ember/component", "@glimmer/component", "ol/proj", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _proj, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Map::Plain @onReady={{this.mapReady}} as |map|>
    <Map::View
      @map={{map}}
      @onChange={{this.viewChanged}}
      @extentM={{this.extent}}
      @constraintExtent={{this.constraintExtent}}
    />
    <Map::Layer::Base @map={{map}} @value={{this.selectedBaseMap}} />
  
    <Map::Interaction::DoubleClickZoom @map={{map}} @duration={{150}} @delta={{1}} />
  
    {{#if this.userLocation}}
      <Map::Overlay class="zero" @map={{map}} @position={{this.userLocation}}>
        <PulseDot @onClick={{this.resetDrag}} />
      </Map::Overlay>
    {{/if}}
  </Map::Plain>
  
  <div class="d-none" {{did-insert this.setup}} {{did-update this.updateViewCenter this.userLocation}} />
  */
  {
    "id": "/KynTohn",
    "block": "[[[8,[39,0],null,[[\"@onReady\"],[[30,0,[\"mapReady\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@map\",\"@onChange\",\"@extentM\",\"@constraintExtent\"],[[30,1],[30,0,[\"viewChanged\"]],[30,0,[\"extent\"]],[30,0,[\"constraintExtent\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@map\",\"@value\"],[[30,1],[30,0,[\"selectedBaseMap\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@map\",\"@duration\",\"@delta\"],[[30,1],150,1]],null],[1,\"\\n\\n\"],[41,[30,0,[\"userLocation\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"zero\"]],[[\"@map\",\"@position\"],[[30,1],[30,0,[\"userLocation\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],null,[[\"@onClick\"],[[30,0,[\"resetDrag\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[1]]]]],[1,\"\\n\\n\"],[11,0],[24,0,\"d-none\"],[4,[38,7],[[30,0,[\"setup\"]]],null],[4,[38,8],[[30,0,[\"updateViewCenter\"]],[30,0,[\"userLocation\"]]],null],[12],[13]],[\"map\"],false,[\"map/plain\",\"map/view\",\"map/layer/base\",\"map/interaction/double-click-zoom\",\"if\",\"map/overlay\",\"pulse-dot\",\"did-insert\",\"did-update\"]]",
    "moduleName": "map/components/map-interactive.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let MapInteractiveComponent = _exports.default = (_class = class MapInteractiveComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "_baseMaps", _descriptor2, this);
      _initializerDefineProperty(this, "isDragged", _descriptor3, this);
    }
    get baseMaps() {
      return this._baseMaps;
    }
    get selectedBaseMap() {
      return this.baseMaps?.[0];
    }
    get userLocation() {
      if (!this.args.userLocation) {
        return null;
      }
      return (0, _proj.transform)(this.args.userLocation, 'EPSG:4326', 'EPSG:3857');
    }
    updateUserLocation() {
      if (!this.args.userLocation) {
        return;
      }
      const view = this.map.getView();
      view.setZoom(18);
      view.setCenter(this.userLocation);
    }
    changeCenter(e) {
      this.isDragged = true;
    }
    resetDrag() {
      this.isDragged = false;
      this.updateViewCenter();
    }
    mapReady(map) {
      this.map = map;
      this.view = this.map.getView();
      this.updateUserLocation();
      this._changeCenter = e => this.changeCenter(e);
      this.view.on('change:center', this._changeCenter);
    }
    updateViewCenter() {
      if (this.isDragged) {
        return;
      }
      this.view.un('change:center', this._changeCenter);
      this.view.setCenter(this.userLocation);
      this.view.on('change:center', this._changeCenter);
    }
    async setup() {
      this._baseMaps = await this.store.query('base-map', {
        default: true
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_baseMaps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDragged", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "resetDrag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapReady", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mapReady"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateViewCenter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateViewCenter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapInteractiveComponent);
});