define("manage/components/input/geo-json-sources", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-geo-json-sources" {{did-insert this.setup}} ...attributes>
    {{#each this.sources as |source|}}
      <div class="form-check d-inline-block ps-0 me-3">
        <Label @value={{t (concat "geo-json-" source)}} after={{true}} as |id|>
          <input class="form-check-input select-{{source}} ms-2 me-1" type="checkbox" checked={{get this.value source}} id={{id}} {{on "change" (fn this.change source)}} />
        </Label>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "0VZZJ6lY",
    "block": "[[[11,0],[24,0,\"input-geo-json-sources\"],[17,1],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sources\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"form-check d-inline-block ps-0 me-3\"],[12],[1,\"\\n      \"],[8,[39,3],[[16,\"after\",true]],[[\"@value\"],[[28,[37,4],[[28,[37,5],[\"geo-json-\",[30,2]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"input\"],[16,0,[29,[\"form-check-input select-\",[30,2],\" ms-2 me-1\"]]],[16,\"checked\",[28,[37,6],[[30,0,[\"value\"]],[30,2]],null]],[16,1,[30,3]],[24,4,\"checkbox\"],[4,[38,7],[\"change\",[28,[37,8],[[30,0,[\"change\"]],[30,2]],null]],null],[12],[13],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"&attrs\",\"source\",\"id\"],false,[\"did-insert\",\"each\",\"-track-array\",\"label\",\"t\",\"concat\",\"get\",\"on\",\"fn\"]]",
    "moduleName": "manage/components/input/geo-json-sources.hbs",
    "isStrictMode": false
  });
  let InputGeoJsonSourcesComponent = _exports.default = (_class = class InputGeoJsonSourcesComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sources", ['allowGps', 'allowManual', 'allowAddress', 'allowExistingFeature']);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get hasValidValue() {
      return typeof this.args.value?.allowGps == 'boolean';
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      if (this.hasValidValue) {
        return this.args.value;
      }
      return {
        allowGps: true,
        allowManual: true,
        allowAddress: true,
        allowExistingFeature: false
      };
    }
    change(source, event) {
      if (!this._value) {
        this._value = this.value;
      }
      this._value[source] = event.target.checked;
      this.args.onChange?.(this._value);
    }
    setup() {
      if (this.hasValidValue) {
        return;
      }
      this.args.onChange?.(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputGeoJsonSourcesComponent);
});