define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "-view": {
      "360 degree image": "360 degree image",
      "Filter icons by name": "Filter icons by name",
      "Please sign in here.": "Please sign in here.",
      "about you": "About you",
      "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
      "close": "close",
      "contact-form-success": "Your message has been successfully sent. We will reply soon.",
      "currency": {
        "default": "{value} {currency}",
        "eur": "{value}€",
        "gbp": "£{value}",
        "usd": "${value}"
      },
      "date": {
        "info.createdOn": "added on",
        "info.lastChangeOn": "last change on",
        "releaseDate": "released on"
      },
      "day of week": "day of week",
      "days": {
        "long": {
          "friday": "Friday",
          "monday": "Monday",
          "saturday": "Saturday",
          "sunday": "Sunday",
          "thursday": "Thursday",
          "tuesday": "Tuesday",
          "wednesday": "Wednesday",
          "weekday": "Weekday",
          "weekend": "Weekend"
        },
        "short": {
          "friday": "Fr",
          "monday": "Mo",
          "saturday": "Sa",
          "sunday": "Su",
          "thursday": "Th",
          "tuesday": "Tu",
          "wednesday": "We",
          "weekday": "Weekday",
          "weekend": "Weekend"
        }
      },
      "edit page": "edit page",
      "event-repetition": {
        "daily": "daily",
        "monthly": "monthly",
        "norepeat": "one off",
        "weekly": "weekly",
        "yearly": "yearly"
      },
      "event_date": {
        "interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
        "interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_same_day": "**{beginTime}** to **{endTime}**",
        "repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
        "repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginM}** **{beginD}**",
        "repeats_norepeat": "**{beginD} {beginM} {beginY}**",
        "repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
        "repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**"
      },
      "location": "location",
      "make private": "make private",
      "make public": "make public",
      "message-generic-list-empty": "Empty list",
      "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
      "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
      "message-missing-record": "No data source",
      "metrics": {
        "contributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "publicFeatures": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "published sites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "publishedSites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "total sites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalContributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "totalFeatures": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalSites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}"
      },
      "other-option": "Other",
      "other-option-input-label": "Please specify if you selected “Other”.",
      "page-not-found": {
        "contact-admin": "Please contact your administrator if you think this is a mistake.",
        "message": "Make sure the address is correct and the page hasn't moved.",
        "title": "Page Not Found"
      },
      "rotate": "rotate",
      "save the pin location": "save the pin location",
      "search": "search",
      "search for a place": "search for a place",
      "space dashboard": "space dashboard",
      "submit": "submit",
      "survey-registered-users-only-message": "In order to contribute to this survey you need to sign in to your account.",
      "survey-registered-users-only-title": "Authentication required",
      "team dashboard": "team dashboard",
      "visibility-pending": "pending",
      "visibility-private": "private",
      "visibility-public": "public"
    },
    "360 degree image": "360 degree image",
    "API Tokens": "API Tokens",
    "Add more icons": "Add more icons",
    "Add more icons:": "Add more icons:",
    "All records will be added as new.": "All records will be added as new.",
    "An unknown error occurred.": "An unknown error occurred.",
    "App Store": "App Store",
    "Are you sure you want to delete this account?": "Are you sure you want to delete this account?",
    "CAPTCHA": "CAPTCHA",
    "CRS": "CRS",
    "CRS using PROJ format": "CRS using PROJ format",
    "CSV": "CSV",
    "Choose file": "Choose file",
    "Create a team": "Create a team",
    "Create account": "Create account",
    "Current status": "Current status",
    "DTLS v1.0": "DTLS v1.0",
    "Dashboard": "Dashboard",
    "Download CSV file": "Download CSV file",
    "Download on the": "Download on the",
    "E-Mail": "E-Mail",
    "E-Mail service": "E-Mail service",
    "Explore the map": "Explore the map",
    "FAQ": "FAQ",
    "FROM SPACE VIEW": "FROM SPACE VIEW",
    "Filter icons by name": "Filter icons by name",
    "Form": "Form",
    "GET IT ON": "GET IT ON",
    "GeoJson": "GeoJson",
    "GeoPackage": "GeoPackage",
    "Give a few words about the issue": "Give a few words about the issue",
    "Go to the last slide": "Go to the last slide",
    "Google Play": "Google Play",
    "Import to": "Import to",
    "Integrations": "Integrations",
    "Location": "Location",
    "Location Services": "Location Services",
    "Location error": "Location error",
    "Main settings": "Main settings",
    "Masking precision to use on maps for which masking is enabled": "Masking precision to use on maps for which masking is enabled",
    "Nominatim url": "Nominatim web address",
    "Please sign in here.": "Please sign in here.",
    "Public avatar": "Public avatar",
    "Remove user": "Remove user",
    "Replace emails": "Replace emails",
    "Review": "Review",
    "Row": "Row",
    "SMTP": "SMTP",
    "SMTP host": "SMTP host",
    "SMTP port": "SMTP port",
    "Server error": "Server error",
    "Take screenshot": "Take screenshot",
    "Tell us about yourself": "Tell us about yourself",
    "The default icon sets will be used.": "The default icon sets will be used.",
    "The login article can not be edited.": "The login article can not be edited.",
    "The mercator CRS will be used.": "The mercator CRS will be used.",
    "The row is not visible on this size.": "The row is not visible on this size.",
    "The site has no assigned map": "The site has no assigned map",
    "There are no attributions": "There are no attributions",
    "There are no values": "There are no values",
    "There was an unexpected error.": "There was an unexpected error.",
    "Update by": "Update by",
    "What is language locale?": "What is the language locale?",
    "What is the language name?": "What is the language name?",
    "What is the local category?": "What is the local category?",
    "What is the local name?": "What is the local name?",
    "What is the local subcategory?": "What is the local subcategory?",
    "What is your job title?": "What is your job title?",
    "What's next?": "What's next?",
    "What's user name?": "What's your user name?",
    "What's your linkedin name?": "What's your linkedin name?",
    "What's your public name?": "What's your public name?",
    "What's your skype name?": "What's your skype name?",
    "What's your status?": "What's your status?",
    "What's your twitter handler?": "What's your twitter handler?",
    "What's your website url?": "What's your website url?",
    "Where are you located?": "Where are you located?",
    "Where do you want to be contacted?": "Where do you want to be contacted?",
    "Where?": "Where?",
    "You have successfully changed your password.": "You have successfully changed your password.",
    "You have successfully removed your user.": "You have successfully removed your user.",
    "about": "about",
    "about you": "About you",
    "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
    "accent color": "accent color",
    "access": "access",
    "account": "account",
    "account activation": "account activation",
    "account info": "account info",
    "account-info-message": "Here are some info about your account",
    "accuracy": "accuracy",
    "activation-expire-error": "The activation token has expired. Please try again.",
    "add": "add",
    "add a site": "add a site",
    "add another site": "add another site",
    "add features as pending": "add features as pending",
    "add icon": "add icon",
    "add one or more maps": "add one or more maps",
    "add to team": "add to team",
    "add-article-paragraph": "Click here if you would like to add one",
    "add-article-title": "Click here to add a title",
    "added on": "added on",
    "address": "address",
    "admin-delete-account-message": "You can't remove your account, because you are an administrator. Ask another administrator to remove this account, or to remove your administrator rights.",
    "admin-missing-login-article": "There is no article for the login page.",
    "administration": "administration",
    "advanced options": "advanced options",
    "align": "align",
    "align items": "align items",
    "alignment": "alignment",
    "all": "all",
    "all base maps": "all base maps",
    "all icon sets": "all icon sets",
    "all maps": "all maps",
    "all sites": "all sites",
    "all teams": "all teams",
    "all users": "all users",
    "allow custom data bindings": "allow custom data bindings",
    "allow domain change": "allow domain change",
    "allow manage without teams": "allow manage without teams",
    "allow many instances": "allow many instances",
    "allow proposing sites": "allow proposing sites",
    "allow-manage-without-teams-message": "When this option is enabled, any authenticated user can create `teams`, `maps`, `features`, `surveys` and `icons`.",
    "allowed email domains": "allowed email domains",
    "allowed-email-domains-message": "Set a list of the domains that are allowed on this service. The domains must be separated by `,` eg. `mydomain1.com,mydomain2.org`. If the list is empty, all emails will be accepted.",
    "allowed-spaces-domains-message": "Set a list of the domains that are allowed to be used by the spaces. The domains must be separated by `,` eg. `mydomain1.com,mydomain2.org`.",
    "already have an account?": "already have an account?",
    "altitude": "altitude",
    "altitude accuracy": "altitude accuracy",
    "analytics": "analytics",
    "analyze": "analyze",
    "analyzing file": "analyzing file",
    "and": "and",
    "anonymous": "anonymous",
    "answer": "answer",
    "answer-message": {
      "no-contributor-questions": "The survey does not have contributor questions.",
      "no-generic-questions": "The survey does not include any additional general questions."
    },
    "answers": "answers",
    "appearance": "appearance",
    "approve": "approve",
    "area": "area",
    "article": "article",
    "article content": "article content",
    "article date": "article date",
    "article title": "article title",
    "article-placeholders": {
      "add-calendar": "This is the calendar related to our project.",
      "add-event": "Presenting our exciting new {name}, carefully curated to bring you a unique and memorable experience. Get ready to be part of something extraordinary!",
      "add-icon": "This is our icon designed by our team.",
      "add-icon-set": "This is our new icon set designed to be used in our new projects.",
      "add-map": "This is the map related to our project.",
      "add-team": "This is our team, dedicated to map all our projects."
    },
    "articles": "articles",
    "as text value": "as text",
    "attribute": "attribute",
    "attributes": "attributes",
    "attributes-options-label": "Options (comma separated values)",
    "attributions": "attributions",
    "authentication type to use": "authentication type to use",
    "author": "author",
    "author-label": "by {name}",
    "auto": "auto",
    "back": "back",
    "back to": "back to {destination}",
    "back to our website": "back to our website",
    "back to search results": "back to search results",
    "background color": "background color",
    "background image": "background image",
    "background map": "background map",
    "backgroundColor": "background color",
    "banner": "banner",
    "base map": "base map",
    "base maps": "base maps",
    "baseline": "baseline",
    "be the first who adds one.": "be the first who adds one.",
    "begins at": "begins at",
    "begins on": "begins on",
    "between": "between",
    "between start and end": "Between `{start}` and `{end}`.",
    "bio": "bio",
    "blocks": "blocks",
    "blue": "blue",
    "boolean-": "unknown",
    "boolean-false": "no",
    "boolean-true": "yes",
    "boolean-undefined": "unknown",
    "border": "border",
    "borderColor": "border color",
    "borderWidth": "border width",
    "bottom": "bottom",
    "breadcrumb": "breadcrumb",
    "browse": "browse",
    "bs-property-bg-position-x": "x",
    "bs-property-bg-position-y": "y",
    "bs-property-bg-repeat": "repeat",
    "bs-property-bg-size": "size",
    "button": "button",
    "button label": "button label",
    "button link": "button link",
    "button type": "button type",
    "buttons": "buttons",
    "by": "by",
    "by address": "by address",
    "calendar": "calendar",
    "calendar-entry-at": "at",
    "calendar-entry-begins-on": "Begins on",
    "calendar-entry-ends-on": "on",
    "calendar-entry-repeats-until": "until",
    "calendar-entry-to": "until",
    "calendar-entry.end-date-before-start": "The event ends before it starts.",
    "calendar-entry.interval-end-date-before-end": "The repetition end date is before the end of the event.",
    "calendars": "calendars",
    "campaign card list": "campaign card list",
    "campaign form": "campaign form",
    "campaign-location-type-address": "by address",
    "campaign-location-type-feature": "select an existing feature",
    "campaign-location-type-gps": "use my current location",
    "campaign-location-type-manual": "choose manually on the map",
    "campaigns": "campaigns",
    "can create events": "can create events",
    "can send newsletters": "can send newsletters",
    "can't upload": "can't upload",
    "cancel": "cancel",
    "cancel publishing": "cancel publishing",
    "cancelled": "cancelled",
    "capture using GPS": "capture using GPS",
    "card list": "card list",
    "card options": "card options",
    "card view": "card view",
    "card-type": "card type",
    "categories": "categories",
    "category": "category",
    "change": "change",
    "change password": "change password",
    "choose manually": "choose manually",
    "choose manually on the map": "choose manually on the map",
    "click here to create a schedule": "click here to create a schedule",
    "click to select an image": "click to select an image",
    "click to select from list": "click to select from list",
    "close": "close",
    "color": "color",
    "color palette": "color palette",
    "colors": "colors",
    "component": "component",
    "component count": "component count",
    "component id": "component id",
    "component-count": "component-count",
    "confirm new password": "confirm new password",
    "confirm your password": "confirm your password",
    "connection info": "connection info",
    "connectionInfo": "connection info",
    "contact form": "contact form",
    "contact-form-success": "Your message has been successfully sent. We will reply soon.",
    "container": "container",
    "container options": "container options",
    "content": "content",
    "contributor": "contributor",
    "contributor questions": "contributor questions",
    "contributors": "contributors",
    "convert": "convert",
    "convert to global component": "convert to global component",
    "copy and paste": "copy and paste",
    "cover": "cover",
    "cover photo": "cover photo",
    "cover photos": "cover photos",
    "cramMd5": "cramMd5",
    "create": "create",
    "create a new API token": "create a new API token",
    "create a new account": "create a new account",
    "create line": "create line",
    "create point": "create point",
    "create polygon": "create polygon",
    "create-first-map": "Your team has no maps.",
    "create-first-map-message": "Create your first map to start adding and visualizing data.",
    "create-map": "Create a map",
    "created by": "created by `{name}`",
    "created by you": "created by `you`",
    "created on": "created on",
    "currency": {
      "default": "{value} {currency}",
      "eur": "{value}€",
      "gbp": "£{value}",
      "usd": "${value}"
    },
    "currency selector": "currency selector",
    "custom geometry": "custom geometry",
    "custom translations": "custom translations",
    "cyan": "cyan",
    "dark": "dark",
    "dashboard": "dashboard",
    "dashboards": "dashboards",
    "data binding": "data binding",
    "data bindings": "data bindings",
    "data source": "data source",
    "date": {
      "info.createdOn": "added on",
      "info.lastChangeOn": "last change on",
      "releaseDate": "released on"
    },
    "date interval": "date interval",
    "date-between": "between `{begin}` and `{end}`",
    "date-on": "on {date}",
    "date-on-detail": "on `{date}`",
    "day": "day",
    "day-Friday": "Friday",
    "day-Monday": "Monday",
    "day-Saturday": "Saturday",
    "day-Sunday": "Sunday",
    "day-Thursday": "Thursday",
    "day-Tuesday": "Tuesday",
    "day-Wednesday": "Wednesday",
    "day-short": {
      "Friday": "Fri",
      "Monday": "Mon",
      "Saturday": "Sat",
      "Sunday": "Sun",
      "Thursday": "Thu",
      "Tuesday": "Tue",
      "Wednesday": "Wed"
    },
    "days": {
      "letter": {
        "friday": "F",
        "monday": "M",
        "sunday": "S",
        "wednesday": "W"
      },
      "long": {
        "friday": "Friday",
        "monday": "Monday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "thursday": "Thursday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "weekday": "Weekday",
        "weekend": "Weekend"
      },
      "short": {
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "Su",
        "thursday": "Th",
        "tuesday": "Tu",
        "wednesday": "We",
        "weekday": "Weekday",
        "weekend": "Weekend"
      }
    },
    "decimals-label": "{value, plural, =0 {0 decimals} =1 {1 decimal} other {# decimals}}",
    "default": "default",
    "default icons": "default icons",
    "default models": "default models",
    "default order": "default order",
    "default-base-maps-used-message": "The default base maps will be used.",
    "delete": "delete",
    "delete account": "{size, plural, =0 {invalid value} =1 {delete account} other {delete # accounts}}",
    "delete all articles": "Delete all articles",
    "delete all campaign-answers": "delete all survey answers",
    "delete all events": "Delete all events",
    "delete all features": "Delete all features",
    "delete all icons": "delete all icons",
    "delete all pages": "Delete all pages",
    "delete article": "{size, plural, =0 {invalid value} =1 {delete article} other {delete # articles}}",
    "delete base map": "{size, plural, =0 {invalid value} =1 {delete base map} other {delete # base maps}}",
    "delete base-map": "delete base-map",
    "delete basemap": "delete basemap",
    "delete calendar": "delete calendar",
    "delete campaign": "delete campaign",
    "delete campaign-answer": "delete survey answer",
    "delete data-binding": "delete data-binding",
    "delete event": "delete event",
    "delete feature": "{size, plural, =0 {invalid value} =1 {delete site} other {delete # sites}}",
    "delete icon": "{size, plural, =0 {invalid value} =1 {delete icon} other {delete # icons}}",
    "delete icon-set": "{size, plural, =0 {invalid value} =1 {delete icon set} other {delete # icon sets}}",
    "delete language": "{size, plural, =0 {invalid value} =1 {delete language} other {delete # languages}}",
    "delete map": "{size, plural, =0 {invalid value} =1 {delete map} other {delete # maps}}",
    "delete non synced records": "delete non synced records",
    "delete page": "delete page",
    "delete presentation": "delete presentation",
    "delete site": "{size, plural, =0 {invalid value} =1 {delete site} other {delete # sites}}",
    "delete space": "delete space",
    "delete survey": "{size, plural, =0 {invalid value} =1 {delete survey} other {delete # surveys}}",
    "delete team": "{size, plural, =0 {invalid value} =1 {delete team} other {delete # teams}}",
    "delete token": "{size, plural, =0 {invalid value} =1 {delete token} other {delete # tokens}}",
    "delete translation": "{size, plural, =0 {invalid value} =1 {delete translation} other {delete # translations}}",
    "delete your account": "delete your account",
    "delete-all-confirmation-message-article": "Are you sure you want to delete all articles?",
    "delete-all-confirmation-message-campaign-answer": "Are you sure you want to delete all answers?",
    "delete-all-confirmation-message-event": "Are you sure you want to delete all events?",
    "delete-all-confirmation-message-feature": "Are you sure you want to delete all features?",
    "delete-all-confirmation-message-icon": "Are you sure you want to delete all icons?",
    "delete-all-confirmation-message-page": "Are you sure you want to delete all pages?",
    "delete-confirmation-message": "{size, plural, =0 {invalid value} =1 {Are you sure you want to delete `{name}`?} other {Are you sure you want to delete `#` items?}}",
    "delete-confirmation-site-success": "The site `{name}` was successfully deleted",
    "description": "description",
    "description template": "description template",
    "deselect icon": "deselect icon",
    "desktop": "desktop",
    "desktop options": "desktop options",
    "destination": "destination",
    "details": "details",
    "didn't receive a confirmation email?": "didn't receive a confirmation email?",
    "dimensions": "dimensions",
    "disabled": "disabled",
    "display": "display",
    "display heading": "display heading",
    "display mode": "display mode",
    "display name": "display name",
    "do not update": "do not update",
    "domain": "domain",
    "domain name": "domain name",
    "domain-name-invalid-chars": "The domain name must contain only letters and number from the english alphabet.",
    "domain-name-update-message": ".{from} will be replaced with .{to}",
    "domain-status": {
      "invalid-domain": "Looks like your domain name is invalid. Try renaming it and give the setup another go.",
      "needs-admin-setup": "Your domain name is reserved and needs one of our admins to finish the setup. Hang tight until you get a confirmation message.",
      "pending": "Your domain will be ready soon; the setup just hasn’t started yet.",
      "ready": "Your space is ready at the reserved domain.",
      "setting-up": "Your space is being set up at the reserved domain."
    },
    "done": "done",
    "downgrade to regular user": "downgrade to regular user",
    "downgrade user": "downgrade user",
    "downgrade-user-question": "Are you sure you want to downgrade `{name}`?",
    "downgrade-user-success-message": "You have successfully downgraded `{name}` as regular user.",
    "download": "download",
    "download CSV": "download CSV",
    "download GeoJson": "download GeoJson",
    "downscale images for faster upload": "downscale images for faster upload",
    "e-mail": "e-mail",
    "edit": "edit",
    "edit article": "edit article",
    "edit base map": "edit base map",
    "edit calendar": "edit calendar",
    "edit content": "edit content",
    "edit data binding": "edit data binding",
    "edit event": "edit event",
    "edit feature": "edit feature",
    "edit geometry": "edit geometry",
    "edit icon": "edit icon",
    "edit icon set": "edit icon set",
    "edit layout": "edit layout",
    "edit map": "edit map",
    "edit newsletter": "edit newsletter",
    "edit notification": "edit notification",
    "edit page": "edit page",
    "edit presentation": "edit presentation",
    "edit site": "edit site",
    "edit space": "edit space",
    "edit survey": "edit survey",
    "edit team": "edit team",
    "edit the map": "edit the map",
    "edit to add one.": "edit to add one.",
    "edit user": "edit user",
    "edit-article-label": "edit article",
    "edit-label": "edit",
    "edit-model": {
      "created-on": "created on {time}",
      "last-change": "by {name} {time}",
      "title": "Edit {name}"
    },
    "edit-model.created-on": "created on",
    "email": "email",
    "email a new issue": "email a new issue",
    "embedded map": "embedded map",
    "empty": "empty",
    "enable all notifications": "enable all notifications",
    "enable links": "enable links",
    "enable the show more button": "enable the show more button",
    "enabled": "enabled",
    "end": "end",
    "end date": "end date",
    "ended on": "ended on",
    "ends at": "ends at",
    "ends on": "ends on",
    "enter again your new password": "enter again your new password",
    "enter password": "enter password",
    "enter search keywords": "enter search keywords",
    "enter the token name": "enter the token name",
    "enter your e-mail": "enter your e-mail",
    "enter your new password": "enter your new password",
    "entry": "entry",
    "error": "error",
    "errors": "errors",
    "event": "event",
    "event dates": "event dates",
    "event location": "event location",
    "event schedule": "event schedule",
    "event-at": "at",
    "event-repetition": {
      "daily": "daily",
      "monthly": "monthly",
      "norepeat": "one off",
      "weekly": "weekly",
      "yearly": "yearly"
    },
    "event_date.interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
    "event_date.interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_same_day": "**{beginTime}** to **{endTime}**",
    "event_date.repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
    "event_date.repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginD}**",
    "event_date.repeats_norepeat": "**{beginD} {beginM} {beginY}**",
    "event_date.repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
    "event_date.repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**",
    "everything is in order": "everything is in order",
    "existing": "existing",
    "expire date": "expire date",
    "expires on": "expires on",
    "export emails with stats": "export emails with stats",
    "external link": "external link",
    "extra icons": "extra icons",
    "failed_restored_site_alert_message": "Your previous session was not restored because it contains corrupted data.",
    "feature": "feature",
    "feature attributes": "feature attributes",
    "feature campaign contribution button": "feature campaign contribution button",
    "feature clustering": "feature clustering",
    "feature field": "feature field",
    "feature issue button": "feature issue button",
    "feature name prefix": "feature name prefix",
    "feature-on-map": "on",
    "feature-pending-count-message": "{count, plural, =0 {There are no pending feature} =1 {There is a pending feature} other {There are # pending features}}",
    "feature-pending-message": "This feature is pending",
    "feature-success-message": "Your site was saved and it's waiting a review.",
    "feature-success-title": "Your site was saved",
    "feedback-bio": "Tell us about yourself in fewer than 250 characters",
    "feedback-full-name": "Enter your name, so people you know can recognize you",
    "feedback-linkedin": "Your LinkedIn profile name from linkedin.com/in/profilename",
    "feedback-organization": "Who you represent or work for",
    "field matching": "field matching",
    "fields": "fields",
    "fields matching": "fields matching",
    "file": "file",
    "file field": "file field",
    "filter": "filter",
    "filter with more than one icon": "filter with more than one icon",
    "filters": "filters",
    "fix": "fix",
    "font": "font",
    "font styles": "font styles",
    "font-awesome-message": "You can select any free icon from the FontAwesome collection.",
    "font-h1": "Heading 1",
    "font-h2": "Heading 2",
    "font-h3": "Heading 3",
    "font-h4": "Heading 4",
    "font-h5": "Heading 5",
    "font-h6": "Heading 6",
    "font-paragraph": "Paragraph",
    "forgot your password?": "forgot your password?",
    "fragment": "fragment",
    "from": "from",
    "full name": "full name",
    "gallery": "gallery",
    "general": "general",
    "general questions": "general questions",
    "generating preview": "generating preview",
    "geo-json-allowAddress": "allow searching for an address",
    "geo-json-allowExistingFeature": "allow selecting a feature from the map",
    "geo-json-allowGps": "allow gps",
    "geo-json-allowManual": "allow setting a point on the map",
    "geo-location-permission-denied": "This app does not have permission to use your location.",
    "geo-location-permission-timeout": "The time allowed to acquire your location was reached before the information was obtained.",
    "geo-location-permission-unavailable": "The acquisition of your location failed because at least one internal source of position returned an internal error.",
    "geo-location-unknown-error": "Your location is not available due to an unexpected error.",
    "geoJson editor": "geoJson editor",
    "geojson-feature-file-message": "Select a .json, .geojson or .gpx",
    "geometry": "geometry",
    "geometry options": "geometry options",
    "get gpx file": "get gpx file",
    "global": "global",
    "global map page": "global map page",
    "go to homepage": "go to homepage",
    "go to the main page": "go to the main page",
    "green": "green",
    "group": "group",
    "group name": "group name",
    "groups": "groups",
    "grow": "grow",
    "guests": "guests",
    "gutter": "gutter",
    "hamburger menu on": "hamburger menu on",
    "has custom style": "has custom style",
    "heading": "heading",
    "heading picture": "heading picture",
    "heading text": "heading text",
    "height": "height",
    "help": {
      "map": {
        "area": "This is the geographic area your map will cover."
      },
      "survey": {
        "icons": "Those icons will be added automatically to each survey answer, the contributor won't be able to select them."
      }
    },
    "help message": "help message",
    "hidden-contribution-calendar-message": "This contribution calendar is hidden. You can publish it by editing the profile.",
    "hide advanced details": "hide advanced details",
    "hide calendar": "hide calendar",
    "hide details": "hide details",
    "hide on main map": "hide on main map",
    "hide options": "hide options",
    "homepage map extent": "Homepage map extent",
    "horizontal gutters": "horizontal gutters",
    "horizontal line": "horizontal line",
    "hour": "hour",
    "i forgot my password": "i forgot my password",
    "icon": "icon",
    "icon grid with categories": "icon grid with categories",
    "icon list": "icon list",
    "icon name": "icon name",
    "icon set": "icon set",
    "icon sets": "icon sets",
    "icon-inherited-style-message": "This icon is using the icon set style.",
    "icon-label": "icon",
    "icon-missing-attributes-message": "This icon has no attributes.",
    "icon-not-matched-message": "There is no matching icon",
    "icon-set": "icon set",
    "icons": "icons",
    "icons per line": "icons per line",
    "ignored": "ignored",
    "import": "import",
    "import a new file": "import a new file",
    "import emails": "import emails",
    "import file to": "import file to",
    "imported": "imported",
    "indigo": "indigo",
    "inherited attribute": "inherited attribute",
    "inside area": "in `{name}`",
    "integrations": "integrations",
    "invalid vars": "invalid vars",
    "invalid-file-type-message": "You provided an unsupported file type.",
    "invalid-gpx-missing-track-or-route": "The gpx file must contain at least one route or track.",
    "invalid-json-feature-message": "The file contains an invalid geometry. Only Point, LineString, MultiLineString and Polygon are allowed.",
    "invalid-json-feature-type-message": "The file contains a `{type}` geometry. Only Point, LineString, MultiLineString and Polygon are allowed.",
    "invalid-json-file-message": "The file contains an invalid Json",
    "is activated": "is activated",
    "is attribute": "is attribute",
    "is default": "is default",
    "is index": "is index",
    "is private": "is private",
    "is public": "is public",
    "is required": "is required",
    "is template": "is template",
    "is-multi-project-mode-message": "When this option is enabled, the `Browse` section will be available to regular users and it will show all public data.",
    "isVisible": "is visible",
    "issue-count": "{issues, plural, =0 {no issues} =1 {one issue} other {# issues}}",
    "issues": "issues",
    "item": "item",
    "items": "items",
    "job title": "job title",
    "job-title": "{jobTitle} at {organization}",
    "joined time": "joined time",
    "justify content": "justify content",
    "keep it on map when it is small": "keep it on map when it is small",
    "key": "key",
    "label": "label",
    "label style": "label style",
    "landing page": "landing page",
    "language": "language",
    "languages": "languages",
    "last activity": "last activity",
    "last change": "last change",
    "latest version": "latest version",
    "latitude": "latitude",
    "layer": "layer",
    "layer-count": "{count, plural, =0 {no layers} =1 {1 layer} other {# layers}}",
    "layers": "layers",
    "layout": "layout",
    "leaders": "leaders",
    "leave a message to our team": "leave a message to our team",
    "license": "license",
    "light": "light",
    "line": "line",
    "line color": "line color",
    "line height": "line height",
    "line marker": "line marker",
    "lineDash": "line dash",
    "lineHeight": "line height",
    "lines": "lines",
    "link": "link",
    "link list": "link list",
    "linkedin": "linkedin",
    "list": "list",
    "list customization": "list customization",
    "list view": "list view",
    "load more": "load more",
    "local name": "local name",
    "locale": "locale",
    "location": "location",
    "location mode": "location mode",
    "location-city": "City",
    "location-country": "Country",
    "location-postalCode": "Postal code",
    "location-province": "Province/State",
    "login": "login",
    "logo": "logo",
    "longitude": "longitude",
    "mailersend.com": "mailersend.com",
    "mailersend.com key": "MailSend.com key",
    "main picture": "main picture",
    "main title": "main title",
    "make private": "make private",
    "make public": "make public",
    "manage": "manage",
    "manage team": "manage team",
    "manage-section": {
      "deleting": "deleting {name}",
      "map-calendar": "Events from this calendar will use locations from",
      "map-mask": "Features belonging to this map will be masked with features from",
      "map-survey": "Answers from this survey will be added on",
      "no-map-calendar": "Events from this calendar will not use map locations.",
      "no-map-mask": "Features belonging to this map will not be masked.",
      "no-map-survey": "Answers from this survey will not be added to a map.",
      "site-note": "created {time}, {distance} away",
      "unset-team": "This record has no team. You must set one in order to have a valid ownership."
    },
    "map": "map",
    "map extent": "map extent",
    "map feature": "map feature",
    "map files": "map files",
    "map map view": "map map view",
    "map sheet": "map sheet",
    "map view": "map view",
    "maps": "maps",
    "maps options": "maps options",
    "marker": "marker",
    "mask": "mask",
    "mask points": "mask features",
    "masked feature info": "Location masking is applied, so the location displayed publicly will be imprecise.",
    "masking-precision-message": "The new value may take up to 1 hour to update for existing data. The number of decimals set will determine how many decimals are kept from the initial location value. E.g. for a value of `0 decimals`, a position like `32.42587` will become `32`, and for a value of `1 decimal` that same position will become `32.4`.",
    "match based on a field": "match based on a field",
    "member": "member",
    "member of": "member of",
    "member-since": "Member since {time}",
    "members": "members",
    "menu": "menu",
    "menu buttons": "menu buttons",
    "menu functionality": "menu functionality",
    "menu item": "menu item",
    "menu style": "menu style",
    "message": "message",
    "message-alert-attributes-missing": "There are no attributes.",
    "message-alert-duplicate-icon-name": "You are using two icons with the same name: `{name}`. Remove the one from `{iconSet}`.",
    "message-alert-duplicate-icon-name-default": "You are using two icons with the same name: `{name}`. Remove the one from the default list.",
    "message-alert-duplicate-icon-name-optional": "You are using two icons with the same name: `{name}`. Remove the one from the optional list.",
    "message-alert-file-already-imported": "This file was previously imported. Be careful so you don't duplicate the existing records.",
    "message-alert-file-not-analyzed-yet": "This file was not analyzed yet.",
    "message-alert-file-without-preview": "This file cannot be previewed.",
    "message-alert-icon-set-private": "The icon set is private, and this icon is not visible by users that are not part of your team.",
    "message-alert-invalid-connection-info": "The connection configuration is invalid.",
    "message-alert-invalid-connection-info-type": "The selected connection type is not supported yet.",
    "message-alert-license-not-set": "The map has no license. Setting one, helps others to know how they can use the map.",
    "message-alert-map-with-unpublished-basemaps": "This map is using private base maps: `{names}`. Users outside your team will not be able to properly see this map.",
    "message-alert-map-with-unrelated-basemaps": "This map is using base maps belonging to other teams: `{names}`. Fix this issue.",
    "message-alert-map-with-unrelated-iconsets": "This map is using icon sets belonging to other teams: `{names}`. Fix this issue.",
    "message-alert-missing-connection-info-type": "Select a type before setting the connection info.",
    "message-alert-missing-destination": "The destination is not set.",
    "message-alert-missing-fields": "There are no fields to be mapped.",
    "message-alert-page-private": "This page is private.",
    "message-alert-parent-icon-private": "The inherited properties are not accessible by users that are not part of your team, because the parent icon is private.",
    "message-alert-pictures-missing": "There are no pictures.",
    "message-alert-select-icon": "You need to set a picture for this icon.",
    "message-alert-sounds-missing": "There are no sounds.",
    "message-alert-survey-add-notification": "Authenticated contributors will receive a notification on add.",
    "message-alert-survey-no-notifications": "The survey has no notifications set up.",
    "message-copy-paste-emails": "Directly paste in new emails from a spreadsheet or similar list.",
    "message-dns-setup": "To access this domain create a new DNS record",
    "message-dns-verification": "To verify ownership of your domain, add the above key to a TXT record within your DNS configuration.",
    "message-email-count": "{count, plural, =0 {no emails} =1 {one email} other {# emails}}",
    "message-empty-layout": "The layout has no containers. You need to edit the layout before editing the content.",
    "message-event-entry-list-empty": "Unfortunately, there are no scheduled dates for this event at the moment.",
    "message-generic-list-empty": "Empty list",
    "message-import-emails": "Grow your newsletter audience by adding or importing contacts who have already given you permission to contact them.",
    "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
    "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
    "message-missing-record": "No data source",
    "message-newsletter-send-to-all": "Are you sure you want to send this message to all your subscribers?",
    "message-newsletter-unpublish": "Are you sure you want to unpublish this message?",
    "message-no-page": "No page is set.",
    "message-no-schedule": "There is no defined schedule.",
    "message-no-team-description": "The team has no description outlining its members, activities, and goals.",
    "message-not-right-location": "Is there a better location?",
    "message-page-col-empty": "Empty column",
    "message-replace-emails-confirmation": "Are you sure you want to replace the existing emails?",
    "message-reused-gid": "the id is already used by another component",
    "message-selectable-list-empty": "Looks like there’s nothing here. Add a new record to get started!",
    "message-upload-email-file": "Import contacts from a CSV or TXT file.",
    "message-view-min-max-zoom": "The icon is visible between `{minZoom}` and `{maxZoom}` zoom levels.",
    "message.no-cover": "There is no picture.",
    "message.no-selected-icons": "There are no selected icons.",
    "message.no-survey-questions": "This survey has no general questions",
    "metrics": {
      "contributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
      "publicFeatures": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
      "published sites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
      "publishedSites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
      "total sites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
      "totalContributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
      "totalFeatures": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
      "totalSites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}"
    },
    "metrics.publicSites": "metrics.publicSites",
    "min height": "min height",
    "minute": "minute",
    "missing-paragraph-message": "The article has no paragraph.",
    "missing-title-message": "You need to provide a level 1 Heading for the article title.",
    "mobile": "mobile",
    "mobile options": "mobile options",
    "mode": "mode",
    "model": "model",
    "model-items": {
      "category": "category",
      "icon": "icon",
      "iconSet": "icon set",
      "map": "map",
      "subcategory": "subcategory",
      "team": "team"
    },
    "month": {
      "short": {
        "april": "Apr",
        "august": "Aug",
        "december": "Dec",
        "february": "Feb",
        "january": "Jan",
        "july": "Jul",
        "june": "Jun",
        "march": "Mar",
        "may": "May",
        "november": "Nov",
        "october": "Oct",
        "september": "Sep"
      }
    },
    "month-label": "month",
    "mtcaptcha-message": "You can avoid spam traffic by generating a mtCAPTCHA account here:",
    "multi project mode": "multi project mode",
    "name": "name",
    "name and description": "name and description",
    "name used in data exports": "name used in data exports",
    "name-first": "First name",
    "name-last": "Last name",
    "name-salutation": "Salutation",
    "name-title": "Title",
    "never": "never",
    "new": "new",
    "new article": "new article",
    "new base map": "new base map",
    "new calendar": "new calendar",
    "new data binding": "new data binding",
    "new event": "new event",
    "new feature": "new feature",
    "new icon": "new icon",
    "new icon set": "new icon set",
    "new issue": "new issue",
    "new language": "new language",
    "new map": "new map",
    "new message": "new message",
    "new newsletter": "new newsletter",
    "new page": "new page",
    "new paragraph": "New paragraph",
    "new password": "new password",
    "new presentation": "new presentation",
    "new record": "new {name}",
    "new site": "new site",
    "new space": "new space",
    "new survey": "new survey",
    "new team": "new team",
    "new title": "New title",
    "new user can join": "new user can join",
    "new-token-confirm-message": "Make sure you save it - you won't be able to access it again.",
    "new-token-expire-message": "Select the date when your API token will expire.",
    "new-token-message": "Enter a name for this API token so that you remember in the future why you are using it.",
    "newsletter": "newsletter",
    "newsletters": "newsletters",
    "no": "no",
    "no maps available in this area.": "no maps available in this area.",
    "no-answer": "No answer.",
    "no-icon-attributes-message": "The icon has no attributes",
    "no-layers-set-message": "There are no layers set.",
    "nominatim-url-info": "Please make sure you comply to the usage policy of your chosen service. Check out the wiki page at https://wiki.openstreetmap.org/wiki/Nominatim for more info.",
    "none": "none",
    "not published": "not published",
    "not set": "not set",
    "not-found-features-search-message": "Your search - {term} - did not match any features.",
    "not-found-features-suggestion-message": "Try searching on all maps.",
    "not-joined-a-team-user": "{name} does not currently belong to a team.",
    "not-joined-a-team-you": "You are not part of any team.",
    "nothing": "nothing",
    "notifications": "notifications",
    "offsetX": "offset X",
    "offsetY": "offset Y",
    "on all zoom levels": "on all zoom levels",
    "only for administrators": "only for administrators",
    "only one variable is allowed in the path": "only one variable is allowed in the path",
    "only pending": "only `pending`",
    "only published": "only `published`",
    "only unpublished": "only `unpublished`",
    "opacity": "opacity",
    "open": "open",
    "option-panel": {
      "accent color": "accent color",
      "active-color": "active color",
      "allow-address": "allow users to select an address",
      "allow-existing-feature": "allow users to select a feature from the map",
      "allow-gps": "allow users to use their current location",
      "allow-manual": "allow users to select a site on the map",
      "anchor": "anchor",
      "attribute-name": "attribute name",
      "attribute-path": "attribute path",
      "attribute-style": "attribute style",
      "background-color": "background color",
      "border-color": "border color",
      "bs-property-align-items": "align items",
      "bs-property-bg-position-x": "background position x",
      "bs-property-bg-position-y": "background position y",
      "bs-property-bg-repeat": "background repeat",
      "bs-property-bg-size": "background size",
      "bs-property-border-radius": "border radius",
      "bs-property-border-size": "border size",
      "bs-property-border-visibility": "border visibility",
      "bs-property-btn": "style",
      "bs-property-display": "display",
      "bs-property-flex-direction": "direction",
      "bs-property-fw": "font weight",
      "bs-property-justify-content": "justify content",
      "bs-property-line-height": "line height",
      "bs-property-min-lines": "minimum lines height",
      "bs-property-size-btn": "size",
      "bs-property-text": "align",
      "bs-property-text-line-clamp": "line clamp",
      "bs-property-text-size": "size",
      "bs-property-width": "width",
      "button": "button",
      "button-label": "label",
      "button-type": "button type",
      "buttons": "buttons",
      "calendar": "calendar",
      "card-type": "card type",
      "categories": "categories",
      "category-list": "categories",
      "category-style": "category style",
      "change component type": "change component type",
      "color": "color",
      "colors": "colors",
      "component": "component",
      "component-margin": "component margin",
      "container": "container",
      "container-list": "container list",
      "container-properties": "container",
      "content-picker": "content picker",
      "copy": "copy",
      "count": "Count",
      "cover": "cover",
      "css-height": "height",
      "css-size": "size",
      "css-width": "width",
      "currencies": "currencies",
      "currency": "currency",
      "currency-style": "currency style",
      "custom-location-question": "custom location question",
      "custom-question": "custom question",
      "daily": "daily",
      "data-source": "data source",
      "default-category": "default category",
      "default-cover": "default cover",
      "default-value": "default value",
      "description": "description",
      "description-label": "description label",
      "destination": "destination",
      "details": "details",
      "effect": "Effect",
      "email-label": "email label",
      "empty-message": "empty message",
      "enable-full-map-view": "enable full map view",
      "enable-map-interaction": "Map interaction",
      "enable-pinning": "enable pinning",
      "entries": "entries",
      "example-model-id": "example model id",
      "extra-fields": "extra fields",
      "feature-hover": "On feature hover",
      "feature-selection": "On feature selection",
      "filter-index": "filter",
      "flex-config": "flex box configuration",
      "floating-labels": "enable floating labels",
      "fly-over-animation": "Fly over animation",
      "form": "form",
      "full-description": "use full description",
      "gutter": "gutter",
      "heading": "heading",
      "height": "height",
      "hover-color": "hover color",
      "icon-container": "icon container",
      "icon-set": "icon set",
      "icon-style": "icon style",
      "icons": "icons",
      "icons-label": "icons label",
      "image-size": "Image size",
      "input-group": "group input",
      "is-mandatory": "is required",
      "items-per-page": "items per page",
      "justify-content": "justify content",
      "label": "label",
      "label-customization": "label customization",
      "label-success": "success label",
      "layers": "layers",
      "layout": "layout",
      "link": "link",
      "link-name": "link name",
      "list-count": "list count",
      "logo": "logo",
      "map": "map",
      "margin": "margin",
      "max-items": "max items",
      "menu-button-title": "button {index}",
      "menu-button-title-child": "dropdown {index}",
      "message-label": "message label",
      "mode": "Mode",
      "model": "model",
      "model-name": "name",
      "monthly": "monthly",
      "name": "name",
      "name-label": "name label",
      "newsletter": "newsletter",
      "next-date-with-location": "next date with location",
      "next-occurrence": "next occurrence",
      "norepeat": "one time",
      "open-in-new-tab": "open in a new tab",
      "padding": "padding",
      "paddings": "paddings",
      "paragraph": "paragraph",
      "paragraph-index": "paragraph index (counting from 0)",
      "paste": "paste",
      "paste style": "paste style",
      "picture": "picture",
      "picture-options": "picture options",
      "pictures": "pictures",
      "placeholder-text": "placeholder text",
      "position-x": "x",
      "position-y": "y",
      "predefined colors": "predefined colors",
      "price": "price",
      "price-details": "price-details",
      "price-list": "price list",
      "primary-photo": "primary photo",
      "properties": "properties",
      "property": "property",
      "proportion": "proportion",
      "restrict-view": "Restrict view",
      "revisions": "revisions",
      "row-properties": "row",
      "schedule": "schedule",
      "selected color": "selected color",
      "send-to": "send to",
      "server-type": "server type",
      "shades": "shades",
      "show search": "show search",
      "show-base-map-selection": "Show base map selection",
      "show-content-after": "Show the content after",
      "show-photo-list": "show photo list",
      "show-primary-photo-attributions": "show primary photo attributions",
      "show-title": "show title",
      "show-user-location": "Show user location",
      "show-zoom-buttons": "Show zoom buttons",
      "size": "size",
      "size-mode": "size mode",
      "sizing-mode": "mode",
      "slot-name": "slot name",
      "slot-properties": "slot",
      "source": "source",
      "source-field": "source field",
      "source-model-key": "source model key",
      "space": "space",
      "stat-customization": "stat customization",
      "stats": "stats",
      "store-type": "store type",
      "style": "style",
      "styles": "styles",
      "subcategory-style": "subcategory style",
      "subject": "subject",
      "subtitle": "subtitle",
      "text": "text",
      "text-color": "text color",
      "text-font": "font family",
      "text-list": "text list",
      "text-style": "text style",
      "tiled": "tiled",
      "title": "title",
      "unlink global component": "unlink global component",
      "url": "url",
      "use-fixed-value": "value",
      "use-the-current-model": "use the current model",
      "use-the-default-model": "use the default model",
      "use-value-from": "use the value from",
      "value": "value",
      "version": "version",
      "vertical-align": "vertical align",
      "vertical-size": "vertical size",
      "visibility": "visibility",
      "weekly": "weekly",
      "width": "width",
      "with-search": "with search",
      "yearly": "yearly"
    },
    "optional": "optional",
    "optional icons": "optional icons",
    "options": "options",
    "or": "or",
    "or go to the last slide": "or go to the last slide",
    "orange": "orange",
    "order": "order",
    "organization": "organization",
    "original author": "original author",
    "other names": "other names",
    "other-option": "Other",
    "other-option-input-label": "Please specify if you selected “Other”.",
    "overlay": "overlay",
    "owners": "owners",
    "paddings": "paddings",
    "page": "page",
    "page-not-found": {
      "contact-admin": "Please contact your administrator if you think this is a mistake.",
      "message": "Make sure the address is correct and the page hasn't moved.",
      "title": "Page Not Found"
    },
    "pages": "pages",
    "pagination-label": "{current} of {total}",
    "paragraph": "paragraph",
    "parameters": "parameters",
    "parent icon": "parent icon",
    "parent-icon": "parent icon",
    "password": "password",
    "password to use for authentication": "password to use for authentication",
    "password-change-success": "You have successfully changed the password for `{name}`.\"",
    "path": "path",
    "personal settings": "personal settings",
    "photos": "photos",
    "pick a location on the map": "Pick a location on the map",
    "pick it on the map": "Pick it on the map",
    "picture": "picture",
    "pictures": "pictures",
    "pink": "pink",
    "places": "places",
    "plain": "plain",
    "point": "point",
    "points": "points",
    "polygon": "polygon",
    "polygon marker": "polygon marker",
    "polygons": "polygons",
    "position": "position",
    "position-details": "How do you want to select the location?",
    "predefined area": "predefined area",
    "predefined colors": "predefined colors",
    "preferences": "preferences",
    "preparing": "preparing",
    "presentation": "presentation",
    "presentations": "presentations",
    "preview": "preview",
    "preview-import": {
      "alert": "Cannot convert `{key}` to `{destination}` because `{error}`",
      "empty-maps": "The record must have at least one map.",
      "empty-name": "The record has no name.",
      "invalid-position": "The record must contain a valid geojson.",
      "invalid-type": "The record must be an object.",
      "missing-fields": "The record must have all the required fields `{vars}`"
    },
    "price offer": "price offer",
    "primary": "primary",
    "primary background color": "primary background color",
    "primary border color": "primary border color",
    "primary text color": "primary text color",
    "privacy Policy": "privacy Policy",
    "private key": "private key",
    "profile": "profile",
    "profile card": "profile card",
    "profile-current-status-message": "This emoji and message will appear on your profile and throughout the interface.",
    "profile-fill-in-message": "Your public profile is not finished.",
    "profile-fill-in-message-link": "Click here to update your profile.",
    "profile-main-settings-message": "This information will appear on your profile.",
    "profile-public-avatar-message": "You can upload your avatar here.",
    "progress": "progress",
    "promote to admin": "promote to admin",
    "promote user": "promote user",
    "promote-user-question": "Are you sure you want to promote the user `{name}`?",
    "promote-user-success-message": "You have successfully promoted `{name}` as administrator.",
    "property": "property",
    "property name": "property name",
    "proportion": "proportion",
    "propose": "propose",
    "public profile": "public profile",
    "publish": "publish",
    "publish now": "publish now",
    "purple": "purple",
    "question": "question",
    "question-description": "question for the feature description",
    "question-icons": "question for the icons that the contributor can choose",
    "question-message": {
      "duplicated-name": "There is already a question with the same name.",
      "empty-icon-list": "You haven't picked any icons. You need to select at least one icon to make the question relevant.",
      "hidden-description": "The user won't be asked to enter a description.",
      "hidden-email": "The user won't be asked to enter an email.",
      "hidden-icons": "The user won't be asked to pick icons.",
      "hidden-name": "The user won't be asked to enter a name.",
      "hidden-pictures": "The user won't be asked to upload pictures.",
      "hidden-position": "The user won't be asked to set a location.",
      "hidden-sounds": "The user won't be asked to upload sounds.",
      "no-name": "The name is not set. We recommend to use one or two words separated by a dash.",
      "no-question": "The question is not set.",
      "no-type": "The type is not set.",
      "no-user-questions": "There are no questions about the contributor's identity. The answer will be added anonymously."
    },
    "question-name": "question for the feature name",
    "question-pictures": "question for adding pictures",
    "question-position": "question for the feature location",
    "question-sounds": "question for adding sounds",
    "questions": "questions",
    "reCAPTCHA": "reCAPTCHA",
    "read more...": "read more...",
    "recaptcha-message": "You can avoid spam traffic by generating a reCAPTCHA v3 account here:",
    "recent searches": "recent searches",
    "record name": "record name",
    "record-status": {
      "draft": "draft",
      "pending": "pending",
      "sending": "sending",
      "sent": "sent"
    },
    "rectangle": "rectangle",
    "red": "red",
    "redirect": "redirect",
    "redirects": "redirects",
    "register": "register",
    "register here.": "register here.",
    "register-section": {
      "accept-message-privacy-policy": "I accept the privacy policy.",
      "accept-message-terms": "I accept the terms of this service.",
      "message-read-privacy-policy": "Click here to read the privacy policy.",
      "message-read-terms": "Click here to read the terms."
    },
    "register-success-message": "Your account has been created! Check your email for the activation link.",
    "registration": "registration",
    "reject": "reject",
    "release date": "release date",
    "remoteId": "remoteId",
    "remove member": "remove member",
    "remove-member-message": "Are you sure you want to remove `{name}` from the team?",
    "repeat-daily-until": "daily between `{begin}` and `{end}`",
    "repeat-monthly-until": "monthly between `{begin}` and `{end}`",
    "repeat-weekly-until": "weekly between `{begin}` and `{end}`",
    "repeat-yearly-until": "yearly between `{begin}` and `{end}`",
    "repeats": "Repeats",
    "repetition": {
      "daily": "Daily",
      "monthly": "Monthly",
      "norepeat": "No",
      "weekly": "Weekly",
      "yearly": "Yearly"
    },
    "replace existing emails": "replace existing emails",
    "report a problem": "report a problem",
    "report issues on Gitlab": "report issues on Gitlab",
    "request a new one.": "request a new one.",
    "required": "Required",
    "resend e-mail": "resend e-mail",
    "reset": "reset",
    "reset password": "reset password",
    "restored_site_alert_message": "This site was restored from a previous session. You can finish editing it, or you can reset the form.",
    "restored_site_alert_title": "Your site was restored!",
    "revert": "revert",
    "reviewed-on": "on",
    "revisions": "revisions",
    "role": "role",
    "roles-guests": "Guest",
    "roles-leaders": "Leader",
    "roles-members": "Member",
    "roles-owners": "Owner",
    "roles-unknown": "unknown",
    "rotate": "rotate",
    "running": "running",
    "salutation-mr": "Mr.",
    "salutation-mrs": "Mrs.",
    "salutation-ms": "Ms.",
    "salutation-other": "None",
    "save": "save",
    "save as a new copy": "save as a new copy",
    "save the pin location": "save the pin location",
    "scale": "scale",
    "schedule": "schedule",
    "scheduled": "scheduled",
    "scope": "scope",
    "search": "search",
    "search a place by name or enter a text": "search a place by name or enter a text",
    "search an address": "search an address",
    "search by name or email": "search by name or email",
    "search options": "search options",
    "search panel expands all available icons": "search panel expands all available icons",
    "search something here": "search something here",
    "search team": "search team",
    "search-with-results": "search results for « {term} »",
    "search-without-results": "unfortunately we could not find any results for « {term} »",
    "secondary": "secondary",
    "secondary background color": "secondary background color",
    "secondary border color": "secondary border color",
    "secondary text color": "secondary text color",
    "secret key": "secret key",
    "select": "select",
    "select a date from the calendar": "select a date from the calendar",
    "select a file": "select a file",
    "select a value from the list": "select a value from the list",
    "select all": "select all",
    "select file": "select file",
    "select files": "select files",
    "select icons": "select icons",
    "select one or more values from the list": "select one or more values from the list",
    "select users that you want to add...": "select users that you want to add...",
    "selected background color": "selected background color",
    "selected color": "selected color",
    "selected picture": "selected picture",
    "selected text color": "selected text color",
    "selection": {
      "allItems": "all items are selected",
      "clear": "clear",
      "items": "{number, plural, =0 {nothing selected} =1 {one selected item} other {# selected items}}"
    },
    "send": "send",
    "send newsletter": "send newsletter",
    "send test email": "send test email",
    "send to all recipients": "send to all recipients",
    "service available only for registered users": "service available only for registered users",
    "set": "set",
    "set a css color": "set a css color",
    "set a float number": "set a float number",
    "set a list of integers separated by comma": "set a list of integers separated by comma",
    "set an integer number": "set an integer number",
    "set as pending": "set as pending",
    "settings": "settings",
    "shades": "shades",
    "shadow": "shadow",
    "shape": "shape",
    "show advanced details": "show advanced details",
    "show all": "show all",
    "show all features": "show all features",
    "show all icons": "show all icons",
    "show details": "show details",
    "show download links": "show download links",
    "show end slide": "show end slide",
    "show in fullscreen": "show in fullscreen",
    "show intro": "show intro",
    "show less": "show less",
    "show login": "show login",
    "show logo": "show logo",
    "show map": "show map",
    "show more": "show more",
    "show private contributions": "show private contributions",
    "show team page": "show team page",
    "show the contributions calendar": "show the contributions calendar",
    "show the map info box": "show the map info box",
    "show welcome presentation to new users": "Show welcome presentation to new users",
    "showAsLineAfterZoom": "show as line after zoom",
    "side picture": "side picture",
    "sign in": "sign in",
    "sign out": "sign out",
    "site": "site",
    "site key": "site key",
    "sites": "sites",
    "size": "size",
    "skype": "skype",
    "slide": "slide",
    "slide-number": "slide {slideNumber}",
    "slug": "slug",
    "slug-double-dash": "The slug should not contain two consecutive dashes.",
    "slug-generic-invalid": "You can only use lower case characters, numbers and dashes.",
    "slug-invalid-chars": "The slug contains invalid chars.",
    "slug-no-space": "The slug should not contain spaces.",
    "slug-no-uppercase": "The slug should not contain upper case chars.",
    "slug-no-value": "This article has no slug. The id will be used to identify this article.",
    "slug-taken": "The slug is already used by another article.",
    "smtp": "smtp",
    "sounds": "sounds",
    "source": "source",
    "space": "space",
    "space dashboard": "space dashboard",
    "space-needed-message": "It looks like you currently have no spaces available. You need to create a space first.",
    "space-no-custom-font-style-message": "There is no customized font style.",
    "spaces": "spaces",
    "spaces domains": "spaces domains",
    "spotlight-not-data-found": "spotlight-not-data-found",
    "start": "start",
    "start date": "start date",
    "startTLS": "startTLS",
    "started on": "started on",
    "starting start": "Starting `{start}`.",
    "stats": {
      "map-views": "Map views",
      "newsletter-emails": "Emails sent",
      "newsletter-interactions": "Emails interactions",
      "newsletter-open": "Emails opened",
      "newsletter-subscribers": "Subscribers",
      "survey-answer-contributors": "Survey contributors",
      "survey-contributions": "Survey contributions",
      "title": "Stats"
    },
    "status": "status",
    "status message": "status message",
    "store type": "store type",
    "style": "style",
    "styles": "styles",
    "subcategory": "subcategory",
    "subheading": "subheading",
    "subheading text": "subheading text",
    "subject": "subject",
    "submenu item": "submenu item",
    "submit": "submit",
    "submitted": "submitted",
    "submitted-by": "by",
    "submitted-by-anonymous": "by an anonymous user",
    "subscription": {
      "domains": "Domains",
      "expire": "Until",
      "hours": "{value, plural, =0 {none} =1 {one hour} other {# hours}}",
      "name": "Name",
      "remaining-billable-support": "Billable support",
      "remaining-free-support": "Remaining support",
      "title": "Subscription"
    },
    "success": "success",
    "suggest a photo": "suggest a photo",
    "suggest photo": "suggest photo",
    "suggestions": "suggestions",
    "surface": "surface",
    "survey": "survey",
    "survey answer": "survey answer",
    "survey answers": "survey answers",
    "survey card list": "survey card list",
    "survey form": "survey form",
    "survey options": "survey options",
    "survey-answer-review-pending": "This contribution needs a review from some one from the team.",
    "survey-answer-review-processing": "This contribution is being processed.",
    "survey-answer-review-published": "This contribution is approved and published.",
    "survey-answer-review-rejected": "This contribution is rejected.",
    "survey-answer-review-unknown": "This contribution can not be reviewed.",
    "survey-answer-review-unpublished": "This contribution is approved and unpublished.",
    "survey-contribute-map-pending-message": "wants to contribute to",
    "survey-contribute-map-published-message": "contributed to",
    "survey-contribute-map-rejected-message": "did not contribute to",
    "survey-feature-description-disabled": "The user will not be prompted for a description.",
    "survey-feature-description-enabled": "The user will be prompted with the question:",
    "survey-feature-description-label": "description",
    "survey-feature-icons-disabled": "The user will not be prompted to select icons.",
    "survey-feature-icons-enabled": "The user can select at least one icon and will be prompted with the question:",
    "survey-feature-icons-label": "icons",
    "survey-feature-location-enabled": "The user will be asked:",
    "survey-feature-location-label": "location",
    "survey-feature-name-disabled": "The feature name will be generated on submit and the user will not be prompted for a name.",
    "survey-feature-name-enabled": "The user will be prompted with the question:",
    "survey-feature-name-label": "name",
    "survey-feature-name-prefix": "feature name prefix",
    "survey-feature-not-selected": "Before submitting the form, you need to select a feature on the map.",
    "survey-feature-pictures-default": "The user can add photos. They are not mandatory.",
    "survey-feature-pictures-enabled": "The user will be prompted with the question:",
    "survey-feature-pictures-label": "Photos",
    "survey-feature-pictures-mandatory": "The user can add photos. They are required.",
    "survey-location-field-message": "The user will be able to select a site:",
    "survey-locationAddress": "by searching an address",
    "survey-locationExistingFeature": "by using an existing map feature",
    "survey-locationGps": "by using their current location",
    "survey-locationManual": "by selecting a point on a map",
    "survey-no-address-found": "There are no addresses matching your search terms.",
    "survey-optional-icons-label": "This is the icon list that the contributor can select:",
    "survey-optional-icons-question": "Optional icons list:",
    "survey-point-outside-extent": "The selected point is outside the map extent. It might not be interesting for this survey.",
    "survey-registered-users-only-message": "In order to contribute to this survey you need to sign in to your account.",
    "survey-registered-users-only-title": "Authentication required",
    "survey-registration-mandatory-label": "Restrict to registered users",
    "survey-settings-description-label-placeholder": "Enter the label for the description field question",
    "survey-settings-feature-name-prefix-placeholder": "Enter a custom prefix for answers without the name field",
    "survey-settings-icons-label-placeholder": "Enter the label for the icons field question",
    "survey-settings-name-label-placeholder": "Enter the label for the name field question",
    "survey-success-message": "Your answer was saved and it's waiting a review.",
    "survey-success-title": "Your answer was saved",
    "surveys": "surveys",
    "surveys for": "surveys for",
    "tablet": "tablet",
    "tablet options": "tablet options",
    "tagline": "tagline",
    "teal": "teal",
    "team": "team",
    "team dashboard": "team dashboard",
    "team maps": "team maps",
    "team surveys": "team surveys",
    "team-guest-count": "{count, plural, =0 {no guests} =1 {one guest} other {# guests}}",
    "team-member-count": "{count, plural, =0 {no member} =1 {one member} other {# members}}",
    "team-needed-message": "It looks like you currently have no teams available. You need to create a team first.",
    "team-role": {
      "guests": "guest",
      "leaders": "leader",
      "members": "member",
      "owners": "owner",
      "unknown": "unknown"
    },
    "teams": "teams",
    "templates": "templates",
    "terms of Service": "terms of Service",
    "text": "text",
    "the attribute value is invalid.": "the attribute value is invalid.",
    "the attributes will be removed on save.": "the attributes will be removed on save.",
    "the email used to send the emails": "the email used to send the emails",
    "the new password can't be empty": "the new password can't be empty",
    "the new password must have at least 10 chars": "the new password must have at least 10 chars",
    "the passwords do not match": "the passwords do not match",
    "there are no changes": "there are no changes",
    "there are no icons": "there are no icons",
    "there are no pictures": "there are no pictures",
    "there is no date interval set": "There is no date interval set.",
    "there is no description": "there is no description",
    "there is nothing to choose": "there is nothing to choose",
    "this base map has no name": "this base map has no name",
    "this calendar does not have a custom record name": "this calendar does not have a custom record name",
    "this map has no tagline": "this map has no tagline",
    "this message has no subject": "this message has no subject",
    "this page has no layout": "this page has no layout",
    "this presentation has no layout": "this presentation has no layout",
    "this presentation has no name": "this presentation has no name",
    "this site has no pictures.": "this site has no pictures.",
    "this site is not linked to a map.": "this site is not linked to a map.",
    "time interval": "time interval",
    "timeout": "timeout",
    "timezone": "Timezone",
    "title": "title",
    "title and article": "title and article",
    "title color": "title color",
    "title with buttons": "title with buttons",
    "title-import-emails": "How would you like to add emails?",
    "tls": "tls",
    "to": "to",
    "toggle dropdown": "toggle dropdown",
    "top": "top",
    "topmost": "topmost",
    "translate": "translate",
    "translate to": "translate to {language}",
    "translation": "translation",
    "trigger": "trigger",
    "type": "type",
    "type of encryption protocol to use": "type of encryption protocol to use",
    "typography": "typography",
    "unknown": "unknown",
    "unknown error": "unknown error",
    "unpublish": "unpublish",
    "unpublish newsletter": "unpublish newsletter",
    "until end": "Until `{end}`.",
    "unverified": "unverified",
    "update": "update",
    "update fields and get a preview": "update fields and get a preview",
    "update password": "update password",
    "updates": "updates",
    "upload": "upload",
    "upload a file": "upload a file",
    "use my current location": "use my current location",
    "use the parent image": "use the parent image",
    "user": "user",
    "user is admin": "**{name}** is an administrator.",
    "user is regular user": "**{name}** is a regular user.",
    "user name": "user name",
    "user name to use for authentication": "user name to use for authentication",
    "username or public name": "username or public name",
    "users": "users",
    "value": "value",
    "vector tiles url": "vector tiles url",
    "verified": "verified",
    "verify": "verify",
    "vertical gutters": "vertical gutters",
    "view": "view",
    "view articles": "view articles",
    "view features": "view features",
    "view icons": "view icons",
    "view issues": "view issues",
    "view log": "view log",
    "view more": "view more",
    "view site": "view site",
    "view sites": "view sites",
    "view the site in browse mode": "view the site in browse mode",
    "visibility": "visibility",
    "visibility on mobile": "visibility on mobile",
    "visibility options": "visibility options",
    "visibility-pending": "pending",
    "visibility-private": "private",
    "visibility-public": "public",
    "visible": "visible",
    "warning": "warning",
    "website url": "website url",
    "weight": "weight",
    "welcome": "welcome",
    "welcome email": "welcome email",
    "welcome presentation": "welcome presentation",
    "welcome to": "welcome to",
    "what is map name?": "what is the map name?",
    "what is nearby?": "what is nearby?",
    "what is team name?": "what is the team name?",
    "what is the article slug?": "what is the article slug?",
    "what is the calendar name?": "what is the calendar name?",
    "what is the data binding name?": "what is the data binding name?",
    "what is the event name?": "what is the {name} name?",
    "what is the feature name?": "what is the feature name?",
    "what is the icon name?": "what is the icon name?",
    "what is the map tagline?": "what is the map tagline?",
    "what is the message subject?": "what is the message subject?",
    "what is the newsletter description?": "what is the newsletter description?",
    "what is the newsletter name?": "what is the newsletter name?",
    "what is the page name?": "what is the page name?",
    "what is the presentation name?": "what is the presentation name?",
    "what is the presentation slug?": "what is the presentation slug?",
    "what is the set name?": "what is the set name?",
    "what is the space name?": "what is the space name?",
    "what is the survey name?": "what is the survey name?",
    "what's your username?": "what's your username?",
    "when": "when",
    "who owns the picture?": "who owns the picture?",
    "width": "width",
    "with icon": "with the `{name}` icon",
    "with issues": "with issues",
    "with map": "from the `{name}` map",
    "write a few words about the picture": "write a few words about the picture",
    "year": "year",
    "yellow": "yellow",
    "yes": "yes",
    "yes and remove sites": "yes and remove sites",
    "you are already loged in.": "you are already loged in.",
    "you don't have an account?": "you don't have an account?",
    "your API tokens": "your API tokens",
    "your account": "your account",
    "your account was activated.": "your account was activated.",
    "your api key": "your api key",
    "your api token": "your api token",
    "your base maps": "your base maps",
    "your icon sets": "your icon sets",
    "your maps": "your maps",
    "your profile": "your profile",
    "your sites": "your sites",
    "your teams": "your teams"
  }], ["es-es", {
    "-view": {
      "360 degree image": "360 degree image",
      "Filter icons by name": "Filter icons by name",
      "Please sign in here.": "Please sign in here.",
      "about you": "About you",
      "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
      "close": "close",
      "contact-form-success": "Your message has been successfully sent. We will reply soon.",
      "currency": {
        "default": "{value} {currency}",
        "eur": "{value}€",
        "gbp": "£{value}",
        "usd": "${value}"
      },
      "date": {
        "info.createdOn": "added on",
        "info.lastChangeOn": "last change on",
        "releaseDate": "released on"
      },
      "day of week": "day of week",
      "days": {
        "long": {
          "friday": "Friday",
          "monday": "Monday",
          "saturday": "Saturday",
          "sunday": "Sunday",
          "thursday": "Thursday",
          "tuesday": "Tuesday",
          "wednesday": "Wednesday",
          "weekday": "Weekday",
          "weekend": "Weekend"
        },
        "short": {
          "friday": "Fr",
          "monday": "Mo",
          "saturday": "Sa",
          "sunday": "Su",
          "thursday": "Th",
          "tuesday": "Tu",
          "wednesday": "We",
          "weekday": "Weekday",
          "weekend": "Weekend"
        }
      },
      "edit page": "edit page",
      "event-repetition": {
        "daily": "daily",
        "monthly": "monthly",
        "norepeat": "one off",
        "weekly": "weekly",
        "yearly": "yearly"
      },
      "event_date": {
        "interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
        "interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_same_day": "**{beginTime}** to **{endTime}**",
        "repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
        "repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginM}** **{beginD}**",
        "repeats_norepeat": "**{beginD} {beginM} {beginY}**",
        "repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
        "repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**"
      },
      "location": "location",
      "make private": "make private",
      "make public": "make public",
      "message-generic-list-empty": "Empty list",
      "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
      "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
      "message-missing-record": "No data source",
      "metrics": {
        "contributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "publicFeatures": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "published sites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "publishedSites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "total sites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalContributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "totalFeatures": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalSites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}"
      },
      "other-option": "Other",
      "other-option-input-label": "Please specify if you selected “Other”.",
      "page-not-found": {
        "contact-admin": "Please contact your administrator if you think this is a mistake.",
        "message": "Make sure the address is correct and the page hasn't moved.",
        "title": "Page Not Found"
      },
      "rotate": "rotate",
      "save the pin location": "save the pin location",
      "search": "search",
      "search for a place": "search for a place",
      "space dashboard": "space dashboard",
      "submit": "submit",
      "survey-registered-users-only-message": "In order to contribute to this survey you need to sign in to your account.",
      "survey-registered-users-only-title": "Authentication required",
      "team dashboard": "team dashboard",
      "visibility-pending": "pending",
      "visibility-private": "private",
      "visibility-public": "public"
    },
    "360 degree image": "imagen de '360 grados",
    "API Tokens": "API Tokens",
    "Add more icons": "Add more icons",
    "Add more icons:": "Add more icons:",
    "All records will be added as new.": "All records will be added as new.",
    "An unknown error occurred.": "Un error desconocido ha ocurrido.",
    "App Store": "App Store",
    "Are you sure you want to delete this account?": "¿Está seguro que desea borrar esta cuenta?",
    "CAPTCHA": "CAPTCHA",
    "CRS": "CRS",
    "CRS using PROJ format": "CRS usando formato PROJ",
    "CSV": "CSV",
    "Choose file": "Elija el archivo",
    "Create a team": "Crea un equipo",
    "Create account": "Crear cuenta",
    "Current status": "Estado actual",
    "DTLS v1.0": "DTLS v1.0",
    "Dashboard": "Dashboard",
    "Download CSV file": "Download CSV file",
    "Download on the": "Descargar desde",
    "E-Mail": "E-Mail",
    "E-Mail service": "Servicio de correo electrónico",
    "Explore the map": "Explorar el mapa",
    "FAQ": "Preguntas Frecuentes",
    "FROM SPACE VIEW": "FROM SPACE VIEW",
    "Filter icons by name": "Filtrar íconos por nombre",
    "Form": "Form",
    "GET IT ON": "Descargar desde",
    "GeoJson": "GeoJson",
    "GeoPackage": "GeoPackage",
    "Give a few words about the issue": "Describa con pocas palabras el problema",
    "Go to the last slide": "Ir a la última diapositiva",
    "Google Play": "Google Play",
    "Import to": "Importar a",
    "Integrations": "Integrations",
    "Location": "Localización",
    "Location Services": "Servicios de localización",
    "Location error": "Error de ubicación",
    "Main settings": "Ajustes principales",
    "Masking precision to use on maps for which masking is enabled": "Precisión de enmascaramiento para usar en mapas para los que está habilitado el enmascaramiento",
    "Nominatim url": "El URL de Nominatim",
    "Please sign in here.": "Regístrese aquí.",
    "Public avatar": "Avatar público",
    "Remove user": "Remover usuario",
    "Replace emails": "Replace emails",
    "Review": "Review",
    "Row": "Fila",
    "SMTP": "SMTP",
    "SMTP host": "SMTP host",
    "SMTP port": "SMTP port",
    "Server error": "Error en el servidor",
    "Take screenshot": "Captura de pantalla",
    "Tell us about yourself": "Cuéntenos acerca de usted",
    "The default icon sets will be used.": "Los sets de íconos estándar serán utilizados.",
    "The login article can not be edited.": "El artículo de acceso no puede ser editado.",
    "The mercator CRS will be used.": "The mercator CRS will be used.",
    "The row is not visible on this size.": "The row is not visible on this size.",
    "The site has no assigned map": "El sitio no tiene un mapa asignado",
    "There are no attributions": "There are no attributions",
    "There are no values": "There are no values",
    "There was an unexpected error.": "Hubo un error inesperado.",
    "Update by": "Actualizar por",
    "What is language locale?": "¿Cuál es el idioma del lugar?",
    "What is the language name?": "¿Cuál es el nombre del idioma?",
    "What is the local category?": "¿Cuál es la categoría local?",
    "What is the local name?": "¿Cuál es el nombre local?",
    "What is the local subcategory?": "¿Cuál es la subcategoria local?",
    "What is your job title?": "¿Cuál es el título en su trabajo?",
    "What's next?": "¿Qué sigue?",
    "What's user name?": "¿cuál es su nombre de usuario?",
    "What's your linkedin name?": "¿Cuál es su nombre en linkedin?",
    "What's your public name?": "¿Cuál su nombre público?",
    "What's your skype name?": "¿Cuál es su nombre de skype?",
    "What's your status?": "¿Cuál es su estado?",
    "What's your twitter handler?": "¿Cuál es su nombre en twitter?",
    "What's your website url?": "¿Cuál es el URL de su website?",
    "Where are you located?": "¿Dónde está usted ubicado?",
    "Where do you want to be contacted?": "¿Dónde desea ser contactado?",
    "Where?": "¿Dónde?",
    "You have successfully changed your password.": "Usted ha cambiado de manera satisfactoria su clave.",
    "You have successfully removed your user.": "Usted ha removido de manera satisfactoria a su usuario.",
    "about": "acerca",
    "about you": "About you",
    "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
    "accent color": "accent color",
    "access": "acceso",
    "account": "cuenta",
    "account activation": "activación de cuenta",
    "account info": "Información de cuenta",
    "account-info-message": "Esta es la información acerca de su cuenta",
    "accuracy": "precisión",
    "activation-expire-error": "El símbolo de activación se ha vencido. Por favor intente de nuevo.",
    "add": "agregar",
    "add a site": "agregar un sitio",
    "add another site": "agregar otro sitio",
    "add features as pending": "Agregar características como pendiente",
    "add icon": "agregar ícono",
    "add one or more maps": "agregar un o más mapas",
    "add to team": "agregar al equipo",
    "add-article-paragraph": "Haga clic aquí si desea agregar uno",
    "add-article-title": "Haga clic aquí para agregar un título",
    "added on": "agregado a",
    "address": "dirección",
    "admin-delete-account-message": "No puede remover su cuenta porque usted es un administrador. Pida a otro administrador para remover esta cuenta o para remover sus derechos como administrador.",
    "admin-missing-login-article": "No artículo para la página de inicio de sesión.",
    "administration": "administración",
    "advanced options": "opciones avanzadas",
    "align": "alinear",
    "align items": "alineación de elementos",
    "alignment": "alignment",
    "all": "todo",
    "all base maps": "todos los mapas base",
    "all icon sets": "todos los sets de íconos",
    "all maps": "todos los mapas",
    "all sites": "todos los sitios",
    "all teams": "todos los equipos",
    "all users": "todos los usuarios",
    "allow custom data bindings": "allow custom data bindings",
    "allow domain change": "allow domain change",
    "allow manage without teams": "permitir gestionar sin equipos",
    "allow many instances": "permitir en muchas ocasiones",
    "allow proposing sites": "permitir proponer sitios",
    "allow-manage-without-teams-message": "Cuando esta opción está habilitada, cualquier usuario autenticado puede crear \"equipos\", \"mapas\", \"características\", \"campañas\" e \"iconos\".",
    "allow-proposing-sites-message": "Cuando esta opción está habilitada, la opción `Sugiera un sitio` estará disponible para los usuarios.",
    "allowed email domains": "dominios de correo electrónico permitidos",
    "allowed-email-domains-message": "Establezca una lista de dominios que son permitidos en este servicio. Los dominios deben ser separados por `,` p. ej. `midominio1.com,midominio2.org`. Si la lista está vacía, todos los correos electrónicos serán aceptados.",
    "allowed-spaces-domains-message": "Set a list of the domains that are allowed to be used by the spaces. The domains must be separated by `,` eg. `mydomain1.com,mydomain2.org`.",
    "already have an account?": "¿ya tiene una cuenta?",
    "altitude": "altitud",
    "altitude accuracy": "exactitud de altitud",
    "analytics": "analytics",
    "analyze": "analyze",
    "analyzing file": "el archivo está siendo analizado",
    "and": "y",
    "anonymous": "anónimo",
    "answer": "answer",
    "answer-message": {
      "no-contributor-questions": "The survey does not have contributor questions.",
      "no-generic-questions": "The survey does not include any additional general questions."
    },
    "answers": "answers",
    "appearance": "apariencia",
    "approve": "approve",
    "area": "área",
    "article": "artículo",
    "article content": "article content",
    "article date": "article date",
    "article title": "article title",
    "article-placeholders": {
      "add-calendar": "This is the calendar related to our project.",
      "add-event": "Presenting our exciting new {name}, carefully curated to bring you a unique and memorable experience. Get ready to be part of something extraordinary!",
      "add-icon": "Este es nuestro icono diseñado por nuestro equipo.",
      "add-icon-set": "Este es nuestro nuevo conjunto de iconos diseñado para ser utilizado en nuestros nuevos proyectos.",
      "add-map": "Este es el mapa relacionado con nuestro proyecto.",
      "add-team": "Este es nuestro equipo, dedicado a mapear todos nuestros proyectos."
    },
    "articles": "artículos",
    "as text value": "as text",
    "attribute": "atributo",
    "attributes": "atributos",
    "attributes-options-label": "Opciones (valores separados por comas)",
    "attributions": "atribuciones",
    "authentication type to use": "tipo de autenticación para utilizar",
    "author": "autor",
    "author-label": "por {name}'",
    "auto": "auto",
    "back": "atrás",
    "back to": "regresar a {destination}",
    "back to our website": "back to our website",
    "back to search results": "regresar a la búsqueda de resultados",
    "background color": "color de fondo",
    "background image": "imagen de fondo",
    "background map": "background map",
    "backgroundColor": "color de fondo",
    "banner": "pancarta",
    "base map": "mapa base",
    "base maps": "mapas base",
    "baseline": "punto de referencia",
    "be the first who adds one.": "sea el primero en agregar uno.",
    "begins at": "begins at",
    "begins on": "begins on",
    "between": "entre",
    "between start and end": "Between `{start}` and `{end}`.",
    "bio": "biografía",
    "blocks": "bloques",
    "blue": "blue",
    "boolean-": "unknown",
    "boolean-false": "no",
    "boolean-true": "yes",
    "boolean-undefined": "unknown",
    "border": "border",
    "borderColor": "color del borde",
    "borderWidth": "ancho del borde",
    "bottom": "bottom",
    "breadcrumb": "breadcrumb",
    "browse": "buscar",
    "bs-property-bg-position-x": "x",
    "bs-property-bg-position-y": "y",
    "bs-property-bg-repeat": "repetición de fondo",
    "bs-property-bg-size": "tamaño de fondo",
    "bs-property-btn": "estilo",
    "bs-property-display": "muestra",
    "bs-property-fw": "grosor de fuente",
    "bs-property-size-btn": "magnitud",
    "bs-property-text": "alineación",
    "button": "button",
    "button label": "etiqueta de botón",
    "button link": "enlace de botón",
    "button type": "tipo de botón",
    "buttons": "buttones",
    "by": "por",
    "by address": "por dirección",
    "calendar": "calendar",
    "calendar-entry-at": "at",
    "calendar-entry-begins-on": "Begins on",
    "calendar-entry-ends-on": "on",
    "calendar-entry-repeats-until": "until",
    "calendar-entry-to": "until",
    "calendar-entry.end-date-before-start": "The event ends before it starts.",
    "calendar-entry.interval-end-date-before-end": "The repetition end date is before the end of the event.",
    "calendars": "calendars",
    "campaign": "campaña",
    "campaign card list": "campaign card list",
    "campaign form": "campaign form",
    "campaign-location-type-address": "by address",
    "campaign-location-type-feature": "select an existing feature",
    "campaign-location-type-gps": "use my current location",
    "campaign-location-type-manual": "choose manually on the map",
    "campaigns": "campañas",
    "can create events": "can create events",
    "can send newsletters": "can send newsletters",
    "can't upload": "no se puede subir",
    "cancel": "cancelar",
    "cancel publishing": "cancel publishing",
    "cancelled": "cancelado",
    "capture using GPS": "capture con GPS",
    "card list": "card list",
    "card options": "card options",
    "card view": "card view",
    "card-type": "card type",
    "categories": "categories",
    "category": "categoria",
    "change": "cambiar",
    "change password": "cambie la contraseña",
    "choose manually": "elegir manualmente",
    "choose manually on the map": "choose manually on the map",
    "click here to create a schedule": "click here to create a schedule",
    "click to select an image": "clic para elegir una imagen",
    "click to select from list": "clic para seleccionar de la lista",
    "close": "cerrar",
    "color": "color",
    "color palette": "color palette",
    "colors": "colores",
    "component": "component",
    "component count": "component count",
    "component id": "component id",
    "component-count": "component-count",
    "confirm new password": "confirme la contraseña nueva",
    "confirm your password": "confirme su contraseña",
    "connection info": "connection info",
    "connectionInfo": "connection info",
    "contact form": "contact form",
    "contact-form-success": "Your message has been successfully sent. We will reply soon.",
    "container": "container",
    "container options": "container options",
    "content": "contenido",
    "contributor": "contributor",
    "contributor questions": "contributor questions",
    "contributors": "contribuyentes",
    "convert": "convert",
    "convert to global component": "convert to global component",
    "copy and paste": "copy and paste",
    "cover": "cubierta",
    "cover photo": "foto de la cubierta",
    "cover photos": "fotos de la cubierta",
    "cramMd5": "cramMd5",
    "create": "crear",
    "create a new API token": "crear un nuevo  identificador de API",
    "create a new account": "crear una cuenta nueva",
    "create line": "crear línea",
    "create point": "crear punto",
    "create polygon": "crear polígono",
    "create-first-map": "No hay mapas disponibles.",
    "create-first-map-message": "Parece que este equipo aún no tiene mapas. Cree un primer mapa para comenzar a agregar y visualizar datos.",
    "create-map": "Crea un mapa",
    "created by": "creado por `{name}`",
    "created by you": "creado por `usted`",
    "created on": "creado en",
    "currency": {
      "default": "{value} {currency}",
      "eur": "{value}€",
      "gbp": "£{value}",
      "usd": "${value}"
    },
    "currency selector": "currency selector",
    "custom geometry": "geometría personalizada",
    "custom translations": "traducciones personalizadas",
    "cyan": "cyan",
    "dark": "dark",
    "dashboard": "tablero",
    "dashboards": "dashboards",
    "data binding": "data binding",
    "data bindings": "data bindings",
    "data source": "data source",
    "date": {
      "info.createdOn": "added on",
      "info.lastChangeOn": "last change on",
      "releaseDate": "released on"
    },
    "date interval": "date interval",
    "date-between": "between `{begin}` and `{end}`",
    "date-on": "on {date}",
    "date-on-detail": "on `{date}`",
    "day": "día",
    "day-Friday": "Friday",
    "day-Monday": "Monday",
    "day-Saturday": "Saturday",
    "day-Sunday": "Sunday",
    "day-Thursday": "Thursday",
    "day-Tuesday": "Tuesday",
    "day-Wednesday": "Wednesday",
    "day-short": {
      "Friday": "Fri",
      "Monday": "Mon",
      "Saturday": "Sat",
      "Sunday": "Sun",
      "Thursday": "Thu",
      "Tuesday": "Tue",
      "Wednesday": "Wed"
    },
    "days": {
      "letter": {
        "friday": "V",
        "monday": "L",
        "sunday": "D",
        "wednesday": "Mi"
      },
      "long": {
        "friday": "Friday",
        "monday": "Monday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "thursday": "Thursday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "weekday": "Weekday",
        "weekend": "Weekend"
      },
      "short": {
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "Su",
        "thursday": "Th",
        "tuesday": "Tu",
        "wednesday": "We",
        "weekday": "Weekday",
        "weekend": "Weekend"
      }
    },
    "decimals-label": "{value, plural, =0 {0 decimales} =1 {1 decimal} other {# decimales}}",
    "default": "implícito",
    "default icons": "iconos predeterminados",
    "default models": "default models",
    "default order": "orden estándar",
    "default-base-maps-used-message": "Los mapas base estándar serán utilizados.",
    "delete": "borrar",
    "delete account": "{size, plural, =0 {invalid value} =1 {eliminar cuenta} other {eliminar # cuentas}}",
    "delete all articles": "Delete all articles",
    "delete all campaign-answers": "delete all survey answers",
    "delete all events": "Delete all events",
    "delete all features": "Delete all features",
    "delete all icons": "delete all icons",
    "delete all pages": "Delete all pages",
    "delete article": "eliminar artículo",
    "delete base map": "{size, plural, =0 {invalid value} =1 {eliminar mapa base} other {eliminar # mapas base}}",
    "delete base-map": "delete base-map",
    "delete basemap": "eliminar mapa base",
    "delete calendar": "delete calendar",
    "delete campaign": "delete campaign",
    "delete campaign-answer": "delete survey answer",
    "delete data-binding": "delete data-binding",
    "delete event": "delete event",
    "delete feature": "{size, plural, =0 {invalid value} =1 {eliminar sitio} other {eliminar # sitios}}",
    "delete icon": "{size, plural, =0 {invalid value} =1 {eliminar ícono} other {eliminar # íconos}}",
    "delete icon-set": "{size, plural, =0 {invalid value} =1 {eliminar set de íconos} other {eliminar # set de íconos}}",
    "delete language": "{size, plural, =0 {invalid value} =1 {eliminar idiomas} other {eliminar # idiomas}}",
    "delete map": "{size, plural, =0 {invalid value} =1 {eliminar mapa} other {eliminar # mapas}}",
    "delete non synced records": "delete non synced records",
    "delete page": "eliminar página",
    "delete presentation": "eliminar presentación",
    "delete site": "{size, plural, =0 {invalid value} =1 {eliminar sitio} other {eliminar # sitios}}",
    "delete space": "borrar espacio",
    "delete survey": "{size, plural, =0 {invalid value} =1 {eliminar campaña} other {eliminar # campañas}}",
    "delete team": "{size, plural, =0 {invalid value} =1 {eliminar equipo} other {eliminar # equipos}}",
    "delete token": "{size, plural, =0 {invalid value} =1 {eliminar identificador} other {eliminar # identificadores}}",
    "delete translation": "{size, plural, =0 {invalid value} =1 {eliminar traducción} other {eliminar # traducciones}}",
    "delete your account": "borrar su cuenta",
    "delete-all-confirmation-message-article": "Are you sure you want to delete all articles?",
    "delete-all-confirmation-message-campaign-answer": "Are you sure you want to delete all answers?",
    "delete-all-confirmation-message-event": "Are you sure you want to delete all events?",
    "delete-all-confirmation-message-feature": "Are you sure you want to delete all features?",
    "delete-all-confirmation-message-icon": "Are you sure you want to delete all icons?",
    "delete-all-confirmation-message-page": "Are you sure you want to delete all pages?",
    "delete-confirmation-message": "{size, plural, =0 {invalid value} =1 {¿Está seguro que desea borrar `{name}`?} other {¿Está seguro que desea borrar `#` objetos?}}",
    "delete-confirmation-site-success": "El sitio `{name}` fue borrado satisfactoriamente",
    "description": "descripción",
    "description template": "description template",
    "deselect icon": "deseleccionar icono",
    "desktop": "desktop",
    "desktop options": "desktop options",
    "destination": "destination",
    "details": "details",
    "didn't receive a confirmation email?": "¿no recibió correo de confirmación?",
    "dimensions": "dimensiones",
    "disabled": "inhabilitado",
    "display": "display",
    "display heading": "encabezamiento de pantalla",
    "display mode": "display mode",
    "display name": "nombre para mostrar",
    "do not update": "no actualices",
    "domain": "domain",
    "domain name": "nombre de dominio",
    "domain-name-invalid-chars": "The domain name must contain only letters and number from the english alphabet.",
    "domain-name-update-message": ".{from} will be replaced with .{to}",
    "domain-status": {
      "invalid-domain": "Looks like your domain name is invalid. Try renaming it and give the setup another go.",
      "needs-admin-setup": "Your domain name is reserved and needs one of our admins to finish the setup. Hang tight until you get a confirmation message.",
      "pending": "Your domain will be ready soon; the setup just hasn’t started yet.",
      "ready": "Your space is ready at the reserved domain.",
      "setting-up": "Your space is being set up at the reserved domain."
    },
    "done": "hecho",
    "downgrade to regular user": "bajar de categoría a usuario estándar",
    "downgrade user": "bajar de categoría a usuario",
    "downgrade-user-question": "¿Está seguro que desea bajar de categoría a `{name}`?",
    "downgrade-user-success-message": "Ha bajado de categoría al usuario `{name}` a usuario estándar satisfactoriamente.",
    "download": "descargar",
    "download CSV": "descargar CSV",
    "download GeoJson": "descargar GeoJson",
    "downscale images for faster upload": "reducir el tamaño de las imágenes para descargas más rapidas",
    "e-mail": "correo electrónico",
    "edit": "editar",
    "edit article": "editar artículo",
    "edit base map": "editar mapa base",
    "edit calendar": "edit calendar",
    "edit content": "contenido editado",
    "edit data binding": "edit data binding",
    "edit event": "edit event",
    "edit feature": "edit feature",
    "edit geometry": "editar geometría",
    "edit icon": "edit icon",
    "edit icon set": "editar set de íconos",
    "edit layout": "editar diseño",
    "edit map": "editar mapa",
    "edit newsletter": "edit newsletter",
    "edit notification": "editar notificación",
    "edit page": "editar página",
    "edit presentation": "editar presentación",
    "edit site": "editar sitio",
    "edit space": "editar espacio",
    "edit survey": "editar campaña",
    "edit team": "editar equipo",
    "edit the map": "editar el mapa",
    "edit to add one.": "editar para agregar uno.",
    "edit user": "editar usuario",
    "edit-article-label": "editar artículo",
    "edit-label": "editar",
    "edit-model": {
      "created-on": "creado {time}",
      "last-change": "por {name} {time}",
      "title": "Editar {name}"
    },
    "edit-model.created-on": "created on",
    "email": "correo electrónico",
    "email a new issue": "envíe un correo electrónico con un problema nuevo",
    "embedded map": "mapa insertado",
    "empty": "vacío",
    "enable all notifications": "habilitar todas las notificaciones",
    "enable links": "enable links",
    "enable the show more button": "enable the show more button",
    "enabled": "habilitado",
    "end": "end",
    "end date": "fecha límite",
    "ended on": "acabó en",
    "ends at": "ends at",
    "ends on": "ends on",
    "enter again your new password": "ingrese otra vez su contraseña nueva",
    "enter password": "ingrese la contraseña",
    "enter search keywords": "ingrese palabras clave de búsqueda",
    "enter the token name": "ingrese el nombre del símbolo",
    "enter your e-mail": "ingrese su correo electrónico",
    "enter your new password": "ingrese su contraseña nueva",
    "entry": "entry",
    "error": "error",
    "errors": "errores",
    "event": "event",
    "event dates": "event dates",
    "event location": "event location",
    "event schedule": "event schedule",
    "event-at": "at",
    "event-repetition": {
      "daily": "daily",
      "monthly": "monthly",
      "norepeat": "one off",
      "weekly": "weekly",
      "yearly": "yearly"
    },
    "event_date.interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
    "event_date.interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_same_day": "**{beginTime}** to **{endTime}**",
    "event_date.repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
    "event_date.repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginD}**",
    "event_date.repeats_norepeat": "**{beginD} {beginM} {beginY}**",
    "event_date.repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
    "event_date.repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**",
    "everything is in order": "todo está en orden",
    "existing": "existente",
    "expire date": "fecha de expiración",
    "expires on": "expira en",
    "export emails with stats": "export emails with stats",
    "external link": "enlace externo",
    "extra icons": "iconos adicionales",
    "failed_restored_site_alert_message": "Su sesión previa no fue recuperada porque contenía información corrupta.",
    "feature": "característica",
    "feature attributes": "feature attributes",
    "feature campaign contribution button": "feature campaign contribution button",
    "feature clustering": "agrupación de características",
    "feature field": "campo del registro",
    "feature issue button": "feature issue button",
    "feature name prefix": "prefijo de nombre de característica",
    "feature-on-map": "on",
    "feature-pending-count-message": "{count, plural, =0 {No hay ninguna característica pendiente} =1 {Hay una característica pendiente} other {Hay # características pendientes}}",
    "feature-pending-message": "Esta característica está pendiente",
    "feature-success-message": "Su sitio ha sido guardado y está en revisión.",
    "feature-success-title": "Su sitio ha sido guardado",
    "feedback-bio": "Cuéntenos acerca de usted en menos de 250 caracteres",
    "feedback-full-name": "Ingrese su nombre, para que las personas que lo conocen puedan reconocerlo",
    "feedback-linkedin": "Su nombre de perfil de LinkedIn de linkedin.com/in/profilename",
    "feedback-organization": "A quién representa o para quién trabaja",
    "field matching": "campos coincidentes",
    "fields": "fields",
    "fields matching": "coincidencia de campos",
    "file": "archivo",
    "file field": "campo del archivo",
    "filter": "filtro",
    "filter with more than one icon": "filter with more than one icon",
    "filters": "filters",
    "fix": "arreglar",
    "font": "font",
    "font styles": "font styles",
    "font-awesome-message": "Puede seleccionar cualquier ícono gratuito de la colección de FontAwesome.",
    "font-h1": "Heading 1",
    "font-h2": "Heading 2",
    "font-h3": "Heading 3",
    "font-h4": "Heading 4",
    "font-h5": "Heading 5",
    "font-h6": "Heading 6",
    "font-paragraph": "Paragraph",
    "forgot your password?": "¿olvidó su contraseña?",
    "fragment": "fragment",
    "from": "from",
    "full name": "nombre completo",
    "gallery": "galería",
    "general": "general",
    "general questions": "general questions",
    "generating preview": "se genera la vista previa",
    "geo-json-allowAddress": "allow searching for an address",
    "geo-json-allowExistingFeature": "allow selecting a feature from the map",
    "geo-json-allowGps": "allow gps",
    "geo-json-allowManual": "allow setting a point on the map",
    "geo-location-permission-denied": "Esta aplicación no tiene permiso para utilizar su ubicación.",
    "geo-location-permission-timeout": "El tiempo permitido para obtener su ubicación se ha terminado antes que la información fuera obtenida.",
    "geo-location-permission-unavailable": "La adquisición de su ubicación falló porque al menos una fuente interna de la posición resultó en un error interno.",
    "geo-location-unknown-error": "Su ubicación no está disponible debido a un error inesperado.",
    "geoJson editor": "geoJson editor",
    "geojson-feature-file-message": "Seleccionar un .json, .geojson o .gpx",
    "geometry": "geometría",
    "geometry options": "geometry options",
    "get gpx file": "get gpx file",
    "global": "global",
    "global map page": "global map page",
    "go to homepage": "ir al inicio",
    "go to the main page": "ir a la página principal",
    "green": "green",
    "group": "grupo",
    "group name": "nombre del grupo",
    "groups": "groups",
    "grow": "grow",
    "guests": "invitados",
    "gutter": "gutter",
    "hamburger menu on": "hamburger menu on",
    "has custom style": "tiene un estilo personalizado",
    "heading": "heading",
    "heading picture": "heading picture",
    "heading text": "heading text",
    "height": "height",
    "help": {
      "map": {
        "area": "This is the geographic area your map will cover."
      },
      "survey": {
        "icons": "Esos íconos se agregarán automáticamente a cada respuesta de campaña, el colaborador no podrá seleccionarlos."
      }
    },
    "help message": "mensaje de ayuda",
    "hidden-contribution-calendar-message": "Este calendario de contribución está oculto. Puede publicarlo cuando edite el perfil.",
    "hide advanced details": "ocultar detalles avanzados",
    "hide calendar": "hide calendar",
    "hide details": "esconder detalles",
    "hide on main map": "ocultar en el mapa principal",
    "hide options": "esconder opciones",
    "homepage map extent": "Extensión del mapa de la página de inicio",
    "horizontal gutters": "canaletas horizontales",
    "horizontal line": "horizontal line",
    "hour": "hour",
    "i forgot my password": "olvidé mi contraseña",
    "icon": "ícono",
    "icon grid with categories": "icon grid with categories",
    "icon list": "icon list",
    "icon name": "nombre del ícono",
    "icon set": "set de íconos",
    "icon sets": "sets de íconos",
    "icon-inherited-style-message": "Este ícono está utilizando el estilo del set de íconos.",
    "icon-label": "ícon",
    "icon-missing-attributes-message": "Este ícono no tiene atributos.",
    "icon-not-matched-message": "No hay ícono correspondiente",
    "icon-set": "set de íconos",
    "icons": "íconos",
    "icons per line": "icons per line",
    "ignored": "ignored",
    "import": "importar",
    "import a new file": "importar un archivo nuevo",
    "import emails": "import emails",
    "import file to": "importar archivo a",
    "imported": "importado",
    "indigo": "indigo",
    "inherited attribute": "atributo heredado",
    "inside area": "en `{name}`",
    "integrations": "integraciones",
    "invalid vars": "variables no válidas",
    "invalid-file-type-message": "Ha enviado un tipo de archivo incompatible.",
    "invalid-gpx-missing-track-or-route": "El archivo gpx debe contener al menos una ruta o vía.",
    "invalid-json-feature-message": "El archivo contiene una geometría invalida. Solo se permiten Punto, Línea, Línea Múltiple, y Polígono.",
    "invalid-json-feature-type-message": "El archivo contiene una geometría de `{type}`. Solo se permiten Punto, Línea, Línea Múltiple, y Polígono.",
    "invalid-json-file-message": "El archivo contiene un Json invalido",
    "is activated": "está activado",
    "is attribute": "es atributo",
    "is default": "es estándar",
    "is index": "es índice",
    "is private": "Es privado",
    "is public": "es público",
    "is required": "es requerido",
    "is template": "is template",
    "is-multi-project-mode-message": "Cuando esta opción está habilitada, la sección `Buscar` estará disponible para los usuarios habituales.",
    "isVisible": "es visible",
    "issue-count": "{issues, plural, =0 {no issues} =1 {one issue} other {# issues}}",
    "issues": "problemas",
    "item": "artículo",
    "items": "artículos",
    "job title": "título del trabajo",
    "job-title": "{jobTitle} en {organization}",
    "joined time": "fecha de inicio",
    "justify content": "arreglo de contenido",
    "keep it on map when it is small": "mantenerlo en el mapa cuando es pequeño",
    "key": "clave",
    "label": "etiqueta",
    "label style": "label style",
    "landing page": "landing page",
    "language": "idioma",
    "languages": "idiomas",
    "last activity": "Última actividad",
    "last change": "último cambio",
    "latest version": "ultima versión",
    "latitude": "latitud",
    "layer": "capa",
    "layer-count": "{count, plural, =0 {sin capas} =1 {1 capa} other {# capas}}",
    "layers": "capas",
    "layout": "esquema",
    "leaders": "líderes",
    "leave a message to our team": "leave a message to our team",
    "license": "licencia",
    "light": "light",
    "line": "línea",
    "line color": "color de la línea",
    "line height": "line height",
    "line marker": "marca de la línea",
    "lineDash": "línea discontinua",
    "lineHeight": "altura de la línea",
    "lines": "líneas",
    "link": "enlace",
    "link list": "link list",
    "linkedin": "linkedin",
    "list": "list",
    "list customization": "list customization",
    "list view": "list view",
    "load more": "load more",
    "local name": "nombre local",
    "locale": "lugar",
    "location": "localización",
    "location mode": "modo de localización",
    "location-city": "Ciudad",
    "location-country": "País",
    "location-postalCode": "Código postal",
    "location-province": "Provincia / Estado",
    "login": "acceso",
    "logo": "logo",
    "longitude": "longitud",
    "mailersend.com": "mailersend.com",
    "mailersend.com key": "clave mailersend.com",
    "main picture": "imagen principal",
    "main title": "main title",
    "make private": "hacer privado",
    "make public": "hacer público",
    "manage": "maneje",
    "manage team": "gestionar el equipo",
    "manage-section": {
      "deleting": "eleminar {name}",
      "map-calendar": "Events from this calendar will use locations from",
      "map-mask": "Los elementos que pertenecen a este mapa se enmascararán con elementos de",
      "map-survey": "Las respuestas de esta campaña se agregarán en el mapa",
      "no-map-calendar": "Events from this calendar will not use map locations.",
      "no-map-mask": "Los elementos que pertenecen a este mapa no estarán enmascarados.",
      "no-map-survey": "Las respuestas de esta campaña no se agregarán a un mapa.",
      "site-note": "creado {time}, {distance}",
      "unset-team": "Este archivo no tiene equipo. Usted debe establecer uno para poder tener una propiedad válda."
    },
    "map": "mapa",
    "map extent": "extensión del mapa",
    "map feature": "map feature",
    "map files": "archivos del mapa",
    "map map view": "mapa vista de mapa",
    "map sheet": "hoja mapa",
    "map view": "vista del mapa",
    "maps": "mapas",
    "maps options": "maps options",
    "marker": "marca",
    "mask": "máscara",
    "mask points": "enmascarar características",
    "masked feature info": "Se aplica la máscara de ubicación, por lo que la ubicación que se muestra públicamente será imprecisa.",
    "masking-precision-message": "El nuevo valor puede tardar hasta 1 hora en actualizarse para los datos existentes. El número de decimales establecido determinará cuántos decimales se mantienen del valor de ubicación inicial. P.ej. para un valor de `0 decimales`, una posición como `32.42587` se convertirá en `32`, y para un valor de `1 decimal` esa misma posición se convertirá en `32.4`.",
    "match based on a field": "coincidencia basada en el campo",
    "member": "miembro",
    "member of": "miembro de",
    "member-since": "Miembro desde {time}",
    "members": "miembros",
    "menu": "menú",
    "menu buttons": "menu buttons",
    "menu functionality": "menu functionality",
    "menu item": "élément du menu",
    "menu style": "menu style",
    "message": "message",
    "message-alert-attributes-missing": "There are no attributes.",
    "message-alert-duplicate-icon-name": "Estás usando los dos iconos con el mismo nombre: `{name}`. Quitar el de `{iconSet}`.",
    "message-alert-duplicate-icon-name-default": "Estás usando los dos iconos con el mismo nombre: `{name}`. Eliminar el de la lista predeterminada.",
    "message-alert-duplicate-icon-name-optional": "Estás usando los dos iconos con el mismo nombre: `{name}`. Retire el de la lista opcional.",
    "message-alert-file-already-imported": "Este archivo fue previamente importado. Tenga cuidado de no duplicar los registros existentes.",
    "message-alert-file-not-analyzed-yet": "Este archivo aún no ha sido analizado.",
    "message-alert-file-without-preview": "No se puede obtener una vista previa de este archivo.",
    "message-alert-icon-set-private": "El set de íconos es privado y los usuarios que no forman parte de su equipo no pueden ver este icono.",
    "message-alert-invalid-connection-info": "The connection configuration is invalid.",
    "message-alert-invalid-connection-info-type": "The selected connection type is not supported yet.",
    "message-alert-license-not-set": "El mapa no tiene licencia. Al configurar uno, ayuda a otros a saber cómo pueden usar el mapa.",
    "message-alert-map-with-unpublished-basemaps": "Este mapa es el uso de mapas de base privada: `{names}`. Los usuarios ajenos a su equipo no podrán ver correctamente este mapa.",
    "message-alert-map-with-unrelated-basemaps": "Este mapa usa mapas base que pertenecen a otros equipos: `{names}`. Solucione este problema.",
    "message-alert-map-with-unrelated-iconsets": "Este mapa utiliza conjuntos de iconos que pertenecen a otros equipos: `{names}`. Solucione este problema.",
    "message-alert-missing-connection-info-type": "Select a type before setting the connection info.",
    "message-alert-missing-destination": "The destination is not set.",
    "message-alert-missing-fields": "There are no fields to be mapped.",
    "message-alert-page-private": "Esta pagina es privada.",
    "message-alert-parent-icon-private": "Los usuarios que no forman parte de su equipo no pueden acceder a las propiedades heredadas porque el icono principal es privado.",
    "message-alert-pictures-missing": "There are no pictures.",
    "message-alert-select-icon": "Debe establecer una imagen para este icono.",
    "message-alert-sounds-missing": "There are no sounds.",
    "message-alert-survey-add-notification": "Los colaboradores autenticados recibirán una notificación al agregar.",
    "message-alert-survey-no-notifications": "La campaña no tiene notificaciones configuradas.",
    "message-copy-paste-emails": "Directly paste in new emails from a spreadsheet or similar list.",
    "message-dns-setup": "para acceder a este dominio, cree un nuevo registro DNS",
    "message-dns-verification": "para verificar la propiedad de su dominio, agregue la clave anterior a un registro TXT dentro de su configuración de DNS.",
    "message-email-count": "{count, plural, =0 {no emails} =1 {one email} other {# emails}}",
    "message-empty-layout": "El diseño no tiene contenedores. Debe editar el diseño antes de editar el contenido.",
    "message-event-entry-list-empty": "Unfortunately, there are no scheduled dates for this event at the moment.",
    "message-generic-list-empty": "Empty list",
    "message-import-emails": "Grow your newsletter audience by adding or importing contacts who have already given you permission to contact them.",
    "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
    "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
    "message-missing-record": "No data source",
    "message-newsletter-send-to-all": "Are you sure you want to send this message to all your subscribers?",
    "message-newsletter-unpublish": "Are you sure you want to unpublish this message?",
    "message-no-page": "No page is set.",
    "message-no-schedule": "There is no defined schedule.",
    "message-no-team-description": "The team has no description outlining its members, activities, and goals.",
    "message-not-right-location": "Is there a better location?",
    "message-page-col-empty": "Esta columna está vacía.",
    "message-replace-emails-confirmation": "Are you sure you want to replace the existing emails?",
    "message-reused-gid": "el id ya lo usa otro componente",
    "message-selectable-list-empty": "Looks like there’s nothing here. Add a new record to get started!",
    "message-upload-email-file": "Import contacts from a CSV or TXT file.",
    "message-view-min-max-zoom": "El icono es visible entre los niveles de zoom `{minZoom}` y `{maxZoom}`.",
    "message.no-cover": "There is no picture.",
    "message.no-selected-icons": "There are no selected icons.",
    "message.no-survey-questions": "This survey has no general questions",
    "metrics": {
      "contributors": "{value, plural, =0 {ningún colaborador} =1 {un colaborador} other {# colaboradores}}",
      "publicFeatures": "{value, plural, =0 {ningún sitio publicado} =1 {un sitio publicado} other {# sitios publicados}}",
      "published sites": "{value, plural, =0 {ningún sitio publicado} =1 {un sitio publicado} other {# sitios publicados}}",
      "publishedSites": "{value, plural, =0 {ningún sitio publicado} =1 {un sitio publicado} other {# sitios publicados}}",
      "total sites": "{value, plural, =0 {ningún sitio} =1 {un sitio} other {# sitios}}",
      "totalContributors": "{value, plural, =0 {ningún colaborador} =1 {un colaborador} other {# colaboradores}}",
      "totalFeatures": "{value, plural, =0 {ningún sitio} =1 {un sitio} other {# sitios}}",
      "totalSites": "{value, plural, =0 {ningún sitio} =1 {un sitio} other {# sitios}}"
    },
    "metrics.publicSites": "metrics.publicSites",
    "min height": "altura mínima",
    "minute": "minute",
    "missing-paragraph-message": "El artículo no tiene párrafo.",
    "missing-title-message": "Es necesario proporcionar un encabezado de nivel 1 para el título del artículo.",
    "mobile": "móvil",
    "mobile options": "mobile options",
    "mode": "mode",
    "model": "model",
    "model-items": {
      "category": "category",
      "icon": "icon",
      "iconSet": "icon set",
      "map": "map",
      "subcategory": "subcategory",
      "team": "team"
    },
    "month": {
      "short": {
        "april": "Abr",
        "august": "Ago",
        "december": "Dic",
        "february": "Feb",
        "january": "Ene",
        "july": "Jul",
        "june": "Jun",
        "march": "Marzo",
        "may": "Mayo",
        "november": "Nov",
        "october": "Oct",
        "september": "Sep"
      }
    },
    "month-label": "mes",
    "mtcaptcha-message": "Puedes evitar el tráfico de spam generando una cuenta mtCAPTCHA aquí:",
    "multi project mode": "modo multiproyecto",
    "name": "nombre",
    "name and description": "name and description",
    "name used in data exports": "name used in data exports",
    "name-first": "Nombre",
    "name-last": "Apellido",
    "name-salutation": "Saludo",
    "name-title": "Title",
    "never": "nunca",
    "new": "nueva",
    "new article": "articulo nuevo",
    "new base map": "agregar mapa base",
    "new calendar": "new calendar",
    "new campaign": "nueva campaña",
    "new data binding": "new data binding",
    "new event": "new event",
    "new feature": "característica nueva",
    "new icon": "ícono nuevo",
    "new icon set": "set de íconos nuevo",
    "new issue": "new issue",
    "new language": "idioma nuevo",
    "new map": "mapa nuevo",
    "new message": "new message",
    "new newsletter": "new newsletter",
    "new page": "nueva pagina",
    "new paragraph": "Nuevo párrafo",
    "new password": "contraseña nueva",
    "new presentation": "new presentation",
    "new record": "new {name}",
    "new site": "sitio nuevo",
    "new space": "new space",
    "new survey": "new survey",
    "new team": "equipo nuevo",
    "new title": "Nuevo título",
    "new user can join": "usuario nuevo puede unirse",
    "new-token-confirm-message": "Asegúrese de guardarlo - no podrá accederlo de nuevo.",
    "new-token-expire-message": "Seleccione la fecha cuando su identificador de API expirará.",
    "new-token-message": "Ingresa un nombre para este identificador de API para recordarle en el futuro porque está utilizadolo.",
    "newsletter": "newsletter",
    "newsletters": "newsletters",
    "no": "no",
    "no maps available in this area.": "no hay mapas disponible en ésta área.",
    "no-answer": "No answer.",
    "no-icon-attributes-message": "El ícono no tiene atributos",
    "no-layers-set-message": "No hay un conjunto de capas.",
    "nominatim-url-info": "Asegúrese de cumplir con la política de uso del servicio elegido. Consulte la página wiki en https://wiki.openstreetmap.org/wiki/ES:Nominatim para obtener más información.",
    "none": "ninguno",
    "not published": "no está publicado",
    "not set": "no está establecido",
    "not-found-features-search-message": "Su búsqueda - {term} - no corresponde ninguna característica.",
    "not-found-features-suggestion-message": "Intente buscar en todos los mapas.",
    "not-joined-a-team-user": "{name} no pertenece al equipo en este momento.",
    "not-joined-a-team-you": "Usted no es parte de ningún equipo.",
    "nothing": "nada",
    "notifications": "notificaciones",
    "offsetX": "compensar X",
    "offsetY": "compensar Y",
    "on all zoom levels": "en todos los niveles de acercamiento",
    "only for administrators": "solo para administradores",
    "only one variable is allowed in the path": "solo se permite una variable en la ruta",
    "only pending": "solamente `pendiente`",
    "only published": "solamente `publicados`",
    "only unpublished": "solamente `sin publicar`",
    "opacity": "opacidad",
    "open": "abierto",
    "option-panel": {
      "accent color": "accent color",
      "active-color": "active color",
      "allow-address": "allow users to select an address",
      "allow-existing-feature": "allow users to select a feature from the map",
      "allow-gps": "allow users to use their current location",
      "allow-manual": "allow users to select a site on the map",
      "anchor": "anchor",
      "attribute-name": "attribute name",
      "attribute-path": "attribute path",
      "attribute-style": "attribute style",
      "background-color": "background color",
      "border-color": "border color",
      "bs-property-align-items": "align items",
      "bs-property-bg-position-x": "background position x",
      "bs-property-bg-position-y": "background position y",
      "bs-property-bg-repeat": "background repeat",
      "bs-property-bg-size": "background size",
      "bs-property-border-radius": "border radius",
      "bs-property-border-size": "border size",
      "bs-property-border-visibility": "border visibility",
      "bs-property-btn": "style",
      "bs-property-display": "display",
      "bs-property-flex-direction": "direction",
      "bs-property-fw": "font weight",
      "bs-property-justify-content": "justify content",
      "bs-property-line-height": "line height",
      "bs-property-min-lines": "minimum lines height",
      "bs-property-size-btn": "size",
      "bs-property-text": "align",
      "bs-property-text-line-clamp": "line clamp",
      "bs-property-text-size": "size",
      "bs-property-width": "width",
      "button": "button",
      "button-label": "label",
      "button-type": "button type",
      "buttons": "buttons",
      "calendar": "calendar",
      "card-type": "card type",
      "categories": "categories",
      "category-list": "categories",
      "category-style": "category style",
      "change component type": "change component type",
      "color": "color",
      "colors": "colors",
      "component": "component",
      "component-margin": "component margin",
      "container": "container",
      "container-list": "container list",
      "container-properties": "container",
      "content-picker": "content picker",
      "copy": "copy",
      "count": "Count",
      "cover": "cover",
      "css-height": "height",
      "css-size": "size",
      "css-width": "width",
      "currencies": "currencies",
      "currency": "currency",
      "currency-style": "currency style",
      "custom-location-question": "custom location question",
      "custom-question": "custom question",
      "daily": "daily",
      "data-source": "data source",
      "default-category": "default category",
      "default-cover": "default cover",
      "default-value": "default value",
      "description": "description",
      "description-label": "description label",
      "destination": "destination",
      "details": "details",
      "effect": "Effect",
      "email-label": "email label",
      "empty-message": "empty message",
      "enable-full-map-view": "enable full map view",
      "enable-map-interaction": "Map interaction",
      "enable-pinning": "enable pinning",
      "entries": "entries",
      "example-model-id": "example model id",
      "extra-fields": "extra fields",
      "feature-hover": "On feature hover",
      "feature-selection": "On feature selection",
      "filter-index": "filter",
      "flex-config": "flex box configuration",
      "floating-labels": "enable floating labels",
      "fly-over-animation": "Fly over animation",
      "form": "form",
      "full-description": "use full description",
      "gutter": "gutter",
      "heading": "heading",
      "height": "height",
      "hover-color": "hover color",
      "icon-container": "icon container",
      "icon-set": "icon set",
      "icon-style": "icon style",
      "icons": "icons",
      "icons-label": "icons label",
      "image-size": "Image size",
      "input-group": "group input",
      "is-mandatory": "is required",
      "items-per-page": "items per page",
      "justify-content": "justify content",
      "label": "label",
      "label-customization": "label customization",
      "label-success": "success label",
      "layers": "layers",
      "layout": "layout",
      "link": "link",
      "link-name": "link name",
      "list-count": "list count",
      "logo": "logo",
      "map": "map",
      "margin": "margin",
      "max-items": "max items",
      "menu-button-title": "button {index}",
      "menu-button-title-child": "dropdown {index}",
      "message-label": "message label",
      "mode": "Mode",
      "model": "model",
      "model-name": "name",
      "monthly": "monthly",
      "name": "name",
      "name-label": "name label",
      "newsletter": "newsletter",
      "next-date-with-location": "next date with location",
      "next-occurrence": "next occurrence",
      "norepeat": "one time",
      "open-in-new-tab": "open in a new tab",
      "padding": "padding",
      "paddings": "paddings",
      "paragraph": "paragraph",
      "paragraph-index": "paragraph index (counting from 0)",
      "paste": "paste",
      "paste style": "paste style",
      "picture": "picture",
      "picture-options": "picture options",
      "pictures": "pictures",
      "placeholder-text": "placeholder text",
      "position-x": "x",
      "position-y": "y",
      "predefined colors": "predefined colors",
      "price": "price",
      "price-details": "price-details",
      "price-list": "price list",
      "primary-photo": "primary photo",
      "properties": "properties",
      "property": "property",
      "proportion": "proportion",
      "restrict-view": "Restrict view",
      "revisions": "revisions",
      "row-properties": "row",
      "schedule": "schedule",
      "selected color": "selected color",
      "send-to": "send to",
      "server-type": "server type",
      "shades": "shades",
      "show search": "show search",
      "show-base-map-selection": "Show base map selection",
      "show-content-after": "Show the content after",
      "show-photo-list": "show photo list",
      "show-primary-photo-attributions": "show primary photo attributions",
      "show-title": "show title",
      "show-user-location": "Show user location",
      "show-zoom-buttons": "Show zoom buttons",
      "size": "size",
      "size-mode": "size mode",
      "sizing-mode": "mode",
      "slot-name": "slot name",
      "slot-properties": "slot",
      "source": "source",
      "source-field": "source field",
      "source-model-key": "source model key",
      "space": "space",
      "stat-customization": "stat customization",
      "stats": "stats",
      "store-type": "store type",
      "style": "style",
      "styles": "styles",
      "subcategory-style": "subcategory style",
      "subject": "subject",
      "subtitle": "subtitle",
      "text": "text",
      "text-color": "text color",
      "text-font": "font family",
      "text-list": "text list",
      "text-style": "text style",
      "tiled": "tiled",
      "title": "title",
      "unlink global component": "unlink global component",
      "url": "url",
      "use-fixed-value": "value",
      "use-the-current-model": "use the current model",
      "use-the-default-model": "use the default model",
      "use-value-from": "use the value from",
      "value": "value",
      "version": "version",
      "vertical-align": "vertical align",
      "vertical-size": "vertical size",
      "visibility": "visibility",
      "weekly": "weekly",
      "width": "width",
      "with-search": "with search",
      "yearly": "yearly"
    },
    "optional": "Opcional",
    "optional icons": "íconos opcionales",
    "options": "options",
    "or": "o",
    "or go to the last slide": "or go to the last slide",
    "orange": "orange",
    "order": "order",
    "organization": "organización",
    "original author": "autor original",
    "other names": "otros nombres",
    "other-option": "Otro",
    "other-option-input-label": "Especifique si seleccionó “Otro”.",
    "overlay": "capa",
    "owners": "propietarios",
    "paddings": "paddings",
    "page": "page",
    "page-not-found": {
      "contact-admin": "Por favor contacte su administrador si piensa que es un error.",
      "message": "Asegúrese que la dirección es correcta y la página no se ha movido.",
      "title": "Página No Encontrada"
    },
    "pages": "página",
    "pagination-label": "{current} de {total}",
    "paragraph": "párrafo",
    "parameters": "parameters",
    "parent icon": "ícono principal",
    "parent-icon": "ícono principal",
    "password": "contraseña",
    "password to use for authentication": "contraseña utilizada para la autenticación",
    "password-change-success": "Ha cambiado la contraseña satisfactoriamente de `{name}`.\"",
    "path": "sendero",
    "personal settings": "configuraciones personales",
    "photos": "fotos",
    "pick a location on the map": "Elige una ubicación en el mapa",
    "pick it on the map": "Pick it on the map",
    "picture": "imagen",
    "pictures": "imágenes",
    "pink": "pink",
    "places": "lugares",
    "plain": "sencillo",
    "point": "punto",
    "points": "puntos",
    "polygon": "polígono",
    "polygon marker": "marca de polígono",
    "polygons": "polígonos",
    "position": "posición",
    "position-details": "¿Cómo desea seleccionar la ubicación?",
    "predefined area": "área predefinida",
    "predefined colors": "colores predefinidos",
    "preferences": "preferencias",
    "preparing": "programado",
    "presentation": "presentación",
    "presentations": "presentaciones",
    "preview": "avance",
    "preview-import": {
      "alert": "No se puede convertir `{key}` a `{destination}` porque `{error}`",
      "empty-maps": "El registro debe tener al menos un mapa.",
      "empty-name": "El registro no tiene nombre.",
      "invalid-position": "El registro debe contener un geojson válido.",
      "invalid-type": "El registro debe ser un objeto.",
      "missing-fields": "El registro debe tener todos los campos obligatorios `{vars}`"
    },
    "price offer": "price offer",
    "primary": "primario",
    "primary background color": "primary background color",
    "primary border color": "primary border color",
    "primary text color": "primary text color",
    "privacy Policy": "política de privacidad",
    "private key": "llave privada",
    "profile": "perfil",
    "profile card": "profile card",
    "profile-current-status-message": "Este emoji y mensaje aparecerán en su perfil y en toda la interfaz.",
    "profile-fill-in-message": "Your public profile is not finished.",
    "profile-fill-in-message-link": "Click here to update your profile.",
    "profile-main-settings-message": "Esta información aparecerá en su perfil.",
    "profile-public-avatar-message": "Usted puede subir su avatar aquí.",
    "progress": "progreso",
    "promote to admin": "promover al administrador",
    "promote user": "promover usuario",
    "promote-user-question": "¿Está seguro que desea promover al usuario `{name}`?",
    "promote-user-success-message": "Ha promovido satisfactoriamente a `{name}` como administrador.",
    "property": "property",
    "property name": "nombre de la propiedad",
    "proportion": "proportion",
    "propose": "propose",
    "public profile": "perfil público",
    "publish": "publicar",
    "publish now": "publish now",
    "purple": "purple",
    "question": "question",
    "question-description": "question for the feature description",
    "question-icons": "question for the icons that the contributor can choose",
    "question-message": {
      "duplicated-name": "There is already a question with the same name.",
      "empty-icon-list": "You haven't picked any icons. You need to select at least one icon to make the question relevant.",
      "hidden-description": "The user won't be asked to enter a description.",
      "hidden-email": "The user won't be asked to enter an email.",
      "hidden-icons": "The user won't be asked to pick icons.",
      "hidden-name": "The user won't be asked to enter a name.",
      "hidden-pictures": "The user won't be asked to upload pictures.",
      "hidden-position": "The user won't be asked to set a location.",
      "hidden-sounds": "The user won't be asked to upload sounds.",
      "no-name": "The name is not set. We recommend to use one or two words separated by a dash.",
      "no-question": "The question is not set.",
      "no-type": "The type is not set.",
      "no-user-questions": "There are no questions about the contributor's identity. The answer will be added anonymously."
    },
    "question-name": "question for the feature name",
    "question-pictures": "question for adding pictures",
    "question-position": "question for the feature location",
    "question-sounds": "question for adding sounds",
    "questions": "preguntas",
    "reCAPTCHA": "reCAPTCHA",
    "read more...": "leer más...",
    "recaptcha-message": "Puede evitar tráfico basura al generar una cuenta reCAPTCHA v3 aqui:",
    "recent searches": "búsquedas recientes",
    "record name": "record name",
    "record-status": {
      "draft": "draft",
      "pending": "pending",
      "sending": "sending",
      "sent": "sent"
    },
    "rectangle": "rectangulo",
    "red": "red",
    "redirect": "redirect",
    "redirects": "redirects",
    "register": "registrese",
    "register here.": "registrese aqui.",
    "register-section": {
      "accept-message": "Yo acepto",
      "accept-message-privacy-policy": "I accept the privacy policy.",
      "accept-message-terms": "I accept the terms of this service.",
      "message-read-privacy-policy": "Click here to read the privacy policy.",
      "message-read-terms": "Click here to read the terms."
    },
    "register-success-message": "¡Su cuenta ha sido creada! Encuentre el enlace de activación en su correo electrónico.",
    "registration": "registración",
    "reject": "reject",
    "release date": "release date",
    "remoteId": "remoteId",
    "remove member": "remover miembro",
    "remove-member-message": "¿Está seguro que desea remover `{name}` del equipo?",
    "repeat-daily-until": "daily between `{begin}` and `{end}`",
    "repeat-monthly-until": "monthly between `{begin}` and `{end}`",
    "repeat-weekly-until": "weekly between `{begin}` and `{end}`",
    "repeat-yearly-until": "yearly between `{begin}` and `{end}`",
    "repeats": "Repeats",
    "repetition": {
      "daily": "Daily",
      "monthly": "Monthly",
      "norepeat": "No",
      "weekly": "Weekly",
      "yearly": "Yearly"
    },
    "replace existing emails": "replace existing emails",
    "report a problem": "reporte un problema",
    "report issues on Gitlab": "reporte problemas en Gitlab",
    "request a new one.": "solicite uno nuevo.",
    "required": "Required",
    "resend e-mail": "reenviar el correo electrónico",
    "reset": "cambiar",
    "reset password": "cambiar la contraseña",
    "restored_site_alert_message": "Este sitio fue recuperado de una sesión previa. Puede terminar la edición del sitio, o puede reiniciar el formulario.",
    "restored_site_alert_title": "¡Su sitio ha sido recuperado!",
    "revert": "revert",
    "reviewed-on": "on",
    "revisions": "revisions",
    "role": "rol",
    "roles-guests": "Guest",
    "roles-leaders": "Leader",
    "roles-members": "Member",
    "roles-owners": "Owner",
    "roles-unknown": "unknown",
    "rotate": "rotar",
    "running": "corriendo",
    "salutation-mr": "Sr.",
    "salutation-mrs": "Sra.",
    "salutation-ms": "Srta.",
    "salutation-other": "None",
    "save": "guardar",
    "save as a new copy": "guardar como una nueva copia",
    "save the pin location": "guardar la ubicación del pin",
    "scale": "escala",
    "schedule": "schedule",
    "scheduled": "scheduled",
    "scope": "rango",
    "search": "buscar",
    "search a place by name or enter a text": "search a place by name or enter a text",
    "search an address": "buscar una dirección",
    "search by name or email": "buscar por nombre o correo electrónico",
    "search options": "search options",
    "search panel expands all available icons": "search panel expands all available icons",
    "search something here": "busca algo aquí",
    "search team": "search team",
    "search-with-results": "busque los resultados para « {term} »",
    "search-without-results": "sin resultados para « {term} »",
    "secondary": "secundario",
    "secondary background color": "secondary background color",
    "secondary border color": "secondary border color",
    "secondary text color": "secondary text color",
    "secret key": "llave secreta",
    "select": "select",
    "select a date from the calendar": "select a date from the calendar",
    "select a file": "seleccione un archivo",
    "select a value from the list": "seleccione un valor de la lista",
    "select all": "select all",
    "select file": "seleccionar archivo",
    "select files": "select files",
    "select icons": "seleccionar íconos",
    "select one or more values from the list": "seleccione uno o más valores de la lista",
    "select users that you want to add...": "seleccione usuarios que desea agregar...",
    "selected background color": "selected background color",
    "selected color": "color seleccionado",
    "selected picture": "selected picture",
    "selected text color": "selected text color",
    "selection": {
      "allItems": "todos los elementos están seleccionados",
      "clear": "eliminar",
      "items": "{number, plural, =0 {nada seleccionado} =1 {un elemento seleccionado} other {# elementos seleccionados}}"
    },
    "send": "enviar",
    "send newsletter": "send newsletter",
    "send test email": "enviar correo electrónico de prueba",
    "send to all recipients": "send to all recipients",
    "service available only for registered users": "servicio disponible solo para usuarios registrados",
    "set": "solicitar",
    "set a css color": "establecer un color css",
    "set a float number": "establecer un número flotante",
    "set a list of integers separated by comma": "establecer una lista de enteros separados por comas",
    "set an integer number": "establezca un número entero",
    "set as pending": "establecer como pendiente",
    "settings": "configuraciones",
    "shades": "sombras",
    "shadow": "shadow",
    "shape": "forma",
    "show advanced details": "mostrar detalles avanzados",
    "show all": "mostrar todos",
    "show all features": "mostrar todas las características",
    "show all icons": "mostrar todos los iconos",
    "show details": "mostrar detalles",
    "show download links": "mostrar enlaces de descarga",
    "show end slide": "show end slide",
    "show in fullscreen": "mostrar en pantalla completa",
    "show intro": "show intro",
    "show less": "mostrar menos",
    "show login": "show login",
    "show logo": "show logo",
    "show map": "mostrar mapa",
    "show more": "mostrar más",
    "show private contributions": "mostrar las contribuciones privadas",
    "show team page": "show team page",
    "show the contributions calendar": "mostrar las contribuciones del calendario",
    "show the map info box": "show the map info box",
    "show welcome presentation to new users": "Mostrar presentación de bienvenida a nuevas usuarias.",
    "showAsLineAfterZoom": "mostrar como una línea por el nivel de zoom",
    "side picture": "side picture",
    "sign in": "iniciar sesión",
    "sign out": "cerrar sesión",
    "site": "sitio",
    "site key": "leyenda del sitio",
    "sites": "sitios",
    "size": "tamaño",
    "skype": "skype",
    "slide": "slide",
    "slide-number": "slide {slideNumber}",
    "slug": "slug",
    "slug-double-dash": "The slug should not contain two consecutive dashes.",
    "slug-generic-invalid": "You can only use lower case characters, numbers and dashes.",
    "slug-invalid-chars": "The slug contains invalid chars.",
    "slug-no-space": "The slug should not contain spaces.",
    "slug-no-uppercase": "The slug should not contain upper case chars.",
    "slug-no-value": "This article has no slug. The id will be used to identify this article.",
    "slug-taken": "The slug is already used by another article.",
    "smtp": "smtp",
    "sounds": "sonidos",
    "source": "source",
    "space": "space",
    "space dashboard": "space dashboard",
    "space-needed-message": "It looks like you currently have no spaces available. You need to create a space first.",
    "space-no-custom-font-style-message": "There is no customized font style.",
    "spaces": "espacios",
    "spaces domains": "spaces domains",
    "spotlight-not-data-found": "spotlight-not-data-found",
    "start": "start",
    "start date": "fecha de inicio",
    "startTLS": "startTLS",
    "started on": "inición en",
    "starting start": "Starting `{start}`.",
    "stats": {
      "map-views": "Map views",
      "newsletter-emails": "Emails sent",
      "newsletter-interactions": "Emails interactions",
      "newsletter-open": "Emails opened",
      "newsletter-subscribers": "Subscribers",
      "survey-answer-contributors": "Survey contributors",
      "survey-contributions": "Survey contributions",
      "title": "Stats"
    },
    "status": "condición",
    "status message": "estado del mensaje",
    "store type": "tipo de tienda",
    "style": "estilo",
    "styles": "estilos",
    "subcategory": "subcategoria",
    "subheading": "subheading",
    "subheading text": "subheading text",
    "subject": "subject",
    "submenu item": "submenu item",
    "submit": "enviar",
    "submitted": "submitted",
    "submitted-by": "by",
    "submitted-by-anonymous": "by an anonymous user",
    "subscription": {
      "domains": "Domains",
      "expire": "Until",
      "hours": "{value, plural, =0 {none} =1 {one hour} other {# hours}}",
      "name": "Name",
      "remaining-billable-support": "Billable support",
      "remaining-free-support": "Remaining support",
      "title": "Subscription"
    },
    "success": "acierto",
    "suggest a photo": "sugerir una foto",
    "suggest photo": "suggest photo",
    "suggestions": "sugerencias",
    "surface": "superficie",
    "survey": "survey",
    "survey answer": "survey answer",
    "survey answers": "survey answers",
    "survey card list": "lista de cartas de campaña",
    "survey form": "survey form",
    "survey options": "opciones de campañas",
    "survey-answer-review-pending": "This contribution needs a review from some one from the team.",
    "survey-answer-review-processing": "This contribution is being processed.",
    "survey-answer-review-published": "This contribution is approved and published.",
    "survey-answer-review-rejected": "This contribution is rejected.",
    "survey-answer-review-unknown": "This contribution can not be reviewed.",
    "survey-answer-review-unpublished": "This contribution is approved and unpublished.",
    "survey-contribute-map-pending-message": "wants to contribute to",
    "survey-contribute-map-published-message": "contributed to",
    "survey-contribute-map-rejected-message": "did not contribute to",
    "survey-feature-description-disabled": "The user will not be prompted for a description.",
    "survey-feature-description-enabled": "The user will be prompted with the question:",
    "survey-feature-description-label": "pregunta para el campo de descripción",
    "survey-feature-icons-disabled": "The user will not be prompted to select icons.",
    "survey-feature-icons-enabled": "The user can select at least one icon and will be prompted with the question:",
    "survey-feature-icons-label": "iconos",
    "survey-feature-location-enabled": "The user will be asked:",
    "survey-feature-location-label": "location",
    "survey-feature-name-disabled": "The feature name will be generated on submit and the user will not be prompted for a name.",
    "survey-feature-name-enabled": "The user will be prompted with the question:",
    "survey-feature-name-label": "pregunta para el campo de nombre",
    "survey-feature-name-prefix": "prefijo del nombre de la función",
    "survey-feature-not-selected": "Before submitting the form, you need to select a feature on the map.",
    "survey-feature-pictures-default": "The user can add photos. They are not mandatory.",
    "survey-feature-pictures-enabled": "The user will be prompted with the question:",
    "survey-feature-pictures-label": "Photos",
    "survey-feature-pictures-mandatory": "The user can add photos. They are required.",
    "survey-location-field-message": "The user will be able to select a site:",
    "survey-locationAddress": "by searching an address",
    "survey-locationExistingFeature": "by using an existing map feature",
    "survey-locationGps": "by using their current location",
    "survey-locationManual": "by selecting a point on a map",
    "survey-no-address-found": "There are no addresses matching your search terms.",
    "survey-optional-icons-label": "Esta es la lista de iconos que puede seleccionar el colaborador.",
    "survey-optional-icons-question": "Optional icons list:",
    "survey-point-outside-extent": "The selected point is outside the map extent. It might not be interesting for this survey.",
    "survey-registered-users-only-message": "Para contribuir a esta campaña, debe iniciar sesión en su cuenta.",
    "survey-registered-users-only-title": "Se requiere autenticación",
    "survey-registration-mandatory-label": "Restringir a usuarios registrados",
    "survey-settings-description-label-placeholder": "ingrese la etiqueta para la pregunta del campo de descripción",
    "survey-settings-feature-name-prefix-placeholder": "ingrese un prefijo personalizado para las respuestas sin el campo de nombre",
    "survey-settings-icons-label-placeholder": "Ingrese la etiqueta para la pregunta del campo de iconos",
    "survey-settings-name-label-placeholder": "ingrese la etiqueta para la pregunta del campo de nombre",
    "survey-success-message": "Tu respuesta se guardó y está esperando una revisión.",
    "survey-success-title": "Tu respuesta fue guardada",
    "surveys": "surveys",
    "surveys for": "campañas para",
    "tablet": "tableta",
    "tablet options": "tablet options",
    "tagline": "eslogan",
    "teal": "teal",
    "team": "equipo",
    "team dashboard": "team dashboard",
    "team maps": "mapas del equipo",
    "team surveys": "campañas del equipo",
    "team-guest-count": "{count, plural, =0 {ningún invitados} =1 {un invitado} other {# invitados}}",
    "team-member-count": "{count, plural, =0 {ningún miembro} =1 {un miembro} other {# miembros}}",
    "team-needed-message": "Parece que actualmente no tienes espacios disponibles. Primero necesitas crear un espacio.",
    "team-role": {
      "guests": "guest",
      "leaders": "leader",
      "members": "member",
      "owners": "owner",
      "unknown": "unknown"
    },
    "teams": "equipos",
    "templates": "templates",
    "terms of Service": "términos del servicio",
    "text": "texto",
    "the attribute value is invalid.": "el valor del atributo es invalido.",
    "the attributes will be removed on save.": "los atributos serán removidos la próxima vez que guarde.",
    "the email used to send the emails": "el correo electrónico utilizado para enviar correo electrónicos",
    "the new password can't be empty": "la nueva contraseña no puede ser sin ningún carácter",
    "the new password must have at least 10 chars": "la contraseña nueva debe tener al menos 10 caracteres",
    "the passwords do not match": "las contraseñas no coinciden",
    "there are no changes": "no hay cambios",
    "there are no icons": "no hay íconos",
    "there are no pictures": "no hay imágenes",
    "there is no date interval set": "There is no date interval set.",
    "there is no description": "no hay una descripción",
    "there is nothing to choose": "no hay nada que eligir",
    "this base map has no name": "este mapa base no tiene nombre",
    "this calendar does not have a custom record name": "this calendar does not have a custom record name",
    "this map has no tagline": "este mapa no tiene eslogan",
    "this message has no subject": "this message has no subject",
    "this page has no layout": "esta página no tiene un esquema",
    "this presentation has no layout": "esta presentación no tiene esquema",
    "this presentation has no name": "esta presentación no tiene nombre",
    "this site has no pictures.": "este sitio no tiene fotos.",
    "this site is not linked to a map.": "este sitio no está conectado a un mapa.",
    "time interval": "time interval",
    "timeout": "tiempo de espera",
    "timezone": "Timezone",
    "title": "título",
    "title and article": "title and article",
    "title color": "color del titulo",
    "title with buttons": "título con botones",
    "title-import-emails": "How would you like to add emails?",
    "tls": "tls",
    "to": "to",
    "toggle dropdown": "alternar menú desplegable",
    "top": "top",
    "topmost": "más arriba",
    "translate": "traducir",
    "translate to": "traducir al {language}",
    "translation": "traducción",
    "trigger": "trigger",
    "type": "tipo",
    "type of encryption protocol to use": "tipo de protocolo de encripción para utilizar",
    "typography": "typography",
    "unknown": "desconocido",
    "unknown error": "error desconocido",
    "unpublish": "sin publicar",
    "unpublish newsletter": "unpublish newsletter",
    "until end": "Until `{end}`.",
    "unverified": "no verificado",
    "update": "actualizar",
    "update fields and get a preview": "actualizar campos y obtener una vista previa",
    "update password": "actualizar la contraseña",
    "updates": "actualizaciones",
    "upload": "upload",
    "upload a file": "upload a file",
    "use my current location": "usar mi ubicación actual",
    "use the parent image": "usa la imagen principal",
    "user": "user",
    "user is admin": "**{name}** es un administrador.",
    "user is regular user": "**{name}** es un usuario estándar.",
    "user name": "nombre de usuario",
    "user name to use for authentication": "nombre de usuario para utilizar para la autenticación",
    "username or public name": "nombre de usuario o nombre público",
    "users": "usuarios",
    "value": "valor",
    "vector tiles url": "vector tiles url",
    "verified": "verificado",
    "verify": "verificar",
    "vertical gutters": "canaletas verticales",
    "view": "view",
    "view articles": "view articles",
    "view features": "ver características",
    "view icons": "ver íconos",
    "view issues": "ver problemas",
    "view log": "ver registro",
    "view more": "ver más",
    "view site": "ver sitio",
    "view sites": "ver sitios",
    "view the site in browse mode": "ver el sitio en modo de búsqueda",
    "visibility": "visibilidad",
    "visibility on mobile": "visibility on mobile",
    "visibility options": "visibility options",
    "visibility-pending": "pendiente",
    "visibility-private": "privada",
    "visibility-public": "pública",
    "visible": "visible",
    "warning": "advertencia",
    "website url": "url del sitio web",
    "weight": "peso",
    "welcome": "Bienvenido",
    "welcome email": "welcome email",
    "welcome presentation": "presentación de bienvenida",
    "welcome to": "bienvenido a",
    "what is map name?": "¿cuál es el nombre del mapa?",
    "what is nearby?": "¿qué está cerca?",
    "what is team name?": "¿cuál es el nombre del equipo?",
    "what is the article slug?": "¿Qué es el slug del artículo?",
    "what is the calendar name?": "what is the calendar name?",
    "what is the data binding name?": "what is the data binding name?",
    "what is the event name?": "what is the {name} name?",
    "what is the feature name?": "¿cuál es el nombre de la característica?",
    "what is the icon name?": "¿cuál es el nombre del ícono?",
    "what is the map tagline?": "¿cuál es el eslogan del mapa?",
    "what is the message subject?": "what is the message subject?",
    "what is the newsletter description?": "what is the newsletter description?",
    "what is the newsletter name?": "what is the newsletter name?",
    "what is the page name?": "¿Cual es el nombre de la pagina?",
    "what is the page slug?": "¿Qué es la página slug?",
    "what is the presentation name?": "¿Cual es el nombre de la presentacion?",
    "what is the presentation slug?": "¿Qué es la babosa de presentación?",
    "what is the set name?": "¿cuál es el nombre del set?",
    "what is the space name?": "what is the space name?",
    "what is the survey name?": "cual es el nombre de la campaña?",
    "what's your username?": "¿cuál es su nombre de usuario?",
    "when": "when",
    "who owns the picture?": "who owns the picture?",
    "width": "ancho",
    "with icon": "con el ícono `{name}`",
    "with issues": "with issues",
    "with map": "del mapa `{name}`",
    "write a few words about the picture": "escriba unas cuantas palabras acerca de la imagen",
    "year": "año",
    "yellow": "yellow",
    "yes": "si",
    "yes and remove sites": "si y remover sitios",
    "you are already loged in.": "usted ya ha iniciado su sesión.",
    "you don't have an account?": "¿no tiene una cuenta?",
    "your API tokens": "su tokens de API",
    "your account": "su cuenta",
    "your account was activated.": "su cuenta fue activada.",
    "your api key": "su clave de api",
    "your api token": "su token de api",
    "your base maps": "sus maps base",
    "your icon sets": "sus sets de íconos",
    "your maps": "sus mapas",
    "your profile": "su perfil",
    "your sites": "sus sitios",
    "your teams": "sus equipos"
  }], ["fr-fr", {
    "-view": {
      "360 degree image": "360 degree image",
      "Filter icons by name": "Filter icons by name",
      "Please sign in here.": "Please sign in here.",
      "about you": "About you",
      "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
      "close": "close",
      "contact-form-success": "Your message has been successfully sent. We will reply soon.",
      "currency": {
        "default": "{value} {currency}",
        "eur": "{value}€",
        "gbp": "£{value}",
        "usd": "${value}"
      },
      "date": {
        "info.createdOn": "added on",
        "info.lastChangeOn": "last change on",
        "releaseDate": "released on"
      },
      "day of week": "day of week",
      "days": {
        "long": {
          "friday": "Friday",
          "monday": "Monday",
          "saturday": "Saturday",
          "sunday": "Sunday",
          "thursday": "Thursday",
          "tuesday": "Tuesday",
          "wednesday": "Wednesday",
          "weekday": "Weekday",
          "weekend": "Weekend"
        },
        "short": {
          "friday": "Fr",
          "monday": "Mo",
          "saturday": "Sa",
          "sunday": "Su",
          "thursday": "Th",
          "tuesday": "Tu",
          "wednesday": "We",
          "weekday": "Weekday",
          "weekend": "Weekend"
        }
      },
      "edit page": "edit page",
      "event-repetition": {
        "daily": "daily",
        "monthly": "monthly",
        "norepeat": "one off",
        "weekly": "weekly",
        "yearly": "yearly"
      },
      "event_date": {
        "interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
        "interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_same_day": "**{beginTime}** to **{endTime}**",
        "repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
        "repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginM}** **{beginD}**",
        "repeats_norepeat": "**{beginD} {beginM} {beginY}**",
        "repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
        "repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**"
      },
      "location": "location",
      "make private": "make private",
      "make public": "make public",
      "message-generic-list-empty": "Empty list",
      "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
      "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
      "message-missing-record": "No data source",
      "metrics": {
        "contributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "publicFeatures": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "published sites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "publishedSites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "total sites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalContributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "totalFeatures": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalSites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}"
      },
      "other-option": "Other",
      "other-option-input-label": "Please specify if you selected “Other”.",
      "page-not-found": {
        "contact-admin": "Please contact your administrator if you think this is a mistake.",
        "message": "Make sure the address is correct and the page hasn't moved.",
        "title": "Page Not Found"
      },
      "rotate": "rotate",
      "save the pin location": "save the pin location",
      "search": "search",
      "search for a place": "search for a place",
      "space dashboard": "space dashboard",
      "submit": "submit",
      "survey-registered-users-only-message": "In order to contribute to this survey you need to sign in to your account.",
      "survey-registered-users-only-title": "Authentication required",
      "team dashboard": "team dashboard",
      "visibility-pending": "pending",
      "visibility-private": "private",
      "visibility-public": "public"
    },
    "360 degree image": "Image de 360 degrés",
    "API Tokens": "API Tokens",
    "Add more icons": "Add more icons",
    "Add more icons:": "Add more icons:",
    "All records will be added as new.": "All records will be added as new.",
    "An unknown error occurred.": "Une erreur inconnue s´est produite.",
    "App Store": "App Store",
    "Are you sure you want to delete this account?": "Êtes-vous certain de vouloir supprimer ce compte?",
    "CAPTCHA": "CAPTCHA",
    "CRS": "CRS",
    "CRS using PROJ format": "CRS folosind formatul PROJ",
    "CSV": "CSV",
    "Choose file": "Choisir un fichier",
    "Create a team": "Créer une équipe",
    "Create account": "Créer un compte",
    "Current status": "Statut actuel",
    "DTLS v1.0": "DTLS v1.0",
    "Dashboard": "Dashboard",
    "Download CSV file": "Download CSV file",
    "Download on the": "Télécharger depuis",
    "E-Mail": "E-Mail",
    "E-Mail service": "Service de courrier électronique",
    "Explore the map": "Explorer la carte",
    "FAQ": "FAQ",
    "FROM SPACE VIEW": "FROM SPACE VIEW",
    "Filter icons by name": "Filtrer les icônes par nom",
    "Form": "Form",
    "GET IT ON": "Télécharger depuis",
    "GeoJson": "GeoJson",
    "GeoPackage": "GeoPackage",
    "Give a few words about the issue": "Expliquez brièvement le problème",
    "Go to the last slide": "Aller à la dernière diapositive",
    "Google Play": "Google Play",
    "Import to": "Importer vers",
    "Integrations": "Integrations",
    "Location": "Location",
    "Location Services": "Services de location",
    "Location error": "Location error",
    "Main settings": "Paramètres principaux",
    "Masking precision to use on maps for which masking is enabled": "Précision de masquage à utiliser sur les cartes pour lesquelles le masquage est activé",
    "Nominatim url": "L'adresse web du service Nominatim",
    "Please sign in here.": "Veuillez vous connecter ici.",
    "Public avatar": "Avatar public",
    "Remove user": "éliminer l´utilisateur",
    "Replace emails": "Replace emails",
    "Review": "Review",
    "Row": "Ligne",
    "SMTP": "SMTP",
    "SMTP host": "SMTP host",
    "SMTP port": "SMTP port",
    "Server error": "Erreur de serveur",
    "Take screenshot": "Prendre une capture d´écran",
    "Tell us about yourself": "Parlez-nous de vous",
    "The default icon sets will be used.": "Les jeux d´icône par défaut seront utilisés.",
    "The login article can not be edited.": "L'article de login ne peut pas être modifié.",
    "The mercator CRS will be used.": "The mercator CRS will be used.",
    "The row is not visible on this size.": "The row is not visible on this size.",
    "The site has no assigned map": "Le site n´est pas assigné à une carte",
    "There are no attributions": "There are no attributions",
    "There are no values": "There are no values",
    "There was an unexpected error.": "Il y a eu une erreur inattendue.",
    "Update by": "Mise à jour par",
    "What is language locale?": "Quel est la langue locale?",
    "What is the language name?": "Quelle est la langue?",
    "What is the local category?": "Quelle est la catégorie locale?",
    "What is the local name?": "Quel est le nom local?",
    "What is the local subcategory?": "Quelle est la sous-catégories locale?",
    "What is your job title?": "Quel est l´intitulé de votre position?",
    "What's next?": "Quelle est la prochaine étape?",
    "What's user name?": "Quel est le nom d´utilisateur?",
    "What's your linkedin name?": "Quel est votre nom d´utilisateur de    linkedin?",
    "What's your public name?": "Quel est votre nom public?",
    "What's your skype name?": "Quel est votre nom d´utilisateur de skype?",
    "What's your status?": "Quel est votre statut?",
    "What's your twitter handler?": "What's your twitter handler?",
    "What's your website url?": "Quel est le lien url de votre site web?",
    "Where are you located?": "Quelle est votre localisation?",
    "Where do you want to be contacted?": "Où voulez-vous être contacté ?",
    "Where?": "Où?",
    "You have successfully changed your password.": "Vous avez bien changé votre mot de passe.",
    "You have successfully removed your user.": "Vous avez bien éliminé votre utilisateur.",
    "about": "à propos de",
    "about you": "About you",
    "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
    "accent color": "accent color",
    "access": "accès",
    "account": "compte",
    "account activation": "activation du compte",
    "account info": "Information du compte",
    "account-info-message": "Voici quelques informations sur votre compte",
    "accuracy": "précision",
    "activation-expire-error": "Expiration du code d´activation. Veuillez recommencer, s´il vous plait.",
    "add": "ajouter",
    "add a site": "ajouter un site",
    "add another site": "ajouter un autre site",
    "add features as pending": "ajouter des fonctionnalités comme en attente",
    "add icon": "ajouter une icône",
    "add one or more maps": "ajouter une ou plusieurs cartes",
    "add to team": "Ajouter à l´équipe",
    "add-article-paragraph": "Cliquez ici si vous souhaitez en ajouter un",
    "add-article-title": "Cliquez ici pour ajouter un titre",
    "added on": "ajouté",
    "address": "adresse",
    "admin-delete-account-message": "Vous ne pouvez pas éliminer votre compte, car vous êtes l´administrateur. Demandez à un autre administrateur d´éliminer ce compte, ou d´éliminer vos droits d´administrateur.",
    "admin-missing-login-article": "“Il n´y a pas d´article pour la page de connexion.”",
    "administration": "administration",
    "advanced options": "options avancées.",
    "align": "aligner",
    "align items": "alignement des éléments",
    "alignment": "alignment",
    "all": "tout",
    "all base maps": "toutes les carte de bases",
    "all icon sets": "tous les jeux d´icônes",
    "all maps": "toutes les cartes",
    "all sites": "tous les sites",
    "all teams": "toutes les équipes",
    "all users": "tous les utilisateurs",
    "allow custom data bindings": "allow custom data bindings",
    "allow domain change": "allow domain change",
    "allow manage without teams": "permettre de gérer sans équipes",
    "allow many instances": "permettre de nombreux cas",
    "allow proposing sites": "Autoriser la proposition de sites",
    "allow-manage-without-teams-message": "Lorsque cette option est activée, tout utilisateur authentifié peut créer des `équipes`, `cartes`, `éléments`, `campagnes` et `icônes`.",
    "allow-proposing-sites-message": "Lorsque cette option est activée, l'option `Proposer un site` sera disponible pour les utilisateurs.",
    "allowed email domains": "domaines de courrier électronique autorisé",
    "allowed-email-domains-message": "Dresser une liste de tous les domaines qui sont autorisés. Les domaines doivent être séparés par `,` exemple `mondomaine1.com,mondomaine2.org`. Si la liste est vide, tous les courriers seront acceptés.",
    "allowed-spaces-domains-message": "Set a list of the domains that are allowed to be used by the spaces. The domains must be separated by `,` eg. `mydomain1.com,mydomain2.org`.",
    "already have an account?": "vous avez déjà un compte?",
    "altitude": "altitude",
    "altitude accuracy": "précision de l´altitude",
    "analytics": "analytics",
    "analyze": "analyze",
    "analyzing file": "le dossier est en cours d'analyse",
    "and": "et",
    "anonymous": "anonyme",
    "answer": "answer",
    "answer-message": {
      "no-contributor-questions": "The survey does not have contributor questions.",
      "no-generic-questions": "The survey does not include any additional general questions."
    },
    "answers": "answers",
    "appearance": "apparence",
    "approve": "approve",
    "area": "aire",
    "article": "article",
    "article content": "article content",
    "article date": "article date",
    "article title": "article title",
    "article-placeholders": {
      "add-calendar": "This is the calendar related to our project.",
      "add-event": "Presenting our exciting new {name}, carefully curated to bring you a unique and memorable experience. Get ready to be part of something extraordinary!",
      "add-icon": "C'est notre icône conçue par notre équipe.",
      "add-icon-set": "Voici notre nouveau jeu d'icônes conçu pour être utilisé dans nos nouveaux projets.",
      "add-map": "Ceci est notre carte, par rapport à notre projet.",
      "add-team": "C'est notre équipe, dédiée à cartographier tous nos projets."
    },
    "articles": "des articles",
    "as text value": "as text",
    "attribute": "attribut",
    "attributes": "attributs",
    "attributes-options-label": "Options (valeurs séparées par des virgules)",
    "attributions": "attributions",
    "authentication type to use": "type d´authentification à utiliser",
    "author": "auteur",
    "author-label": "par {name}",
    "auto": "auto",
    "back": "retour",
    "back to": "retour à {destination}",
    "back to our website": "back to our website",
    "back to search results": "retour aux résultats de la recherche",
    "background color": "couleur de fond",
    "background image": "image de fond",
    "background map": "background map",
    "backgroundColor": "couleur de fond",
    "banner": "bannière",
    "base map": "carte de base",
    "base maps": "cartes base",
    "baseline": "ligne de base",
    "be the first who adds one.": "Soyez le premier à en ajouter un.",
    "begins at": "begins at",
    "begins on": "begins on",
    "between": "entre",
    "between start and end": "Between `{start}` and `{end}`.",
    "bio": "bio",
    "blocks": "blocs",
    "blue": "blue",
    "boolean-": "unknown",
    "boolean-false": "no",
    "boolean-true": "yes",
    "boolean-undefined": "unknown",
    "border": "border",
    "borderColor": "couleur du bord",
    "borderWidth": "largeur du bord",
    "bottom": "bottom",
    "breadcrumb": "breadcrumb",
    "browse": "naviguer",
    "bs-property-bg-position-x": "x",
    "bs-property-bg-position-y": "y",
    "bs-property-bg-repeat": "répétition du fond",
    "bs-property-bg-size": "taille du fond",
    "bs-property-btn": "style",
    "bs-property-display": "affiche",
    "bs-property-fw": "épaisseur de la police",
    "bs-property-size-btn": "dimension",
    "bs-property-text": "alignement",
    "button": "button",
    "button label": "étiquette du bouton",
    "button link": "lien du bouton",
    "button type": "type de bouton",
    "buttons": "buttons",
    "by": "par",
    "by address": "par adresse",
    "calendar": "calendar",
    "calendar-entry-at": "at",
    "calendar-entry-begins-on": "Begins on",
    "calendar-entry-ends-on": "on",
    "calendar-entry-repeats-until": "until",
    "calendar-entry-to": "until",
    "calendar-entry.end-date-before-start": "The event ends before it starts.",
    "calendar-entry.interval-end-date-before-end": "The repetition end date is before the end of the event.",
    "calendars": "calendars",
    "campaign": "campagne",
    "campaign card list": "campaign card list",
    "campaign form": "campaign form",
    "campaign-location-type-address": "by address",
    "campaign-location-type-feature": "select an existing feature",
    "campaign-location-type-gps": "use my current location",
    "campaign-location-type-manual": "choose manually on the map",
    "campaigns": "campagnes",
    "can create events": "can create events",
    "can send newsletters": "can send newsletters",
    "can't upload": "ne peut pas envoyer",
    "cancel": "annuler",
    "cancel publishing": "cancel publishing",
    "cancelled": "annulé",
    "capture using GPS": "capturer en utilisant un Système de Positionnement Global (GPS)",
    "card list": "card list",
    "card options": "card options",
    "card view": "card view",
    "card-type": "card type",
    "categories": "categories",
    "category": "catégorie",
    "change": "changer",
    "change password": "changer le mot de passe",
    "choose manually": "choisir manuellement",
    "choose manually on the map": "choose manually on the map",
    "click here to create a schedule": "click here to create a schedule",
    "click to select an image": "cliquer pour sélectionner une image",
    "click to select from list": "cliquer por sélectionner dans la liste",
    "close": "fermer",
    "color": "couleur",
    "color palette": "color palette",
    "colors": "couleurs",
    "component": "component",
    "component count": "component count",
    "component id": "component id",
    "component-count": "component-count",
    "confirm new password": "confirmer le nouveau mot de de passe",
    "confirm your password": "confirmer votre mot de passe",
    "connection info": "connection info",
    "connectionInfo": "connection info",
    "contact form": "contact form",
    "contact-form-success": "Your message has been successfully sent. We will reply soon.",
    "container": "conteneur",
    "container options": "container options",
    "content": "content",
    "contributor": "contributor",
    "contributor questions": "contributor questions",
    "contributors": "contributeurs",
    "convert": "convert",
    "convert to global component": "convert to global component",
    "copy and paste": "copy and paste",
    "cover": "couverture",
    "cover photo": "photo de couverture",
    "cover photos": "photos de couverture",
    "cramMd5": "cramMd5",
    "create": "créer",
    "create a new API token": "créer un nouveau token d´API",
    "create a new account": "créer un nouveau compte",
    "create line": "créer une ligne",
    "create point": "créer un point",
    "create polygon": "créer un polygone",
    "create-first-map": "Aucune carte disponible.",
    "create-first-map-message": "Il semble que cette équipe n'ait pas encore de cartes. Créez une première carte pour commencer à ajouter et à visualiser des données.",
    "create-map": "Créer une carte",
    "created by": "créé par `{name}`",
    "created by you": "créé par `vous`",
    "created on": "créé le",
    "currency": {
      "default": "{value} {currency}",
      "eur": "{value}€",
      "gbp": "£{value}",
      "usd": "${value}"
    },
    "currency selector": "currency selector",
    "custom geometry": "géométrie personnalisée",
    "custom translations": "traductions personnalisées",
    "cyan": "cyan",
    "dark": "dark",
    "dashboard": "tableau de bord",
    "dashboards": "dashboards",
    "data binding": "data binding",
    "data bindings": "data bindings",
    "data source": "data source",
    "date": {
      "info.createdOn": "added on",
      "info.lastChangeOn": "last change on",
      "releaseDate": "released on"
    },
    "date interval": "date interval",
    "date-between": "between `{begin}` and `{end}`",
    "date-on": "on {date}",
    "date-on-detail": "on `{date}`",
    "day": "jour",
    "day-Friday": "Friday",
    "day-Monday": "Monday",
    "day-Saturday": "Saturday",
    "day-Sunday": "Sunday",
    "day-Thursday": "Thursday",
    "day-Tuesday": "Tuesday",
    "day-Wednesday": "Wednesday",
    "day-short": {
      "Friday": "Fri",
      "Monday": "Mon",
      "Saturday": "Sat",
      "Sunday": "Sun",
      "Thursday": "Thu",
      "Tuesday": "Tue",
      "Wednesday": "Wed"
    },
    "days": {
      "letter": {
        "friday": "V",
        "monday": "L",
        "sunday": "S",
        "wednesday": "M"
      },
      "long": {
        "friday": "Friday",
        "monday": "Monday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "thursday": "Thursday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "weekday": "Weekday",
        "weekend": "Weekend"
      },
      "short": {
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "Su",
        "thursday": "Th",
        "tuesday": "Tu",
        "wednesday": "We",
        "weekday": "Weekday",
        "weekend": "Weekend"
      }
    },
    "decimals-label": "{value, plural, =0 {0 décimales} =1 {1 décimale} other {# décimales}}",
    "default": "implicite",
    "default icons": "icônes par défaut",
    "default models": "default models",
    "default order": "default order",
    "default-base-maps-used-message": "The default base maps will be used.",
    "delete": "supprimer",
    "delete account": "{size, plural, =0 {invalid value} =1 {supprimer compte} other {supprimer # comptes}}",
    "delete all articles": "Delete all articles",
    "delete all campaign-answers": "delete all survey answers",
    "delete all events": "Delete all events",
    "delete all features": "Delete all features",
    "delete all icons": "delete all icons",
    "delete all pages": "Delete all pages",
    "delete article": "supprimer l'article",
    "delete base map": "{size, plural, =0 {invalid value} =1 {supprimer carte de base } other {supprimer # cartes de base}}",
    "delete base-map": "delete base-map",
    "delete basemap": "supprimer le carte de base",
    "delete calendar": "delete calendar",
    "delete campaign": "delete campaign",
    "delete campaign-answer": "delete survey answer",
    "delete data-binding": "delete data-binding",
    "delete event": "delete event",
    "delete feature": "{size, plural, =0 {invalid value} =1 {supprimer site} other {supprimer # sites}}",
    "delete icon": "{size, plural, =0 {invalid value} =1 {supprimer icône} other {supprimer # icônes}}",
    "delete icon-set": "{size, plural, =0 {invalid value} =1 {supprimer jeux d´icônes} other {supprimer # jeux d´icônes}}",
    "delete language": "{size, plural, =0 {invalid value} =1 {supprimer langue} other {supprimer # langues}}",
    "delete map": "{size, plural, =0 {invalid value} =1 {supprimer carte} other {supprimer # cartes}}",
    "delete non synced records": "delete non synced records",
    "delete page": "supprimer la page",
    "delete presentation": "supprimer la présentation",
    "delete site": "{size, plural, =0 {invalid value} =1 {supprimer site} other {supprimer # sites}}",
    "delete space": "supprimer l'espace",
    "delete survey": "{size, plural, =0 {invalid value} =1 {delete campagne} other {delete # campagnes}}",
    "delete team": "{size, plural, =0 {invalid value} =1 {supprimer équipe} other {supprimer # équipes}}",
    "delete token": "{size, plural, =0 {invalid value} =1 {supprimer token} other {supprimer # tokens}}",
    "delete translation": "{size, plural, =0 {invalid value} =1 {supprimer traduction} other {supprimer # traductions}}",
    "delete your account": "supprimer votre compte",
    "delete-all-confirmation-message-article": "Are you sure you want to delete all articles?",
    "delete-all-confirmation-message-campaign-answer": "Are you sure you want to delete all answers?",
    "delete-all-confirmation-message-event": "Are you sure you want to delete all events?",
    "delete-all-confirmation-message-feature": "Are you sure you want to delete all features?",
    "delete-all-confirmation-message-icon": "Are you sure you want to delete all icons?",
    "delete-all-confirmation-message-page": "Are you sure you want to delete all pages?",
    "delete-confirmation-message": "{size, plural, =0 {invalid value} =1 {Êtes-vous certain de vouloir supprimer `{name}`?} other {Êtes-vous certain de vouloir supprimer `#` éléments?}}",
    "delete-confirmation-site-success": "le site `{name}` a bien été supprimé",
    "description": "description",
    "description template": "description template",
    "deselect icon": "icône de désélection",
    "desktop": "ordinateur de bureau",
    "desktop options": "desktop options",
    "destination": "destination",
    "details": "details",
    "didn't receive a confirmation email?": "Vous n´avez pas reçu de courrier de confirmation?",
    "dimensions": "dimensions",
    "disabled": "disactivé",
    "display": "display",
    "display heading": "afficher l'en-tête",
    "display mode": "display mode",
    "display name": "nom d'affichage",
    "do not update": "Ne pas mettre à jour",
    "domain": "domain",
    "domain name": "nom de domaine",
    "domain-name-invalid-chars": "The domain name must contain only letters and number from the english alphabet.",
    "domain-name-update-message": ".{from} will be replaced with .{to}",
    "domain-status": {
      "invalid-domain": "Looks like your domain name is invalid. Try renaming it and give the setup another go.",
      "needs-admin-setup": "Your domain name is reserved and needs one of our admins to finish the setup. Hang tight until you get a confirmation message.",
      "pending": "Your domain will be ready soon; the setup just hasn’t started yet.",
      "ready": "Your space is ready at the reserved domain.",
      "setting-up": "Your space is being set up at the reserved domain."
    },
    "done": "effectué",
    "downgrade to regular user": "rétrograder en utilisateur normal",
    "downgrade user": "rétrograder utilisateur",
    "downgrade-user-question": "Êtes-vous certain de vouloir rétrograder `{name}`?",
    "downgrade-user-success-message": "Vous avez bien rétrogradé `{name}` en utilisateur normal.",
    "download": "télécharger",
    "download CSV": "télécharger CSV",
    "download GeoJson": "télécharger GeoJson",
    "downscale images for faster upload": "réduire la taille des images pour un envoi plus rapide",
    "e-mail": "e-mail",
    "edit": "modifier",
    "edit article": "modifier article",
    "edit base map": "modifier la carte de base",
    "edit calendar": "edit calendar",
    "edit content": "modifier le contenu",
    "edit data binding": "edit data binding",
    "edit event": "edit event",
    "edit feature": "edit feature",
    "edit geometry": "modifier la géométrie",
    "edit icon": "edit icon",
    "edit icon set": "modifier le jeux d´icônes",
    "edit layout": "modifier la mise en page",
    "edit map": "modifier la carte",
    "edit newsletter": "edit newsletter",
    "edit notification": "modifier la notification",
    "edit page": "modifier la page",
    "edit presentation": "modifier la présentation",
    "edit site": "modifier le site",
    "edit space": "espace d'édition",
    "edit survey": "modifier la campagne",
    "edit team": "modifier l´équipe",
    "edit the map": "modifier la carte",
    "edit to add one.": "En ajouter un.",
    "edit user": "modifier l´utilisateur",
    "edit-article-label": "modifier l'article",
    "edit-label": "modifier",
    "edit-model": {
      "created-on": "créé le {time}",
      "last-change": "par {name} {time}",
      "title": "Editer {name}"
    },
    "edit-model.created-on": "created on",
    "email": "envoyer un courrier électronique",
    "email a new issue": "rapporter un problème par courrier électronique",
    "embedded map": "carte intégrée",
    "empty": "vide",
    "enable all notifications": "activer toutes les notifications",
    "enable links": "enable links",
    "enable the show more button": "enable the show more button",
    "enabled": "activé",
    "end": "end",
    "end date": "date de fin",
    "ended on": "terminé le",
    "ends at": "ends at",
    "ends on": "ends on",
    "enter again your new password": "Encodez de nouveau votre mot de passe",
    "enter password": "Encodez votre mot de passe",
    "enter search keywords": "saisir des mots-clés de recherche",
    "enter the token name": "encodez le nom du token",
    "enter your e-mail": "encodez votre adresse e-mail",
    "enter your new password": "encodez votre nouveau mot de passe",
    "entry": "entry",
    "error": "erreur",
    "errors": "erreurs",
    "event": "event",
    "event dates": "event dates",
    "event location": "event location",
    "event schedule": "event schedule",
    "event-at": "at",
    "event-repetition": {
      "daily": "daily",
      "monthly": "monthly",
      "norepeat": "one off",
      "weekly": "weekly",
      "yearly": "yearly"
    },
    "event_date.interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
    "event_date.interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_same_day": "**{beginTime}** to **{endTime}**",
    "event_date.repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
    "event_date.repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginD}**",
    "event_date.repeats_norepeat": "**{beginD} {beginM} {beginY}**",
    "event_date.repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
    "event_date.repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**",
    "everything is in order": "tout est réglé",
    "existing": "existant",
    "expire date": "date d´expiration",
    "expires on": "expire le",
    "export emails with stats": "export emails with stats",
    "external link": "lien externe",
    "extra icons": "icônes supplémentaires",
    "failed_restored_site_alert_message": "Votre session précédente n´a pas été restaurée car les données sont corrompues.",
    "feature": "élément",
    "feature attributes": "feature attributes",
    "feature campaign contribution button": "feature campaign contribution button",
    "feature clustering": "regroupement d'entités",
    "feature field": "champ de l'enregistrement",
    "feature issue button": "feature issue button",
    "feature name prefix": "préfixe de nom de fonction",
    "feature-on-map": "on",
    "feature-pending-count-message": "{count, plural, =0 {Il n'y a aucun élément en attente} =1 {Il y a un élément en attente} other {Il y a # éléments en attente}}",
    "feature-pending-message": "Cet élément est en attente",
    "feature-success-message": "Votre site a été sauvegardé et il est en attente d'une révision.",
    "feature-success-title": "Votre site a été sauvegardé.",
    "feedback-bio": "Présentez-vous en moins de 250 caractères",
    "feedback-full-name": "Donnez votre nom pour que l´on puisse vous reconnaitre.",
    "feedback-linkedin": "Votre nom de profil de LinkedIn linkedin.com/in/profilename",
    "feedback-organization": "Qui représentez-vous? Pour qui travaillez-vous?",
    "field matching": "champs correspondants",
    "fields": "fields",
    "fields matching": "champs correspondants",
    "file": "fichier",
    "file field": "champ du fichier",
    "filter": "filtre",
    "filter with more than one icon": "filter with more than one icon",
    "filters": "filters",
    "fix": "réparer",
    "font": "font",
    "font styles": "font styles",
    "font-awesome-message": "Vous pouvez séleccionner une icône gratuite de la collection FontAwesome.",
    "font-h1": "Heading 1",
    "font-h2": "Heading 2",
    "font-h3": "Heading 3",
    "font-h4": "Heading 4",
    "font-h5": "Heading 5",
    "font-h6": "Heading 6",
    "font-paragraph": "Paragraph",
    "forgot your password?": "Vous avez oublié votre mot de passe?",
    "fragment": "fragment",
    "from": "from",
    "full name": "nom",
    "gallery": "gallerie",
    "general": "général",
    "general questions": "general questions",
    "generating preview": "l'aperçu est généré",
    "geo-json-allowAddress": "allow searching for an address",
    "geo-json-allowExistingFeature": "allow selecting a feature from the map",
    "geo-json-allowGps": "allow gps",
    "geo-json-allowManual": "allow setting a point on the map",
    "geo-location-permission-denied": "Cette application n´a pas l´autorisation d´utiliser votre localisation.",
    "geo-location-permission-timeout": "The time allowed to acquire your location was reached before the information was obtained.",
    "geo-location-permission-unavailable": "The acquisition of your location failed because at least one internal source of position returned an internal error.",
    "geo-location-unknown-error": "Du à une erreur inattendue, votre localisation n´est pas disponible.",
    "geoJson editor": "editeur geoJson",
    "geojson-feature-file-message": "Selectionez un fichier.json, .geojson ou .gpx",
    "geometry": "geometrie",
    "geometry options": "geometry options",
    "get gpx file": "get gpx file",
    "global": "global",
    "global map page": "global map page",
    "go to homepage": "retour à la page initiale",
    "go to the main page": "retour à la page principale",
    "green": "green",
    "group": "groupe",
    "group name": "nom de groupe",
    "groups": "groups",
    "grow": "grow",
    "guests": "invités",
    "gutter": "gutter",
    "hamburger menu on": "hamburger menu on",
    "has custom style": "a un style personnalisé",
    "heading": "heading",
    "heading picture": "heading picture",
    "heading text": "heading text",
    "height": "height",
    "help": {
      "map": {
        "area": "This is the geographic area your map will cover."
      },
      "survey": {
        "icons": "Ces icônes seront ajoutées automatiquement à chaque réponse de campagne, le contributeur ne pourra pas les sélectionner."
      }
    },
    "help message": "message d'aide",
    "hidden-contribution-calendar-message": "This contribution calendar is hidden. Vous pouvez le publier en modifiant le profil.",
    "hide advanced details": "masquer les détails avancés",
    "hide calendar": "hide calendar",
    "hide details": "masquer les détails",
    "hide on main map": "hide on main map",
    "hide options": "masquer les options",
    "homepage map extent": "L'extension de la carte de la page d'accueil",
    "horizontal gutters": "chéneaux horizontaux",
    "horizontal line": "horizontal line",
    "hour": "hour",
    "i forgot my password": "j´ai oublié mon mot de passe",
    "icon": "icône",
    "icon grid with categories": "icon grid with categories",
    "icon list": "icon list",
    "icon name": "nom de l´icône",
    "icon set": "jeux d´icônes",
    "icon sets": "jeux d´icônes",
    "icon-inherited-style-message": "“Cette icône utilise le style du jeux d´icône.” is using the icon set style.\"",
    "icon-label": "icône",
    "icon-missing-attributes-message": "Cette icône n´a pas d´attributs.",
    "icon-not-matched-message": "Il n´y a pas d´icône correspondante",
    "icon-set": "jeux d´icônes",
    "icons": "icônes",
    "icons per line": "icons per line",
    "ignored": "ignored",
    "import": "importer",
    "import a new file": "importer un nouveau fichier",
    "import emails": "import emails",
    "import file to": "importer le fichier dans",
    "imported": "importé",
    "indigo": "indigo",
    "inherited attribute": "attribut hérité",
    "inside area": "dans `{name}`",
    "integrations": "intégrations",
    "invalid vars": "variables invalides",
    "invalid-file-type-message": "vous avez donné un type de fichier non supporté.",
    "invalid-gpx-missing-track-or-route": "Le fichier gpx doit contenir au moins une route ou une trace.",
    "invalid-json-feature-message": "Géométrie invalide dans le fichier. Seulement les Point, LineString, MultiLineString and Polygon sont permis",
    "invalid-json-feature-type-message": "Le fichier contient une `{type}` geometrie. Seulement les Point, LineString, MultiLineString et Polygon sont permis.",
    "invalid-json-file-message": "The file contains an invalid Json",
    "is activated": "est activé",
    "is attribute": "est un attribut",
    "is default": "is default",
    "is index": "est index",
    "is private": "est privé",
    "is public": "est public",
    "is required": "est requis",
    "is template": "is template",
    "is-multi-project-mode-message": "Lorsque cette option est activée, la section `Naviguer` sera disponible pour les utilisateurs réguliers.",
    "isVisible": "est visible",
    "issue-count": "{issues, plural, =0 {sans problèmes} =1 {une problème} other {# problèmes}}",
    "issues": "problèmes",
    "item": "élément",
    "items": "éléments",
    "job title": "intitulé du poste",
    "job-title": "{jobTitle} at {organization}",
    "joined time": "temps joint",
    "justify content": "disposition du contenu",
    "keep it on map when it is small": "gardez-le sur la carte quand il est petit",
    "key": "clé",
    "label": "étiquette",
    "label style": "label style",
    "landing page": "landing page",
    "language": "langue",
    "languages": "langue",
    "last activity": "Dernière Activité",
    "last change": "dernier changement",
    "latest version": "dernière version",
    "latitude": "latitude",
    "layer": "couche",
    "layer-count": "{count, plural, =0 {pas de couches} =1 {1 couche} other {# couches}}",
    "layers": "couches",
    "layout": "schéma",
    "leaders": "leaders",
    "leave a message to our team": "leave a message to our team",
    "license": "licence",
    "light": "light",
    "line": "ligne",
    "line color": "couleur de la ligne",
    "line height": "line height",
    "line marker": "line marker",
    "lineDash": "tiret",
    "lineHeight": "hauteur de la ligne",
    "lines": "lignes",
    "link": "lien",
    "link list": "link list",
    "linkedin": "linkedin",
    "list": "list",
    "list customization": "list customization",
    "list view": "list view",
    "load more": "load more",
    "local name": "nom local",
    "locale": "local",
    "location": "emplacement",
    "location mode": "mode de localisation",
    "location-city": "Ville",
    "location-country": "Pays",
    "location-postalCode": "Code postal",
    "location-province": "Province / État",
    "login": "login",
    "logo": "logo",
    "longitude": "longitude",
    "mailersend.com": "mailersend.com",
    "mailersend.com key": "clé mailersend.com",
    "main picture": "photo principale",
    "main title": "main title",
    "make private": "rendre privé",
    "make public": "rendre public",
    "manage": "gérer",
    "manage team": "gérer l'équipe",
    "manage-section": {
      "deleting": "éliminer {name}",
      "map-calendar": "Events from this calendar will use locations from",
      "map-mask": "Les entités appartenant à cette carte seront masquées avec les entités.",
      "map-survey": "Les réponses de cette campagne seront ajoutées sur la carte",
      "no-map-calendar": "Events from this calendar will not use map locations.",
      "no-map-mask": "Les entités appartenant à cette carte ne seront pas masquées.",
      "no-map-survey": "Les réponses de cette campagne ne seront pas ajoutées à une carte.",
      "site-note": "créé {time}, {distance} loin",
      "unset-team": "Cet enregistrement n´a pas d´équipe. Vous devez en définir un pour avoir une propriété valide."
    },
    "map": "carte",
    "map extent": "extension de la carte",
    "map feature": "map feature",
    "map files": "fichiers de la carte",
    "map map view": "vue sur la carte",
    "map sheet": "feuille de carte",
    "map view": "vue de la carte",
    "maps": "cartes",
    "maps options": "maps options",
    "marker": "marqueur",
    "mask": "masque",
    "mask points": "masquer éléments",
    "masked feature info": "Le masquage d'emplacement est appliqué, de sorte que l'emplacement affiché publiquement sera imprécis.",
    "masking-precision-message": "La nouvelle valeur peut prendre jusqu'à 1 heure pour se mettre à jour pour les données existantes. Le nombre de décimales défini déterminera le nombre de décimales conservées par rapport à la valeur initiale de l'emplacement. Par exemple, pour une valeur de `0 décimale`, une position comme `32,42587` deviendra `32`, et pour une valeur de `1 décimal`, cette même position deviendra `32,4`.",
    "match based on a field": "match basé sur un champ",
    "member": "membre",
    "member of": "membre de",
    "member-since": "Membre depuis {time}",
    "members": "membres",
    "menu": "menu",
    "menu buttons": "menu buttons",
    "menu functionality": "menu functionality",
    "menu item": "élément du menu",
    "menu style": "menu style",
    "message": "message",
    "message-alert-attributes-missing": "There are no attributes.",
    "message-alert-duplicate-icon-name": "Vous utilisez les deux icônes du même nom: `{name}`. Retirez celui de `{iconSet}`.",
    "message-alert-duplicate-icon-name-default": "Vous utilisez les deux icônes du même nom: `{name}`. Supprimez celui-ci de la liste par défaut.",
    "message-alert-duplicate-icon-name-optional": "Vous utilisez les deux icônes du même nom: `{name}`. Supprimez celui-ci de la liste optionnelle.",
    "message-alert-file-already-imported": "Ce fichier a déjà été importé. Faites attention à ne pas dupliquer les enregistrements existants.",
    "message-alert-file-not-analyzed-yet": "Ce fichier n'a pas encore été analysé.",
    "message-alert-file-without-preview": "Ce fichier ne peut pas être prévisualisé.",
    "message-alert-icon-set-private": "Le jeux d´icônes est privé et cette icône n'est pas visible par les utilisateurs qui ne font pas partie de votre équipe.",
    "message-alert-invalid-connection-info": "The connection configuration is invalid.",
    "message-alert-invalid-connection-info-type": "The selected connection type is not supported yet.",
    "message-alert-license-not-set": "La carte n'a pas de licence. En définir un, aide les autres à savoir comment ils peuvent utiliser la carte.",
    "message-alert-map-with-unpublished-basemaps": "Cette carte est en utilisant des cartes de base privées: `{names}`. Les utilisateurs extérieurs à votre équipe ne pourront pas voir correctement cette carte.",
    "message-alert-map-with-unrelated-basemaps": "Cette carte utilise des cartes de base appartenant à d'autres équipes : `{names}`. Corrigez ce problème.",
    "message-alert-map-with-unrelated-iconsets": "Cette carte utilise des jeux d'icônes appartenant à d'autres équipes : `{names}`. Corrigez ce problème.",
    "message-alert-missing-connection-info-type": "Select a type before setting the connection info.",
    "message-alert-missing-destination": "The destination is not set.",
    "message-alert-missing-fields": "There are no fields to be mapped.",
    "message-alert-page-private": "Cette page est privée.",
    "message-alert-parent-icon-private": "Les propriétés héritées ne sont pas accessibles aux utilisateurs qui ne font pas partie de votre équipe, car l'icône parent est privée.",
    "message-alert-pictures-missing": "There are no pictures.",
    "message-alert-select-icon": "Vous devez définir une image pour cette icône.",
    "message-alert-sounds-missing": "There are no sounds.",
    "message-alert-survey-add-notification": "Les contributeurs authentifiés recevront une notification lors de l'ajout.",
    "message-alert-survey-no-notifications": "La campagne n'a pas de notifications configurées.",
    "message-copy-paste-emails": "Directly paste in new emails from a spreadsheet or similar list.",
    "message-dns-setup": "Pour accéder à ce domaine, créez un nouvel enregistrement DNS.",
    "message-dns-verification": "Pour vérifier la propriété de votre domaine, ajoutez la clé ci-dessus à un enregistrement TXT dans votre configuration DNS.",
    "message-email-count": "{count, plural, =0 {no emails} =1 {one email} other {# emails}}",
    "message-empty-layout": "La mise en page n'a pas de conteneurs. Vous devez modifier la mise en page avant de modifier le contenu.",
    "message-event-entry-list-empty": "Unfortunately, there are no scheduled dates for this event at the moment.",
    "message-generic-list-empty": "Empty list",
    "message-import-emails": "Grow your newsletter audience by adding or importing contacts who have already given you permission to contact them.",
    "message-map-marker-move": "Arrastre y acerque el mapa para establecer la ubicación del pin y luego presione el botón `guardar la ubicación del pin`",
    "message-map-marker-point-move": "Arrastre y acerque el mapa para establecer la ubicación del pin y luego presione el botón `guardar`",
    "message-missing-record": "No data source",
    "message-newsletter-send-to-all": "Are you sure you want to send this message to all your subscribers?",
    "message-newsletter-unpublish": "Are you sure you want to unpublish this message?",
    "message-no-page": "No page is set.",
    "message-no-schedule": "There is no defined schedule.",
    "message-no-team-description": "The team has no description outlining its members, activities, and goals.",
    "message-not-right-location": "Is there a better location?",
    "message-page-col-empty": "Cette colonne est vide.",
    "message-replace-emails-confirmation": "Are you sure you want to replace the existing emails?",
    "message-reused-gid": "l'id est déjà utilisé par un autre composant",
    "message-selectable-list-empty": "Looks like there’s nothing here. Add a new record to get started!",
    "message-upload-email-file": "Import contacts from a CSV or TXT file.",
    "message-view-min-max-zoom": "L'icône est visible entre les niveaux de zoom `{minZoom}` et `{maxZoom}`.",
    "message.no-cover": "There is no picture.",
    "message.no-selected-icons": "There are no selected icons.",
    "message.no-survey-questions": "This survey has no general questions",
    "metrics": {
      "contributors": "{value, plural, =0 {aucun contributeur} =1 {un contributeur} other {# contributeurs}}",
      "publicFeatures": "{value, plural, =0 {aucun élément publié} =1 {un élément publié} other {# éléments publiés}}",
      "published sites": "{value, plural, =0 {aucun site publié} =1 {un site publié} other {# sites publiés}}",
      "publishedSites": "{value, plural, =0 {aucun site publié} =1 {un site publié} other {# sites publiés}}",
      "total sites": "{value, plural, =0 {aucun site} =1 {un site} other {# sites}}",
      "totalContributors": "{value, plural, =0 {aucun contributeur} =1 {un contributeur} other {# contributeurs}}",
      "totalFeatures": "{value, plural, =0 {aucun élément} =1 {un élément} other {# éléments}}",
      "totalSites": "{value, plural, =0 {aucun site} =1 {un site} other {# sites}}"
    },
    "metrics.publicSites": "metrics.publicSites",
    "min height": "hauteur minimale",
    "minute": "minute",
    "missing-paragraph-message": "L'article n'a pas aucun paragraphe.",
    "missing-title-message": "Vous devez fournir un titre de niveau 1 pour le titre de l´article.",
    "mobile": "mobile",
    "mobile options": "mobile options",
    "mode": "mode",
    "model": "model",
    "model-items": {
      "category": "category",
      "icon": "icon",
      "iconSet": "icon set",
      "map": "map",
      "subcategory": "subcategory",
      "team": "team"
    },
    "month": {
      "short": {
        "april": "Avr",
        "august": "Août",
        "december": "Dec",
        "february": "Fev",
        "january": "Jan",
        "july": "Juil",
        "june": "Juin",
        "march": "Mar",
        "may": "Mai",
        "november": "Nov",
        "october": "Oct",
        "september": "Sep"
      }
    },
    "month-label": "mois",
    "mtcaptcha-message": "Vous pouvez éviter le trafic de spam en générant un compte mtCAPTCHA ici:",
    "multi project mode": "mode multi-projets",
    "name": "nom",
    "name and description": "name and description",
    "name used in data exports": "name used in data exports",
    "name-first": "Prénom",
    "name-last": "Nom de famille",
    "name-salutation": "Salutation",
    "name-title": "Titre",
    "never": "jamais",
    "new": "Nouveau",
    "new article": "nouvel article",
    "new base map": "ajouter une carte de base",
    "new calendar": "new calendar",
    "new campaign": "nouvelle campagne",
    "new data binding": "new data binding",
    "new event": "new event",
    "new feature": "nouvel élément",
    "new icon": "nouvelle icône",
    "new icon set": "nouveau jeu d´icônes",
    "new issue": "new issue",
    "new language": "nouvelle langue",
    "new map": "nouvelle carte",
    "new message": "new message",
    "new newsletter": "new newsletter",
    "new page": "nouvelle page",
    "new paragraph": "Nouveau paragraphe",
    "new password": "nouveau mot de passe",
    "new presentation": "nouvelle présentation",
    "new record": "new {name}",
    "new site": "nouveau site",
    "new space": "new space",
    "new survey": "new survey",
    "new team": "nouvelle équipe",
    "new title": "Nouveau titre",
    "new user can join": "un nouveau utilisateur peut se joindre",
    "new-token-confirm-message": "S´assurer de sauver - Vous ne pourrez plus avoir accès",
    "new-token-expire-message": "Sélectionner la date d´expiration de votre API token.",
    "new-token-message": "Donner un nom à votre API token, pour vous souvenir pourquoi vous l´utilisez.",
    "newsletter": "newsletter",
    "newsletters": "newsletters",
    "no": "non",
    "no maps available in this area.": "Aucune carte disponible dans cette région.",
    "no-answer": "No answer.",
    "no-icon-attributes-message": "l´icône n´a pas d´attributs",
    "no-layers-set-message": "Il n´y a pas de couches cartographiques.",
    "nominatim-url-info": "Veuillez vous assurer de respecter la politique d'utilisation du service que vous avez choisi. Consultez la page wiki à https://wiki.openstreetmap.org/wiki/FR:Nominatim pour plus d'informations.",
    "none": "aucune",
    "not published": "pas publié",
    "not set": "pas defini",
    "not-found-features-search-message": "Pas de resultats pour votre recherche - {term} -",
    "not-found-features-suggestion-message": "Essayez de chercher dans toutes les cartes.",
    "not-joined-a-team-user": "{name} ne fait pas partie d´une équipe.",
    "not-joined-a-team-you": "Vous n´appartenez à aucune équipe.",
    "nothing": "rien",
    "notifications": "notifications",
    "offsetX": "offset X",
    "offsetY": "offset Y",
    "on all zoom levels": "Tous niveaux de zoom",
    "only for administrators": "seulement pour les administrateurs",
    "only one variable is allowed in the path": "une seule variable est autorisée dans le chemin",
    "only pending": "seulement `en attente`",
    "only published": "seulement `publié`",
    "only unpublished": "seulement `non publié`",
    "opacity": "opacité",
    "open": "ouvert",
    "option-panel": {
      "accent color": "accent color",
      "active-color": "active color",
      "allow-address": "allow users to select an address",
      "allow-existing-feature": "allow users to select a feature from the map",
      "allow-gps": "allow users to use their current location",
      "allow-manual": "allow users to select a site on the map",
      "anchor": "anchor",
      "attribute-name": "attribute name",
      "attribute-path": "attribute path",
      "attribute-style": "attribute style",
      "background-color": "background color",
      "border-color": "border color",
      "bs-property-align-items": "align items",
      "bs-property-bg-position-x": "background position x",
      "bs-property-bg-position-y": "background position y",
      "bs-property-bg-repeat": "background repeat",
      "bs-property-bg-size": "background size",
      "bs-property-border-radius": "border radius",
      "bs-property-border-size": "border size",
      "bs-property-border-visibility": "border visibility",
      "bs-property-btn": "style",
      "bs-property-display": "display",
      "bs-property-flex-direction": "direction",
      "bs-property-fw": "font weight",
      "bs-property-justify-content": "justify content",
      "bs-property-line-height": "line height",
      "bs-property-min-lines": "minimum lines height",
      "bs-property-size-btn": "size",
      "bs-property-text": "align",
      "bs-property-text-line-clamp": "line clamp",
      "bs-property-text-size": "size",
      "bs-property-width": "width",
      "button": "button",
      "button-label": "label",
      "button-type": "button type",
      "buttons": "buttons",
      "calendar": "calendar",
      "card-type": "card type",
      "categories": "categories",
      "category-list": "categories",
      "category-style": "category style",
      "change component type": "change component type",
      "color": "color",
      "colors": "colors",
      "component": "component",
      "component-margin": "component margin",
      "container": "container",
      "container-list": "container list",
      "container-properties": "container",
      "content-picker": "content picker",
      "copy": "copy",
      "count": "Count",
      "cover": "cover",
      "css-height": "height",
      "css-size": "size",
      "css-width": "width",
      "currencies": "currencies",
      "currency": "currency",
      "currency-style": "currency style",
      "custom-location-question": "custom location question",
      "custom-question": "custom question",
      "daily": "daily",
      "data-source": "data source",
      "default-category": "default category",
      "default-cover": "default cover",
      "default-value": "default value",
      "description": "description",
      "description-label": "description label",
      "destination": "destination",
      "details": "details",
      "effect": "Effect",
      "email-label": "email label",
      "empty-message": "empty message",
      "enable-full-map-view": "enable full map view",
      "enable-map-interaction": "Map interaction",
      "enable-pinning": "enable pinning",
      "entries": "entries",
      "example-model-id": "example model id",
      "extra-fields": "extra fields",
      "feature-hover": "On feature hover",
      "feature-selection": "On feature selection",
      "filter-index": "filter",
      "flex-config": "flex box configuration",
      "floating-labels": "enable floating labels",
      "fly-over-animation": "Fly over animation",
      "form": "form",
      "full-description": "use full description",
      "gutter": "gutter",
      "heading": "heading",
      "height": "height",
      "hover-color": "hover color",
      "icon-container": "icon container",
      "icon-set": "icon set",
      "icon-style": "icon style",
      "icons": "icons",
      "icons-label": "icons label",
      "image-size": "Image size",
      "input-group": "group input",
      "is-mandatory": "is required",
      "items-per-page": "items per page",
      "justify-content": "justify content",
      "label": "label",
      "label-customization": "label customization",
      "label-success": "success label",
      "layers": "layers",
      "layout": "layout",
      "link": "link",
      "link-name": "link name",
      "list-count": "list count",
      "logo": "logo",
      "map": "map",
      "margin": "margin",
      "max-items": "max items",
      "menu-button-title": "button {index}",
      "menu-button-title-child": "dropdown {index}",
      "message-label": "message label",
      "mode": "Mode",
      "model": "model",
      "model-name": "name",
      "monthly": "monthly",
      "name": "name",
      "name-label": "name label",
      "newsletter": "newsletter",
      "next-date-with-location": "next date with location",
      "next-occurrence": "next occurrence",
      "norepeat": "one time",
      "open-in-new-tab": "open in a new tab",
      "padding": "padding",
      "paddings": "paddings",
      "paragraph": "paragraph",
      "paragraph-index": "paragraph index (counting from 0)",
      "paste": "paste",
      "paste style": "paste style",
      "picture": "picture",
      "picture-options": "picture options",
      "pictures": "pictures",
      "placeholder-text": "placeholder text",
      "position-x": "x",
      "position-y": "y",
      "predefined colors": "predefined colors",
      "price": "price",
      "price-details": "price-details",
      "price-list": "price list",
      "primary-photo": "primary photo",
      "properties": "properties",
      "property": "property",
      "proportion": "proportion",
      "restrict-view": "Restrict view",
      "revisions": "revisions",
      "row-properties": "row",
      "schedule": "schedule",
      "selected color": "selected color",
      "send-to": "send to",
      "server-type": "server type",
      "shades": "shades",
      "show search": "show search",
      "show-base-map-selection": "Show base map selection",
      "show-content-after": "Show the content after",
      "show-photo-list": "show photo list",
      "show-primary-photo-attributions": "show primary photo attributions",
      "show-title": "show title",
      "show-user-location": "Show user location",
      "show-zoom-buttons": "Show zoom buttons",
      "size": "size",
      "size-mode": "size mode",
      "sizing-mode": "mode",
      "slot-name": "slot name",
      "slot-properties": "slot",
      "source": "source",
      "source-field": "source field",
      "source-model-key": "source model key",
      "space": "space",
      "stat-customization": "stat customization",
      "stats": "stats",
      "store-type": "store type",
      "style": "style",
      "styles": "styles",
      "subcategory-style": "subcategory style",
      "subject": "subject",
      "subtitle": "subtitle",
      "text": "text",
      "text-color": "text color",
      "text-font": "font family",
      "text-list": "text list",
      "text-style": "text style",
      "tiled": "tiled",
      "title": "title",
      "unlink global component": "unlink global component",
      "url": "url",
      "use-fixed-value": "value",
      "use-the-current-model": "use the current model",
      "use-the-default-model": "use the default model",
      "use-value-from": "use the value from",
      "value": "value",
      "version": "version",
      "vertical-align": "vertical align",
      "vertical-size": "vertical size",
      "visibility": "visibility",
      "weekly": "weekly",
      "width": "width",
      "with-search": "with search",
      "yearly": "yearly"
    },
    "optional": "optionnelle",
    "optional icons": "icônes optionnelles",
    "options": "options",
    "or": "ou",
    "or go to the last slide": "or go to the last slide",
    "orange": "orange",
    "order": "order",
    "organization": "organisation",
    "original author": "auteur d´origine",
    "other names": "autres noms",
    "other-option": "Autre",
    "other-option-input-label": "Veuillez préciser si vous avez sélectionné «Autre».",
    "overlay": "superposition",
    "owners": "propriétaires",
    "paddings": "paddings",
    "page": "page",
    "page-not-found": {
      "contact-admin": "Contactez votre administrateur, si vous pensez que ceci est une erreur.",
      "message": "Assurez-vous que l´adresse est correcte et que l´a page n´a pas changé de location.",
      "title": "Page non trouvée"
    },
    "pages": "pages",
    "pagination-label": "{current} sur {total}",
    "paragraph": "paragraphe",
    "parameters": "parameters",
    "parent icon": "icône principale",
    "parent-icon": "icône principale",
    "password": "mot de passe",
    "password to use for authentication": "Mot de passe pour vous identifier",
    "password-change-success": "Vous avez changez le mot de passe pour `{name}`.\"",
    "path": "chemin",
    "personal settings": "cadre personnel",
    "photos": "photos",
    "pick a location on the map": "Choisissez un emplacement sur la carte",
    "pick it on the map": "Pick it on the map",
    "picture": "image",
    "pictures": "photos",
    "pink": "pink",
    "places": "des endroits",
    "plain": "simple",
    "point": "point",
    "points": "points",
    "polygon": "polygone",
    "polygon marker": "marqueur polygone",
    "polygons": "polygones",
    "position": "position",
    "position-details": "Comment voulez-vous sélectionner l'emplacement?",
    "predefined area": "surface prédéfinie",
    "predefined colors": "couleurs prédéfinies",
    "preferences": "préférences",
    "preparing": "préparation",
    "presentation": "présentation",
    "presentations": "présentations",
    "preview": "Aperçu",
    "preview-import": {
      "alert": "Impossible de convertir `{key}` en `{destination}` car `{error}`",
      "empty-maps": "L'enregistrement doit avoir au moins une carte.",
      "empty-name": "L'enregistrement n'a pas de nom.",
      "invalid-position": "L'enregistrement doit contenir un geojson valide.",
      "invalid-type": "L'enregistrement doit être un objet.",
      "missing-fields": "L'enregistrement doit avoir tous les champs obligatoires `{vars}`"
    },
    "price offer": "price offer",
    "primary": "primaire",
    "primary background color": "primary background color",
    "primary border color": "primary border color",
    "primary text color": "primary text color",
    "privacy Policy": "politique de protection de la vie privée",
    "private key": "clé privée",
    "profile": "profil",
    "profile card": "profile card",
    "profile-current-status-message": "Cet emoji et ce message apparaitront sur votre profil et dans toute l´interface.",
    "profile-fill-in-message": "Your public profile is not finished.",
    "profile-fill-in-message-link": "Click here to update your profile.",
    "profile-main-settings-message": "Cette information apparaitra sur votre profil.",
    "profile-public-avatar-message": "Vous pouvez envoyer votre avatar.",
    "progress": "progrès",
    "promote to admin": "Promouvoir à administrateur",
    "promote user": "promouvoir l´utilisateur",
    "promote-user-question": "Êtes-vous certain de vouloir promouvoir l´utilisateur `{name}`?",
    "promote-user-success-message": "Vous avez promu l´utilisateur `{name}`comme administrateur.",
    "property": "property",
    "property name": "nom de la propriété",
    "proportion": "proportion",
    "propose": "propose",
    "public profile": "profil public",
    "publish": "publier",
    "publish now": "publish now",
    "purple": "purple",
    "question": "question",
    "question-description": "question for the feature description",
    "question-icons": "question for the icons that the contributor can choose",
    "question-message": {
      "duplicated-name": "There is already a question with the same name.",
      "empty-icon-list": "You haven't picked any icons. You need to select at least one icon to make the question relevant.",
      "hidden-description": "The user won't be asked to enter a description.",
      "hidden-email": "The user won't be asked to enter an email.",
      "hidden-icons": "The user won't be asked to pick icons.",
      "hidden-name": "The user won't be asked to enter a name.",
      "hidden-pictures": "The user won't be asked to upload pictures.",
      "hidden-position": "The user won't be asked to set a location.",
      "hidden-sounds": "The user won't be asked to upload sounds.",
      "no-name": "The name is not set. We recommend to use one or two words separated by a dash.",
      "no-question": "The question is not set.",
      "no-type": "The type is not set.",
      "no-user-questions": "There are no questions about the contributor's identity. The answer will be added anonymously."
    },
    "question-name": "question for the feature name",
    "question-pictures": "question for adding pictures",
    "question-position": "question for the feature location",
    "question-sounds": "question for adding sounds",
    "questions": "des questions",
    "reCAPTCHA": "reCAPTCHA",
    "read more...": "lire plus...",
    "recaptcha-message": "Vous pouvez éviter le trafic de spam en générant un compte reCAPTCHA v3 ici:",
    "recent searches": "recherches récentes",
    "record name": "record name",
    "record-status": {
      "draft": "draft",
      "pending": "pending",
      "sending": "sending",
      "sent": "sent"
    },
    "rectangle": "rectangle",
    "red": "red",
    "redirect": "redirect",
    "redirects": "redirects",
    "register": "s´inscrire",
    "register here.": "s´inscrire ici.",
    "register-section": {
      "accept-message": "J´accepte le",
      "accept-message-privacy-policy": "I accept the privacy policy.",
      "accept-message-terms": "I accept the terms of this service.",
      "message-read-privacy-policy": "Click here to read the privacy policy.",
      "message-read-terms": "Click here to read the terms."
    },
    "register-success-message": "Votre compte a été créé ! Vérifiez votre email. Vous trouverez le lien d´activation.",
    "registration": "inscription",
    "reject": "reject",
    "release date": "release date",
    "remoteId": "remoteId",
    "remove member": "éliminer le membre",
    "remove-member-message": "Êtes-vous certain de vouloir éliminer `{name}`de l´équipe?",
    "repeat-daily-until": "daily between `{begin}` and `{end}`",
    "repeat-monthly-until": "monthly between `{begin}` and `{end}`",
    "repeat-weekly-until": "weekly between `{begin}` and `{end}`",
    "repeat-yearly-until": "yearly between `{begin}` and `{end}`",
    "repeats": "Repeats",
    "repetition": {
      "daily": "Daily",
      "monthly": "Monthly",
      "norepeat": "No",
      "weekly": "Weekly",
      "yearly": "Yearly"
    },
    "replace existing emails": "replace existing emails",
    "report a problem": "rapporter un problème",
    "report issues on Gitlab": "rapporter un problème sur Gitlab",
    "request a new one.": "demander un nouveau",
    "required": "Required",
    "resend e-mail": "renvoyer l´e-mail",
    "reset": "réinitialiser",
    "reset password": "réinitialiser le mot de passe",
    "restored_site_alert_message": "Ce site a été restauré à partir d´une session précédente. Vous pouvez finir de l´éditer, ou vous pouvez réinitialiser le formulaire.",
    "restored_site_alert_title": "Votre site a été restauré!",
    "revert": "revert",
    "reviewed-on": "on",
    "revisions": "revisions",
    "role": "rôle",
    "roles-guests": "Guest",
    "roles-leaders": "Leader",
    "roles-members": "Member",
    "roles-owners": "Owner",
    "roles-unknown": "unknown",
    "rotate": "pivoter",
    "running": "en cours d'exécution",
    "salutation-mr": "M.",
    "salutation-mrs": "Mme.",
    "salutation-ms": "Me.",
    "salutation-other": "Aucun",
    "save": "sauver",
    "save as a new copy": "enregistrer en tant que nouvelle copie",
    "save the pin location": "enregistrer l'emplacement de l'épingle",
    "scale": "échelle",
    "schedule": "schedule",
    "scheduled": "programmé",
    "scope": "scope",
    "search": "chercher",
    "search a place by name or enter a text": "search a place by name or enter a text",
    "search an address": "rechercher une adresse",
    "search by name or email": "Chercher par nom ou email",
    "search options": "search options",
    "search panel expands all available icons": "search panel expands all available icons",
    "search something here": "chercher quelque chose ici",
    "search team": "search team",
    "search-with-results": "chercher les résultats pour « {term} »",
    "search-without-results": "pas de résultats pour « {term} »",
    "secondary": "secondaires",
    "secondary background color": "secondary background color",
    "secondary border color": "secondary border color",
    "secondary text color": "secondary text color",
    "secret key": "clé secrète",
    "select": "select",
    "select a date from the calendar": "select a date from the calendar",
    "select a file": "sélectionner un fichier",
    "select a value from the list": "sélectionner une valeur dans la liste",
    "select all": "select all",
    "select file": "sélectionner un fichier",
    "select files": "select files",
    "select icons": "sélectionner des icônes",
    "select one or more values from the list": "sélectionner une ou plusieurs valeurs de la liste",
    "select users that you want to add...": "sélectionner les utilisateurs que vous voulez ajouter …",
    "selected background color": "selected background color",
    "selected color": "couleur sélectionnée",
    "selected picture": "selected picture",
    "selected text color": "selected text color",
    "selection": {
      "allItems": "tous les éléments sont sélectionnés",
      "clear": "clear",
      "items": "{number, plural, =0 {aucune sélection} =1 {un élément sélectionné} other {# éléments sélectionnés}}"
    },
    "send": "envoyer",
    "send newsletter": "send newsletter",
    "send test email": "envoyer un e-mail de test",
    "send to all recipients": "send to all recipients",
    "service available only for registered users": "Service disponible seulement pour les utilisateurs inscrits",
    "set": "appliquer",
    "set a css color": "définir une couleur css",
    "set a float number": "set a float number",
    "set a list of integers separated by comma": "définir une liste de nombres entiers séparés par une virgules",
    "set an integer number": "définir un nombre entier",
    "set as pending": "défini comme en attente",
    "settings": "paramètres",
    "shades": "nuances",
    "shadow": "shadow",
    "shape": "forme",
    "show advanced details": "afficher les détails avancés",
    "show all": "tout montrer",
    "show all features": "montrer tous les éléments",
    "show all icons": "afficher toutes les icônes",
    "show details": "montrer les détails",
    "show download links": "afficher les liens de téléchargement",
    "show end slide": "show end slide",
    "show in fullscreen": "montrer l´écran entier",
    "show intro": "show intro",
    "show less": "montrer moins",
    "show login": "show login",
    "show logo": "show logo",
    "show map": "montrer la carte",
    "show more": "montrer plus",
    "show private contributions": "montrer les contributions privées",
    "show team page": "show team page",
    "show the contributions calendar": "montrer le calendrier des contributions",
    "show the map info box": "show the map info box",
    "show welcome presentation to new users": "Afficher la présentation de bienvenue aux nouveaux utilisateurs",
    "showAsLineAfterZoom": "affichage en ligne par niveau de zoom",
    "side picture": "side picture",
    "sign in": "s´identifier",
    "sign out": "se déconnecter",
    "site": "site",
    "site key": "site clé",
    "sites": "sites",
    "size": "taille",
    "skype": "skype",
    "slide": "slide",
    "slide-number": "slide {slideNumber}",
    "slug": "slug",
    "slug-double-dash": "The slug should not contain two consecutive dashes.",
    "slug-generic-invalid": "You can only use lower case characters, numbers and dashes.",
    "slug-invalid-chars": "The slug contains invalid chars.",
    "slug-no-space": "The slug should not contain spaces.",
    "slug-no-uppercase": "The slug should not contain upper case chars.",
    "slug-no-value": "This article has no slug. The id will be used to identify this article.",
    "slug-taken": "The slug is already used by another article.",
    "smtp": "smtp",
    "sounds": "sons",
    "source": "source",
    "space": "space",
    "space dashboard": "space dashboard",
    "space-needed-message": "Il semble que vous n'ayez actuellement aucune place disponible. Vous devez d'abord créer un espace.",
    "space-no-custom-font-style-message": "There is no customized font style.",
    "spaces": "espaces",
    "spaces domains": "spaces domains",
    "spotlight-not-data-found": "spotlight-not-data-found",
    "start": "start",
    "start date": "date initiale",
    "startTLS": "startTLS",
    "started on": "commencé le",
    "starting start": "Starting `{start}`.",
    "stats": {
      "map-views": "Map views",
      "newsletter-emails": "Emails sent",
      "newsletter-interactions": "Emails interactions",
      "newsletter-open": "Emails opened",
      "newsletter-subscribers": "Subscribers",
      "survey-answer-contributors": "Survey contributors",
      "survey-contributions": "Survey contributions",
      "title": "Stats"
    },
    "status": "statut",
    "status message": "message de statut",
    "store type": "type de magasin",
    "style": "style",
    "styles": "les styles",
    "subcategory": "sous-catégorie",
    "subheading": "subheading",
    "subheading text": "subheading text",
    "subject": "subject",
    "submenu item": "submenu item",
    "submit": "soumettre",
    "submitted": "submitted",
    "submitted-by": "by",
    "submitted-by-anonymous": "by an anonymous user",
    "subscription": {
      "domains": "Domains",
      "expire": "Until",
      "hours": "{value, plural, =0 {none} =1 {one hour} other {# hours}}",
      "name": "Name",
      "remaining-billable-support": "Billable support",
      "remaining-free-support": "Remaining support",
      "title": "Subscription"
    },
    "success": "succès",
    "suggest a photo": "proposer une photo",
    "suggest photo": "suggest photo",
    "suggestions": "propositions",
    "surface": "surface",
    "survey": "survey",
    "survey answer": "survey answer",
    "survey answers": "survey answers",
    "survey card list": "liste des cartes de campagne",
    "survey form": "survey form",
    "survey options": "options de campagnes",
    "survey-answer-review-pending": "This contribution needs a review from some one from the team.",
    "survey-answer-review-processing": "This contribution is being processed.",
    "survey-answer-review-published": "This contribution is approved and published.",
    "survey-answer-review-rejected": "This contribution is rejected.",
    "survey-answer-review-unknown": "This contribution can not be reviewed.",
    "survey-answer-review-unpublished": "This contribution is approved and unpublished.",
    "survey-contribute-map-pending-message": "wants to contribute to",
    "survey-contribute-map-published-message": "contributed to",
    "survey-contribute-map-rejected-message": "did not contribute to",
    "survey-feature-description-disabled": "The user will not be prompted for a description.",
    "survey-feature-description-enabled": "The user will be prompted with the question:",
    "survey-feature-description-label": "question pour le champ de description",
    "survey-feature-icons-disabled": "The user will not be prompted to select icons.",
    "survey-feature-icons-enabled": "The user can select at least one icon and will be prompted with the question:",
    "survey-feature-icons-label": "icônes",
    "survey-feature-location-enabled": "The user will be asked:",
    "survey-feature-location-label": "location",
    "survey-feature-name-disabled": "The feature name will be generated on submit and the user will not be prompted for a name.",
    "survey-feature-name-enabled": "The user will be prompted with the question:",
    "survey-feature-name-label": "question pour le champ de nom",
    "survey-feature-name-prefix": "préfixe du nom de fonctionnalité",
    "survey-feature-not-selected": "Before submitting the form, you need to select a feature on the map.",
    "survey-feature-pictures-default": "The user can add photos. They are not mandatory.",
    "survey-feature-pictures-enabled": "The user will be prompted with the question:",
    "survey-feature-pictures-label": "Photos",
    "survey-feature-pictures-mandatory": "The user can add photos. They are required.",
    "survey-location-field-message": "The user will be able to select a site:",
    "survey-locationAddress": "by searching an address",
    "survey-locationExistingFeature": "by using an existing map feature",
    "survey-locationGps": "by using their current location",
    "survey-locationManual": "by selecting a point on a map",
    "survey-no-address-found": "There are no addresses matching your search terms.",
    "survey-optional-icons-label": "Il s'agit de la liste d'icônes que le contributeur peut sélectionner.",
    "survey-optional-icons-question": "Optional icons list:",
    "survey-point-outside-extent": "The selected point is outside the map extent. It might not be interesting for this survey.",
    "survey-registered-users-only-message": "pour contribuer à cette campagne, vous devez vous connecter à votre compte.",
    "survey-registered-users-only-title": "authentification requise",
    "survey-registration-mandatory-label": "Restreindre aux utilisateurs enregistrés",
    "survey-settings-description-label-placeholder": "entrez le libellé de la question du champ de description",
    "survey-settings-feature-name-prefix-placeholder": "entrez un préfixe personnalisé pour les réponses sans le champ de nom",
    "survey-settings-icons-label-placeholder": "saisissez le libellé de la question du champ des icônes",
    "survey-settings-name-label-placeholder": "saisissez le libellé de la question du champ de nom",
    "survey-success-message": "Votre réponse a été enregistrée et attend un examen.",
    "survey-success-title": "Votre réponse a été enregistrée",
    "surveys": "surveys",
    "surveys for": "campagnes pour",
    "tablet": "tablette",
    "tablet options": "tablet options",
    "tagline": "tagline",
    "teal": "teal",
    "team": "équipe",
    "team dashboard": "team dashboard",
    "team maps": "cartes des équipes",
    "team surveys": "les campagnes de l'équipe",
    "team-guest-count": "{count, plural, =0 {pas d´invités} =1 {un invité} other {# invités}}",
    "team-member-count": "{count, plural, =0 {aucun membre} =1 {un membre} other {# membres}}",
    "team-needed-message": "Vous n´avez pas encore d´équipe. Vous devez d´abords créer une équipe.",
    "team-role": {
      "guests": "guest",
      "leaders": "leader",
      "members": "member",
      "owners": "owner",
      "unknown": "unknown"
    },
    "teams": "équipes",
    "templates": "templates",
    "terms of Service": "conditions de service",
    "text": "text",
    "the attribute value is invalid.": "la valeur de l´attribut n´est pas correcte.",
    "the attributes will be removed on save.": "les attributs seront supprimés lors de la sauvegarde.",
    "the email used to send the emails": "l´email utilisé pour envoyer les emails",
    "the new password can't be empty": "l´espace pour le nouveau mot de passe ne peut pas rester vide",
    "the new password must have at least 10 chars": "le nouveau mot de passe doit avoir au moins 10 caractères.",
    "the passwords do not match": "Les mots de passes ne sont pas identiques",
    "there are no changes": "il n'y a pas de changements",
    "there are no icons": "il n´y a pas d´icônes",
    "there are no pictures": "il n´y a pas de photos",
    "there is no date interval set": "There is no date interval set.",
    "there is no description": "il n´y a pas de description",
    "there is nothing to choose": "il n´y a rien à choisir",
    "this base map has no name": "Cette carte de base n´a pas de nom",
    "this calendar does not have a custom record name": "this calendar does not have a custom record name",
    "this map has no tagline": "this map has no tagline",
    "this message has no subject": "this message has no subject",
    "this page has no layout": "cette page n'a pas de schéma",
    "this presentation has no layout": "cette présentation n'a pas de schéma",
    "this presentation has no name": "cette présentation n'a pas de nom",
    "this site has no pictures.": "ce site n´a pas de photo.",
    "this site is not linked to a map.": "ce site n´est pas lié à une carte.",
    "time interval": "time interval",
    "timeout": "timeout",
    "timezone": "Timezone",
    "title": "titre",
    "title and article": "title and article",
    "title color": "couleur du titre",
    "title with buttons": "titre avec boutons",
    "title-import-emails": "How would you like to add emails?",
    "tls": "tls",
    "to": "to",
    "toggle dropdown": "toggle dropdown",
    "top": "top",
    "topmost": "topmost",
    "translate": "traduire",
    "translate to": "traduire à {language}",
    "translation": "traduction",
    "trigger": "trigger",
    "type": "type",
    "type of encryption protocol to use": "type de protocole de cryptage à utiliser",
    "typography": "typography",
    "unknown": "inconnue",
    "unknown error": "erreur inconnue",
    "unpublish": "pas publié",
    "unpublish newsletter": "unpublish newsletter",
    "until end": "Until `{end}`.",
    "unverified": "non vérifié",
    "update": "actualiser",
    "update fields and get a preview": "mettre à jour les champs et obtenir un aperçu",
    "update password": "actualiser le mot de passe",
    "updates": "actualisations",
    "upload": "upload",
    "upload a file": "upload a file",
    "use my current location": "utiliser ma position actuelle",
    "use the parent image": "utiliser l'image parent",
    "user": "user",
    "user is admin": "**{name}** est un administrateur.",
    "user is regular user": "**{name}** est un utilisateur régulier.",
    "user name": "nom d´utilisateur",
    "user name to use for authentication": "nom d'utilisateur à utiliser pour l'authentification",
    "username or public name": "nom d´utilisateur ou nom public",
    "users": "utilisateur",
    "value": "valeur",
    "vector tiles url": "vector tiles url",
    "verified": "vérifié",
    "verify": "vérifier",
    "vertical gutters": "caniveaux verticaux",
    "view": "view",
    "view articles": "view articles",
    "view features": "voir les éléments",
    "view icons": "voir les icônes",
    "view issues": "voir les problèmes",
    "view log": "view log",
    "view more": "voir plus",
    "view site": "voir le site",
    "view sites": "voir les sites",
    "view the site in browse mode": "voir le site en mode navigation",
    "visibility": "visibilité",
    "visibility on mobile": "visibility on mobile",
    "visibility options": "visibility options",
    "visibility-pending": "en attente",
    "visibility-private": "privée",
    "visibility-public": "publique",
    "visible": "visible",
    "warning": "avertissement",
    "website url": "website url",
    "weight": "poids",
    "welcome": "Bienvenue",
    "welcome email": "welcome email",
    "welcome presentation": "présentation de bienvenue",
    "welcome to": "bienvenu à",
    "what is map name?": "Quel est le nom de la carte?",
    "what is nearby?": "qu'y a-t-il à proximité?",
    "what is team name?": "Quel est le nom de l´équipe?",
    "what is the article slug?": "quel est le slug de l'article?",
    "what is the calendar name?": "what is the calendar name?",
    "what is the data binding name?": "what is the data binding name?",
    "what is the event name?": "what is the {name} name?",
    "what is the feature name?": "Quel est le nom de l´élément?",
    "what is the icon name?": "Quel est le nom de l´icône?",
    "what is the map tagline?": "what is the map tagline?",
    "what is the message subject?": "what is the message subject?",
    "what is the newsletter description?": "what is the newsletter description?",
    "what is the newsletter name?": "what is the newsletter name?",
    "what is the page name?": "quel est le nom de la page?",
    "what is the page slug?": "quel est le slug de page?",
    "what is the presentation name?": "quel est le nom de la présentation?",
    "what is the presentation slug?": "quelle est la slug de présentation?",
    "what is the set name?": "what is the set name?",
    "what is the space name?": "what is the space name?",
    "what is the survey name?": "quel est le nom de la campagne?",
    "what's your username?": "Quel est votre nom d´utilisateur?",
    "when": "when",
    "who owns the picture?": "who owns the picture?",
    "width": "largeur",
    "with icon": "avec l`icône `{name}`",
    "with issues": "with issues",
    "with map": "avec la carte `{name}`",
    "write a few words about the picture": "décrire brièvement la photo",
    "year": "année",
    "yellow": "yellow",
    "yes": "oui",
    "yes and remove sites": "oui et éliminer les sites",
    "you are already loged in.": "Vous êtes déjà enregistré.",
    "you don't have an account?": "Avez-vous un compte?",
    "your API tokens": "vos API tokens",
    "your account": "votre compte",
    "your account was activated.": "Votre compte a été activé.",
    "your api key": "votre api key",
    "your api token": "votre api token",
    "your base maps": "vos cartes de bases",
    "your icon sets": "vos jeux d´icônes",
    "your maps": "vos cartes",
    "your profile": "votre profil",
    "your sites": "vos sites",
    "your teams": "vos équipes"
  }], ["ro-ro", {
    "-view": {
      "360 degree image": "360 degree image",
      "Filter icons by name": "Filter icons by name",
      "Please sign in here.": "Please sign in here.",
      "about you": "About you",
      "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
      "close": "close",
      "contact-form-success": "Your message has been successfully sent. We will reply soon.",
      "currency": {
        "default": "{value} {currency}",
        "eur": "{value}€",
        "gbp": "£{value}",
        "usd": "${value}"
      },
      "date": {
        "info.createdOn": "added on",
        "info.lastChangeOn": "last change on",
        "releaseDate": "released on"
      },
      "day of week": "day of week",
      "days": {
        "long": {
          "friday": "Friday",
          "monday": "Monday",
          "saturday": "Saturday",
          "sunday": "Sunday",
          "thursday": "Thursday",
          "tuesday": "Tuesday",
          "wednesday": "Wednesday",
          "weekday": "Weekday",
          "weekend": "Weekend"
        },
        "short": {
          "friday": "Fr",
          "monday": "Mo",
          "saturday": "Sa",
          "sunday": "Su",
          "thursday": "Th",
          "tuesday": "Tu",
          "wednesday": "We",
          "weekday": "Weekday",
          "weekend": "Weekend"
        }
      },
      "edit page": "edit page",
      "event-repetition": {
        "daily": "daily",
        "monthly": "monthly",
        "norepeat": "one off",
        "weekly": "weekly",
        "yearly": "yearly"
      },
      "event_date": {
        "interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
        "interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
        "interval_same_day": "**{beginTime}** to **{endTime}**",
        "repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
        "repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginM}** **{beginD}**",
        "repeats_norepeat": "**{beginD} {beginM} {beginY}**",
        "repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
        "repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**"
      },
      "location": "location",
      "make private": "make private",
      "make public": "make public",
      "message-generic-list-empty": "Empty list",
      "message-map-marker-move": "Drag and zoom the map to set the pin location and then press the `save the pin location` button",
      "message-map-marker-point-move": "Drag and zoom the map to set the pin location and then press the `save` button",
      "message-missing-record": "No data source",
      "metrics": {
        "contributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "publicFeatures": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "published sites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "publishedSites": "{value, plural, =0 {no published sites} =1 {one published site} other {# published sites}}",
        "total sites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalContributors": "{value, plural, =0 {no contributors} =1 {one contributor} other {# contributors}}",
        "totalFeatures": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}",
        "totalSites": "{value, plural, =0 {no sites} =1 {one site} other {# sites}}"
      },
      "other-option": "Other",
      "other-option-input-label": "Please specify if you selected “Other”.",
      "page-not-found": {
        "contact-admin": "Please contact your administrator if you think this is a mistake.",
        "message": "Make sure the address is correct and the page hasn't moved.",
        "title": "Page Not Found"
      },
      "rotate": "rotate",
      "save the pin location": "save the pin location",
      "search": "search",
      "search for a place": "search for a place",
      "space dashboard": "space dashboard",
      "submit": "submit",
      "survey-registered-users-only-message": "In order to contribute to this survey you need to sign in to your account.",
      "survey-registered-users-only-title": "Authentication required",
      "team dashboard": "team dashboard",
      "visibility-pending": "pending",
      "visibility-private": "private",
      "visibility-public": "public"
    },
    "360 degree image": "imagine 360 de grade",
    "API Tokens": "Token-uri API",
    "Add more icons": "Add more icons",
    "Add more icons:": "Add more icons:",
    "All records will be added as new.": "All records will be added as new.",
    "An unknown error occurred.": "S-a produs o eroare necunoscută.",
    "App Store": "App Store",
    "Are you sure you want to delete this account?": "Ești sigur că vrei să ștergi acest cont?",
    "CAPTCHA": "CAPTCHA",
    "CRS": "CRS",
    "CRS using PROJ format": "CRS folosind formatul PROJ",
    "CSV": "CSV",
    "Choose file": "Alege fișier",
    "Create a team": "Creați o echipă",
    "Create account": "Creează cont",
    "Current status": "Status curent",
    "DTLS v1.0": "DTLS v1.0",
    "Dashboard": "Dashboard",
    "Download CSV file": "Download CSV file",
    "Download on the": "Descarcă de pe",
    "E-Mail": "E-Mail",
    "E-Mail service": "Serviciu de e-mail",
    "Explore the map": "Explorează harta",
    "FAQ": "Întrebări frecvente",
    "FROM SPACE VIEW": "FROM SPACE VIEW",
    "Filter icons by name": "Filtrează simboluri după nume",
    "Form": "Form",
    "GET IT ON": "Ia de pe",
    "GeoJson": "GeoJson",
    "GeoPackage": "GeoPackage",
    "Give a few words about the issue": "Descrie problema în câteva cuvinte",
    "Go to the last slide": "Accesați ultimul diapozitiv",
    "Google Play": "Google Play",
    "Import to": "nou",
    "Integrations": "Integrations",
    "Location": "Localizare",
    "Location Services": "Servicii de localizare",
    "Location error": "Eroare de localizare",
    "Main settings": "Setări principale",
    "Masking precision to use on maps for which masking is enabled": "Precizie de mascare pe hărțile pentru care mascarea este activată",
    "Nominatim url": "Adresa web a serviciului Nominatim",
    "Original author": "Autor original",
    "Please sign in here.": "Vă rugăm să vă conectați aici",
    "Public avatar": "Avatar public",
    "Remove user": "Șterge utilizator",
    "Replace emails": "Replace emails",
    "Review": "Review",
    "Row": "Rând",
    "SMTP": "SMTP",
    "SMTP host": "Server SMTP",
    "SMTP port": "Port SMTP",
    "Server error": "Eroare de server",
    "Take screenshot": "Fă o captură de ecran",
    "Tell us about yourself": "Spune-ne câteva lucruri despre tine",
    "The default icon sets will be used.": "Seturile de simboluri implicite vor fi folosite.",
    "The login article can not be edited.": "Nu se poate edita articolul de autentificare.",
    "The mercator CRS will be used.": "The mercator CRS will be used.",
    "The row is not visible on this size.": "The row is not visible on this size.",
    "The site has no assigned map": "Locul nu aparține vreunei hărți",
    "There are no attributions": "There are no attributions",
    "There are no values": "There are no values",
    "There was an unexpected error.": "S-a produs o eroare neașteptată",
    "Update by": "Actualizați bazat pe",
    "What is language locale?": "What is language locale?",
    "What is the language name?": "Care este denumirea limbii?",
    "What is the local category?": "Care este categoria locală?",
    "What is the local name?": "Care este numele local?",
    "What is the local subcategory?": "Care este subcategoria locală?",
    "What is your job title?": "Care este numele locului tău de muncă?",
    "What's next?": "Ce urmează?",
    "What's user name?": "Care este numele utilizatorului?",
    "What's your linkedin name?": "Care este numele tău pe linkedin?",
    "What's your public name?": "Care este numele tău public?",
    "What's your skype name?": "Care este numele tău pe skype?",
    "What's your status?": "Care este statusul tău?",
    "What's your twitter handler?": "Care este numele tău pe twitter?",
    "What's your website url?": "Care este adresa websitului tău?",
    "Where are you located?": "Unde ești localizat?",
    "Where do you want to be contacted?": "Unde vrei să fii contactat?",
    "Where?": "Unde?",
    "You have successfully changed your password.": "Ți-ai schimbat parola cu succes.",
    "You have successfully removed your user.": "Ți-ai șters utilizatorul cu succes.",
    "about": "despre",
    "about you": "About you",
    "about-you-info": "None of your personal information will be published. We will only use this information to keep in touch and to monitor who is contributing ideas to our project.",
    "accent color": "accent color",
    "access": "acces",
    "account": "cont",
    "account activation": "activare cont",
    "account info": "Informații despre cont",
    "account-info-message": "Aici sunt câteva informații despre cont",
    "accuracy": "precizie la orizontală",
    "activation-expire-error": "Codul de activare a expirat. Te rugăm să încerci din nou.",
    "add": "adaugă",
    "add a site": "adaugă un loc",
    "add another site": "adaugă un loc nou",
    "add features as pending": "adăugați caracteristici ca în aşteptare",
    "add icon": "adaugă simbol",
    "add one or more maps": "Adaugă una sau mai multe hărți",
    "add to team": "adaugă în echipă",
    "add-article-paragraph": "Faceți clic aici dacă doriți să adăugați unul",
    "add-article-title": "Faceți clic aici pentru a adăuga un titlu",
    "added on": "adăugat pe",
    "address": "adresă",
    "admin-delete-account-message": "Nu îți poți șterge contul pentru că ești administrator. Roagă un alt administrator să îți șteargă contul sau să îți ia dreptul de administrare.",
    "admin-missing-login-article": "Nu există niciun articol pentru pagina de autentificare.",
    "administration": "administrare",
    "advanced options": "opțiuni avansate",
    "align": "aliniere",
    "align items": "alinierea elementelor",
    "alignment": "alignment",
    "all": "tot",
    "all base maps": "toate fundalurile de hartă",
    "all icon sets": "toate seturile de simboluri",
    "all maps": "toate hărțile",
    "all sites": "toate locurile",
    "all teams": "toate echipele",
    "all users": "toți utilizatorii",
    "allow custom data bindings": "allow custom data bindings",
    "allow domain change": "allow domain change",
    "allow manage without teams": "permite administrarea fără echipe",
    "allow many instances": "permite instanțe multiple",
    "allow proposing sites": "permite propunerea de site-uri",
    "allow-manage-without-teams-message": "Când această opțiune este activată, orice utilizator autentificat poate crea `echipe`, `hărți`, `caracteristici`, `campanii` și `simboluri`.",
    "allow-proposing-sites-message": "Când această opțiune este activată, opțiunea `Propune un site` va fi disponibilă pentru utilizatori.",
    "allowed email domains": "domenii de e-mail permise",
    "allowed-email-domains-message": "Stabilește o listă de domenii care pot fi folosite pe acest domeniu. Aceste domenii trebuie separate prin `,` de exemplu `mydomain1.com,mydomain2.org`. Dacă lista e goală, toate mesajele vor fi acceptate.",
    "allowed-spaces-domains-message": "Set a list of the domains that are allowed to be used by the spaces. The domains must be separated by `,` eg. `mydomain1.com,mydomain2.org`.",
    "already have an account?": "ai deja un cont?",
    "altitude": "altitudine",
    "altitude accuracy": "precizia altitudinii",
    "analytics": "analytics",
    "analyze": "analyze",
    "analyzing file": "se analizează fișierul",
    "and": "și",
    "anonymous": "anonim",
    "answer": "answer",
    "answer-message": {
      "no-contributor-questions": "The survey does not have contributor questions.",
      "no-generic-questions": "The survey does not include any additional general questions."
    },
    "answers": "answers",
    "appearance": "aspect",
    "approve": "approve",
    "area": "zonă",
    "article": "articol",
    "article content": "article content",
    "article date": "article date",
    "article title": "article title",
    "article-placeholders": {
      "add-calendar": "This is the calendar related to our project.",
      "add-event": "Presenting our exciting new {name}, carefully curated to bring you a unique and memorable experience. Get ready to be part of something extraordinary!",
      "add-icon": "Aceasta este simbolul creat de echipa noastră.",
      "add-icon-set": "Acesta este noul nostru set de simboluri conceput pentru a fi utilizat în noile noastre proiecte.",
      "add-map": "Aceasta este harta noastră, legată de proiectul nostru.",
      "add-team": "Aceasta este echipa noastră, dedicată cartografierii tuturor proiectelor noastre."
    },
    "articles": "articole",
    "as text value": "as text",
    "attribute": "atribut",
    "attributes": "atribute",
    "attributes-options-label": "Opțiuni (valori separate prin virgulă)",
    "attributions": "citări",
    "authentication type to use": "tipul de autentificare folosit",
    "author": "autor",
    "author-label": "de {name}",
    "auto": "auto",
    "back": "înapoi",
    "back to": "înapoi la {destination}",
    "back to our website": "back to our website",
    "back to search results": "înapoi la rezultatele căutării",
    "background color": "culoare de fundal",
    "background image": "imagine de fundal",
    "background map": "background map",
    "backgroundColor": "culoarea de fundal",
    "banner": "banner",
    "base map": "fundal de hartă",
    "base maps": "fundaluri de hartă",
    "baseline": "baseline",
    "be the first who adds one.": "fii primul care adaugă una.",
    "begins at": "begins at",
    "begins on": "begins on",
    "between": "între",
    "between start and end": "Between `{start}` and `{end}`.",
    "bio": "bio",
    "blocks": "blocuri",
    "blue": "blue",
    "boolean-": "unknown",
    "boolean-false": "no",
    "boolean-true": "yes",
    "boolean-undefined": "unknown",
    "border": "border",
    "borderColor": "culoarea bordurii",
    "borderWidth": "grosimea bordurii",
    "bottom": "jos",
    "breadcrumb": "breadcrumb",
    "browse": "explorează",
    "bs-property-bg-position-x": "x",
    "bs-property-bg-position-y": "y",
    "bs-property-bg-repeat": "repetarea fundalului",
    "bs-property-bg-size": "dimensiunea fundalului",
    "bs-property-btn": "stil",
    "bs-property-display": "afişaj",
    "bs-property-fw": "grosimea fontului",
    "bs-property-size-btn": "mărime",
    "bs-property-text": "aliniere",
    "button": "button",
    "button label": "eticheta butonului",
    "button link": "link-ul butonului",
    "button type": "tipul butonului",
    "buttons": "butoane",
    "by": "de către",
    "by address": "după adresă",
    "calendar": "calendar",
    "calendar-entry-at": "at",
    "calendar-entry-begins-on": "Begins on",
    "calendar-entry-ends-on": "on",
    "calendar-entry-repeats-until": "until",
    "calendar-entry-to": "until",
    "calendar-entry.end-date-before-start": "The event ends before it starts.",
    "calendar-entry.interval-end-date-before-end": "The repetition end date is before the end of the event.",
    "calendars": "calendars",
    "campaign": "campanie",
    "campaign card list": "campaign card list",
    "campaign form": "campaign form",
    "campaign-location-type-address": "by address",
    "campaign-location-type-feature": "select an existing feature",
    "campaign-location-type-gps": "use my current location",
    "campaign-location-type-manual": "choose manually on the map",
    "campaigns": "campanii",
    "can create events": "can create events",
    "can send newsletters": "can send newsletters",
    "can't upload": "nu se poate trimite",
    "cancel": "renunță",
    "cancel publishing": "cancel publishing",
    "cancelled": "anulat",
    "capture using GPS": "Înregistrează din senzorul GPS",
    "card list": "card list",
    "card options": "card options",
    "card view": "card view",
    "card-type": "card type",
    "categories": "categories",
    "category": "categorie",
    "change": "schimbă",
    "change password": "schimbă parola",
    "choose manually": "selectează manual",
    "choose manually on the map": "choose manually on the map",
    "click here to create a schedule": "click here to create a schedule",
    "click to select an image": "click pentru a alege o imagine",
    "click to select from list": "click pentru a alege din listă",
    "close": "închide",
    "color": "culoare",
    "color palette": "color palette",
    "colors": "culori",
    "component": "componentă",
    "component count": "component count",
    "component id": "component id",
    "component-count": "component-count",
    "confirm new password": "confirmă noua parolă",
    "confirm your password": "confirmă parola",
    "connection info": "connection info",
    "connectionInfo": "connection info",
    "contact form": "contact form",
    "contact-form-success": "Your message has been successfully sent. We will reply soon.",
    "container": "container",
    "container options": "opțiuni recipient",
    "content": "conţinut",
    "contributor": "contributor",
    "contributor questions": "contributor questions",
    "contributors": "contribuitori",
    "convert": "convert",
    "convert to global component": "convert to global component",
    "copy and paste": "copy and paste",
    "cover": "copertă",
    "cover photo": "imaginea de copertă",
    "cover photos": "imagini de copertă",
    "cramMd5": "cramMd5",
    "create": "creează",
    "create a new API token": "creează un nou token API",
    "create a new account": "creează un cont nou",
    "create line": "creează linie",
    "create point": "creează punct",
    "create polygon": "creează poligon",
    "create-first-map": "Nu există hărți disponibile.",
    "create-first-map-message": "Se pare că această echipă încă nu are hărți. Creați o primă hartă pentru a începe să adăugați și să vizualizați datele.",
    "create-map": "Creați o hartă",
    "created by": "create de `{name}`",
    "created by you": "create de `tine`",
    "created on": "creat la",
    "currency": {
      "default": "{value} {currency}",
      "eur": "{value}€",
      "gbp": "£{value}",
      "usd": "${value}"
    },
    "currency selector": "currency selector",
    "custom geometry": "geometrie personalizată",
    "custom translations": "traduceri personalizate",
    "cyan": "cyan",
    "dark": "dark",
    "dashboard": "panou",
    "dashboards": "dashboards",
    "data binding": "data binding",
    "data bindings": "data bindings",
    "data source": "data source",
    "date": {
      "info.createdOn": "added on",
      "info.lastChangeOn": "last change on",
      "releaseDate": "released on"
    },
    "date interval": "date interval",
    "date-between": "between `{begin}` and `{end}`",
    "date-on": "on {date}",
    "date-on-detail": "on `{date}`",
    "day": "zi",
    "day-Friday": "Friday",
    "day-Monday": "Monday",
    "day-Saturday": "Saturday",
    "day-Sunday": "Sunday",
    "day-Thursday": "Thursday",
    "day-Tuesday": "Tuesday",
    "day-Wednesday": "Wednesday",
    "day-short": {
      "Friday": "Fri",
      "Monday": "Mon",
      "Saturday": "Sat",
      "Sunday": "Sun",
      "Thursday": "Thu",
      "Tuesday": "Tue",
      "Wednesday": "Wed"
    },
    "days": {
      "letter": {
        "friday": "V",
        "monday": "L",
        "sunday": "S",
        "wednesday": "M"
      },
      "long": {
        "friday": "Friday",
        "monday": "Monday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "thursday": "Thursday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "weekday": "Weekday",
        "weekend": "Weekend"
      },
      "short": {
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "Su",
        "thursday": "Th",
        "tuesday": "Tu",
        "wednesday": "We",
        "weekday": "Weekday",
        "weekend": "Weekend"
      }
    },
    "decimals-label": "{value, plural, =0 {0 zecimale} =1 {o zecimală} other {# zecimale}}",
    "default": "implicit",
    "default icons": "pictograme implicite",
    "default models": "default models",
    "default order": "ordine implicită",
    "default-base-maps-used-message": "Se vor folosi fundalurile de hartă implicite.",
    "delete": "șterge",
    "delete account": "{size, plural, =0 {invalid value} =1 {șterge cont} other {șterge # conturi}}",
    "delete all articles": "Delete all articles",
    "delete all campaign-answers": "delete all survey answers",
    "delete all events": "Delete all events",
    "delete all features": "Delete all features",
    "delete all icons": "delete all icons",
    "delete all pages": "Delete all pages",
    "delete article": "șterge articolul",
    "delete base map": "{size, plural, =0 {invalid value} =1 {șterge fundal de hartă} other {șterge # fundaluri de hartă}}",
    "delete base-map": "delete base-map",
    "delete basemap": "șterge fundal de hartă",
    "delete calendar": "delete calendar",
    "delete campaign": "delete campaign",
    "delete campaign-answer": "delete survey answer",
    "delete data-binding": "delete data-binding",
    "delete event": "delete event",
    "delete feature": "{size, plural, =0 {invalid value} =1 {șterge element} other {șterge # elemente}}",
    "delete icon": "{size, plural, =0 {invalid value} =1 {șterge simbol} other {șterge # simboluri}}",
    "delete icon-set": "{size, plural, =0 {invalid value} =1 {șterge set de simboluri} other {șterge # seturi de simboluri}}",
    "delete language": "{size, plural, =0 {invalid value} =1 {șterge limba} other {șterge # limbi}}",
    "delete map": "{size, plural, =0 {invalid value} =1 {șterge harta} other {șterge # hărti}}",
    "delete non synced records": "delete non synced records",
    "delete page": "șterge pagina",
    "delete presentation": "ștergeți prezentarea",
    "delete site": "{size, plural, =0 {invalid value} =1 {șterge loc} other {șterge # locuri}}",
    "delete space": "șterge spațiu",
    "delete survey": "{size, plural, =0 {invalid value} =1 {șterge campanie} other {șterge # campanii}}",
    "delete team": "{size, plural, =0 {invalid value} =1 {șterge echipa} other {șterge # echipe}}",
    "delete token": "{size, plural, =0 {invalid value} =1 {delete token} other {delete # tokens}}",
    "delete translation": "{size, plural, =0 {invalid value} =1 {șterge traducerea} other {șterge # traduceri}}",
    "delete your account": "șterge-ți contul",
    "delete-all-confirmation-message-article": "Are you sure you want to delete all articles?",
    "delete-all-confirmation-message-campaign-answer": "Are you sure you want to delete all answers?",
    "delete-all-confirmation-message-event": "Are you sure you want to delete all events?",
    "delete-all-confirmation-message-feature": "Are you sure you want to delete all features?",
    "delete-all-confirmation-message-icon": "Are you sure you want to delete all icons?",
    "delete-all-confirmation-message-page": "Are you sure you want to delete all pages?",
    "delete-confirmation-message": "{size, plural, =0 {invalid value} =1 {Ești sigur că vrei să ștergi `{name}`?} other {Ești sigur că vrei să ștergi `#` elemente?}}",
    "delete-confirmation-site-success": "Locul `{name}` a fost șters cu success",
    "description": "descriere",
    "description template": "description template",
    "deselect icon": "deselectați simbolul",
    "desktop": "desktop",
    "desktop options": "opțiuni desktop",
    "destination": "destination",
    "details": "details",
    "didn't receive a confirmation email?": "nu ai primit un e-mail de confirmare?",
    "dimensions": "dimensiuni",
    "disabled": "dezactivat",
    "display": "display",
    "display heading": "titlul de afișare",
    "display mode": "display mode",
    "display name": "nume afișat",
    "do not update": "nu actualizați",
    "domain": "domain",
    "domain name": "nume de domeniu",
    "domain-name-invalid-chars": "The domain name must contain only letters and number from the english alphabet.",
    "domain-name-update-message": ".{from} will be replaced with .{to}",
    "domain-status": {
      "invalid-domain": "Looks like your domain name is invalid. Try renaming it and give the setup another go.",
      "needs-admin-setup": "Your domain name is reserved and needs one of our admins to finish the setup. Hang tight until you get a confirmation message.",
      "pending": "Your domain will be ready soon; the setup just hasn’t started yet.",
      "ready": "Your space is ready at the reserved domain.",
      "setting-up": "Your space is being set up at the reserved domain."
    },
    "done": "terminat",
    "downgrade to regular user": "retrogradează la utilizator normal",
    "downgrade user": "retrogradează utilizator",
    "downgrade-user-question": "Ești sigur că vrei să retrogradezi `{name}`?",
    "downgrade-user-success-message": "Ai retrogradat cu succes `{name}` la utilizator normal.",
    "download": "descarcă",
    "download CSV": "descarcă fișier CSV",
    "download GeoJson": "descarcă fișier GeoJSON",
    "downscale images for faster upload": "redimensionează pentru încărcare mai rapidă",
    "e-mail": "e-mail",
    "edit": "editează",
    "edit article": "editează articolul",
    "edit base map": "editează fundalul de hartă",
    "edit calendar": "edit calendar",
    "edit content": "editează conținutul",
    "edit data binding": "edit data binding",
    "edit event": "edit event",
    "edit feature": "edit feature",
    "edit geometry": "editează geometrie",
    "edit icon": "edit icon",
    "edit icon set": "editează setul de simboluri",
    "edit layout": "editează aspectul",
    "edit map": "editează harta",
    "edit newsletter": "edit newsletter",
    "edit notification": "editează notificarea",
    "edit page": "editează pagina",
    "edit presentation": "editează prezentarea",
    "edit site": "editează locul",
    "edit space": "editează spațiul",
    "edit survey": "editează campania",
    "edit team": "editează echipa",
    "edit the map": "editează harta",
    "edit to add one.": "editează pentru a adăuga una.",
    "edit user": "editează utilizatorul",
    "edit-article-label": "editează articolul",
    "edit-label": "editează",
    "edit-model": {
      "created-on": "creat la {time}",
      "last-change": "de {name} {time}",
      "title": "Editează {name}"
    },
    "edit-model.created-on": "created on",
    "email": "e-mail",
    "email a new issue": "raportează o problemă prin e-mail",
    "embedded map": "hartă încorporată",
    "empty": "gol",
    "enable all notifications": "activează toate notificările",
    "enable links": "enable links",
    "enable the show more button": "enable the show more button",
    "enabled": "activat",
    "end": "capăt",
    "end date": "data finalizării",
    "ended on": "finalizat la",
    "ends at": "ends at",
    "ends on": "ends on",
    "enter again your new password": "reintrodu parola nouă",
    "enter password": "completează parola",
    "enter search keywords": "introduceți cuvinte cheie de căutare",
    "enter the token name": "completează numele pentru token",
    "enter your e-mail": "completează adresa ta de e-mail",
    "enter your new password": "introdu parola nouă",
    "entry": "entry",
    "error": "eroare",
    "errors": "erori",
    "event": "event",
    "event dates": "event dates",
    "event location": "event location",
    "event schedule": "event schedule",
    "event-at": "at",
    "event-repetition": {
      "daily": "daily",
      "monthly": "monthly",
      "norepeat": "one off",
      "weekly": "weekly",
      "yearly": "yearly"
    },
    "event_date.interval_days_daily": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_monthly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_days_weekly": "**{beginTime}** to **{endDWeek}**, **{endTime}**",
    "event_date.interval_days_yearly": "**{beginTime}** to **{endM} {endD}**, **{endTime}**",
    "event_date.interval_same_day": "**{beginTime}** to **{endTime}**",
    "event_date.repeats_daily": "daily, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**",
    "event_date.repeats_monthly": "monthly, from **{beginM} {beginY}** to **{intervalEndM} {intervalEndY}**, on **{beginD}**",
    "event_date.repeats_norepeat": "**{beginD} {beginM} {beginY}**",
    "event_date.repeats_weekly": "weekly, from **{beginD} {beginM} {beginY}** to **{intervalEndD} {intervalEndM} {intervalEndY}**, on **{beginDWeek}**",
    "event_date.repeats_yearly": "yearly, from **{beginY}** to **{intervalEndY}**, on **{beginM} {beginD}**",
    "everything is in order": "totul este în regulă",
    "existing": "existenți",
    "expire date": "data expirării",
    "expires on": "expiră la",
    "export emails with stats": "export emails with stats",
    "external link": "legătură externă",
    "extra icons": "simboluri suplimentare",
    "failed_restored_site_alert_message": "Sesiunea ta precedenta nu a putut fi restaurată pentru că datele erau corupte.",
    "feature": "element",
    "feature attributes": "feature attributes",
    "feature campaign contribution button": "feature campaign contribution button",
    "feature clustering": "gruparea caracteristicilor",
    "feature field": "câmpul din înregistrare",
    "feature issue button": "feature issue button",
    "feature name prefix": "prefixul numelui caracteristicii",
    "feature-on-map": "on",
    "feature-pending-count-message": "{count, plural, =0 {Nu sunt locuri în aşteptare} =1 {Există un loc în aşteptare} other {Există # locuri în aşteptare}}",
    "feature-pending-message": "Acest loc este în curs de examinare",
    "feature-success-message": "Locul tău a fost salvat și asteaptă să fie verificat.",
    "feature-success-title": "Locul tău a fost salvat",
    "feedback-bio": "Spune-ne ceva despre tine în mai puțin de 250 de caractere",
    "feedback-full-name": "Introdu numele tău complet, ca lumea să te poată recunoaște",
    "feedback-linkedin": "Numele profilulului tău de LinkedIn din linkedin.com/in/numeprofil",
    "feedback-organization": "Pe cine reprezinți sau pentru cine lucrezi",
    "field matching": "potrivire câmpuri",
    "fields": "fields",
    "fields matching": "potrivirea câmpurilor",
    "file": "fișier",
    "file field": "câmpul din fișier",
    "filter": "filtru",
    "filter with more than one icon": "filter with more than one icon",
    "filters": "filters",
    "fix": "repară",
    "font": "font",
    "font styles": "font styles",
    "font-awesome-message": "Poți alege orice simbol gratuit din colecția FontAwesome.",
    "font-h1": "Heading 1",
    "font-h2": "Heading 2",
    "font-h3": "Heading 3",
    "font-h4": "Heading 4",
    "font-h5": "Heading 5",
    "font-h6": "Heading 6",
    "font-paragraph": "Paragraph",
    "forgot your password?": "ai uitat parola?",
    "fragment": "fragment",
    "from": "from",
    "full name": "Nume complet",
    "gallery": "galerie",
    "general": "general",
    "general questions": "general questions",
    "generating preview": "se generează previzualizarea",
    "geo-json-allowAddress": "allow searching for an address",
    "geo-json-allowExistingFeature": "allow selecting a feature from the map",
    "geo-json-allowGps": "allow gps",
    "geo-json-allowManual": "allow setting a point on the map",
    "geo-location-permission-denied": "Aplicația nu are dreptul de a folosi localizarea ta.",
    "geo-location-permission-timeout": "Timpul permis pentru a te localiza a expirat înainte ca informația să fie obținută.",
    "geo-location-permission-unavailable": "Localizarea ta a eșuat pentru că cel puțin o sursă de poziționare a returnat o eroare.",
    "geo-location-unknown-error": "Localizarea ta nu este disponibilă datorită unei erori neașteptate.",
    "geoJson editor": "editor geoJson",
    "geojson-feature-file-message": "Selectează un fișier .json, .geojson sau .gpx",
    "geometry": "geometrie",
    "geometry options": "geometry options",
    "get gpx file": "get gpx file",
    "global": "global",
    "global map page": "global map page",
    "go to homepage": "înapoi la pagina principală",
    "go to the main page": "navighează la pagina principală",
    "green": "green",
    "group": "grup",
    "group name": "numele grupului",
    "groups": "groups",
    "grow": "grow",
    "guests": "musafiri",
    "gutter": "gutter",
    "hamburger menu on": "hamburger menu on",
    "has custom style": "are stil personalizat",
    "heading": "heading",
    "heading picture": "imagine heading",
    "heading text": "text heading",
    "height": "height",
    "help": {
      "map": {
        "area": "This is the geographic area your map will cover."
      },
      "survey": {
        "icons": "Aceste simboluri vor fi adăugate automat la fiecare răspuns al campaniei, colaboratorul nu le va putea selecta."
      }
    },
    "help message": "mesaj de ajutor",
    "hidden-contribution-calendar-message": "Acest calendar cu contribuții este privat. Îl poți publica editând acest profil.",
    "hide advanced details": "ascunde detaliile avansate",
    "hide calendar": "hide calendar",
    "hide details": "ascunde detalii",
    "hide on main map": "ascunde pe harta principală",
    "hide options": "ascunde opțiuni",
    "homepage map extent": "Cadrul hărții pe pagina principală",
    "horizontal gutters": "rigole orizontale",
    "horizontal line": "horizontal line",
    "hour": "hour",
    "i forgot my password": "mi-am uitat parola",
    "icon": "simbol",
    "icon grid with categories": "icon grid with categories",
    "icon list": "icon list",
    "icon name": "denumire simbol",
    "icon set": "set de simboluri",
    "icon sets": "seturi de simboluri",
    "icon-inherited-style-message": "Acest simbol folosește stilul setului de simboluri.",
    "icon-label": "simbol",
    "icon-missing-attributes-message": "Acest simbol nu are attribute.",
    "icon-not-matched-message": "Nu a fost găsit niciun simbol",
    "icon-set": "set de simboluri",
    "icons": "simboluri",
    "icons per line": "icons per line",
    "ignored": "ignored",
    "import": "importă",
    "import a new file": "importă un fișier nou",
    "import emails": "import emails",
    "import file to": "import fişier în",
    "imported": "importate",
    "indigo": "indigo",
    "inherited attribute": "atribut moștenit",
    "inside area": "din `{name}`",
    "integrations": "integrări",
    "invalid vars": "variabile invalide",
    "invalid-file-type-message": "Ai selectat un fișier nesuportat.",
    "invalid-gpx-missing-track-or-route": "Fișierul gpx trebuie să conțină cel puțin o rută sau un traseu.",
    "invalid-json-feature-message": "Fișierul conține o geometrie invalidă. Doar Point, LineString, MultiLineString și Polygon sunt permise.",
    "invalid-json-feature-type-message": "Fișierul conține o geometrie de tipul `{type}`. Doar Point, LineString, MultiLineString și Polygon sunt permise.",
    "invalid-json-file-message": "Fișierul conține un Json invalid",
    "is activated": "este activat",
    "is attribute": "este atribut",
    "is default": "este implicit",
    "is index": "este index",
    "is private": "este privat",
    "is public": "este public",
    "is required": "este necesar",
    "is template": "is template",
    "is-multi-project-mode-message": "Când această opțiune este activată, secțiunea `Explorează` va fi disponibilă utilizatorilor obișnuiți.",
    "isVisible": "este vizibil",
    "issue-count": "{issues, plural, =0 {fără probleme} =1 {o problemă} other {# probleme}}",
    "issues": "probleme",
    "item": "element",
    "items": "elemente",
    "job title": "numele jobului",
    "job-title": "{jobTitle} la {organization}",
    "joined time": "Data înrolarii",
    "justify content": "aranjarea conținutului",
    "keep it on map when it is small": "ține-l pe hartă când este mic",
    "key": "cheie",
    "label": "etichetă",
    "label style": "label style",
    "landing page": "landing page",
    "language": "limba",
    "languages": "limbi",
    "last activity": "Ultima activitate",
    "last change": "ultima schimbare",
    "latest version": "ultima versiune",
    "latitude": "latitudine",
    "layer": "strat",
    "layer-count": "{count, plural, =0 {niciun strat} =1 {1 strat} other {# straturi}}",
    "layers": "straturi",
    "layout": "aspect",
    "leaders": "lideri",
    "leave a message to our team": "leave a message to our team",
    "license": "licență",
    "light": "light",
    "line": "linie",
    "line color": "culoarea liniei",
    "line height": "line height",
    "line marker": "semnul liniei",
    "lineDash": "linie punctată",
    "lineHeight": "lățimea liniei",
    "lines": "linii",
    "link": "legătură",
    "link list": "link list",
    "linkedin": "linkedin",
    "list": "list",
    "list customization": "list customization",
    "list view": "list view",
    "load more": "load more",
    "local name": "denumire locală",
    "locale": "cod locale",
    "location": "localizare",
    "location mode": "modul de localizare",
    "location-city": "Oraș",
    "location-country": "Țară",
    "location-postalCode": "Cod poștal",
    "location-province": "Provincie",
    "login": "autentificare",
    "logo": "logo",
    "longitude": "longitudine",
    "mailersend.com": "mailersend.com",
    "mailersend.com key": "cheia mailersend.com",
    "main picture": "poza principală",
    "main title": "main title",
    "make private": "anulează publicarea",
    "make public": "publică",
    "manage": "administrează",
    "manage team": "gestionază echipa",
    "manage-section": {
      "deleting": "se șterge {name}",
      "map-calendar": "Events from this calendar will use locations from",
      "map-mask": "Elementele acestei hărți vor fi mascate cu elementele din",
      "map-survey": "Răspunsurile din această campanie vor fi adăugate pe harta",
      "no-map-calendar": "Events from this calendar will not use map locations.",
      "no-map-mask": "Elementele acestei hărți nu vor fi mascate.",
      "no-map-survey": "Răspunsurile din această campanie nu vor fi adăugate pe o hartă.",
      "site-note": "creat {time}, la {distance} depărtare",
      "unset-team": "Această înregistrare nu are echipă. Trebuie să introduci una pentru a avea o autoritate validă."
    },
    "map": "hartă",
    "map extent": "cadru hartă",
    "map feature": "map feature",
    "map files": "fișierele hărții",
    "map map view": "vizualizare hartă",
    "map sheet": "fișa hărții",
    "map view": "vizualizare hartă",
    "mapping area": "zona de cartare",
    "maps": "hărți",
    "maps options": "maps options",
    "marker": "marker",
    "mask": "mască",
    "mask points": "maschează elemente",
    "masked feature info": "Mascarea localizării e activă, deci locul afișat public va fi imprecis.",
    "masking-precision-message": "Actualizarea la noua valoare poate dura până la 1 oră pentru datele existente. Numărul de zecimale setate va determina câte zecimale sunt păstrate din valoarea localizării inițiale. De exemplu, pentru o valoare de `0 zecimale`, o poziție de genul `32.42587` va deveni `32`, iar pentru o valoare de `1 zecimală` aceeași poziție va deveni `32.4`.",
    "match based on a field": "potrivire bazată pe un câmp",
    "member": "membru",
    "member of": "membru în",
    "member-since": "Membru din {time}",
    "members": "membri",
    "menu": "meniu",
    "menu buttons": "menu buttons",
    "menu functionality": "menu functionality",
    "menu item": "element de meniu",
    "menu style": "menu style",
    "message": "message",
    "message-alert-attributes-missing": "There are no attributes.",
    "message-alert-duplicate-icon-name": "Folosești două simboluri cu același nume: `{name}`. Scoate-l pe cel din `{iconSet}`.",
    "message-alert-duplicate-icon-name-default": "Folosești două simboluri cu același nume: `{name}`. Eliminați pe cel din lista implicită. ",
    "message-alert-duplicate-icon-name-optional": "Folosești două simboluri cu același nume: `{name}`. Eliminați pe cel din lista opțională. ",
    "message-alert-file-already-imported": "Acest fișier a fost importat anterior. Aveți grijă să nu duplicați înregistrările existente.",
    "message-alert-file-not-analyzed-yet": "Acest fișier nu a fost încă analizat.",
    "message-alert-file-without-preview": "Acest fișier nu poate fi previzualizat.",
    "message-alert-icon-set-private": "Setul de simboluri este privat, iar aceast simbol nu este vizibil de utilizatorii care nu fac parte din echipa ta.",
    "message-alert-invalid-connection-info": "The connection configuration is invalid.",
    "message-alert-invalid-connection-info-type": "The selected connection type is not supported yet.",
    "message-alert-license-not-set": "Harta nu are licență. Setarea unuia îi ajută pe alții să știe cum pot folosi harta.",
    "message-alert-map-with-unpublished-basemaps": "Această hartă utilizează hărți de bază private: `{names}`. Utilizatorii din afara echipei dvs. nu vor putea vedea corect această hartă.",
    "message-alert-map-with-unrelated-basemaps": "Această hartă folosește hărți de bază aparținând altor echipe: `{names}`. Repară această problemă.",
    "message-alert-map-with-unrelated-iconsets": "Această hartă folosește seturi de simboluri aparținând altor echipe: `{names}`. Repară această problemă.",
    "message-alert-missing-connection-info-type": "Select a type before setting the connection info.",
    "message-alert-missing-destination": "The destination is not set.",
    "message-alert-missing-fields": "There are no fields to be mapped.",
    "message-alert-page-private": "Această pagină este privată.",
    "message-alert-parent-icon-private": "Proprietățile moștenite nu sunt accesibile utilizatorilor care nu fac parte din echipa dvs., deoarece pictograma părinte este privată.",
    "message-alert-pictures-missing": "There are no pictures.",
    "message-alert-select-icon": "Trebuie să setați o imagine pentru aceast simbol.",
    "message-alert-sounds-missing": "There are no sounds.",
    "message-alert-survey-add-notification": "Colaboratorii autentificați vor primi o notificare la adăugare.",
    "message-alert-survey-no-notifications": "Campania nu are notificări configurate.",
    "message-copy-paste-emails": "Directly paste in new emails from a spreadsheet or similar list.",
    "message-dns-setup": "Pentru a accesa acest domeniu, creați o nouă înregistrare DNS",
    "message-dns-verification": "Pentru a verifica calitatea de proprietar al domeniului dvs., adăugați cheia de mai sus la o înregistrare TXT din configurația DNS.",
    "message-email-count": "{count, plural, =0 {no emails} =1 {one email} other {# emails}}",
    "message-empty-layout": "Aspectul nu are containere. Trebuie să editați aspectul înainte de a edita conținutul.",
    "message-event-entry-list-empty": "Unfortunately, there are no scheduled dates for this event at the moment.",
    "message-generic-list-empty": "Empty list",
    "message-import-emails": "Grow your newsletter audience by adding or importing contacts who have already given you permission to contact them.",
    "message-map-marker-move": "Trage și mărește harta pentru a seta localizarea indicatorului și apoi apasă butonul `salvează localizarea punctului de pe hartă`",
    "message-map-marker-point-move": "Trage și mărește harta pentru a seta localizarea indicatorului și apoi apasă butonul `salvează`",
    "message-missing-record": "No data source",
    "message-newsletter-send-to-all": "Are you sure you want to send this message to all your subscribers?",
    "message-newsletter-unpublish": "Are you sure you want to unpublish this message?",
    "message-no-page": "No page is set.",
    "message-no-schedule": "There is no defined schedule.",
    "message-no-team-description": "The team has no description outlining its members, activities, and goals.",
    "message-not-right-location": "Is there a better location?",
    "message-page-col-empty": "Această coloană este goală.",
    "message-replace-emails-confirmation": "Are you sure you want to replace the existing emails?",
    "message-reused-gid": "id-ul este deja folosit de o altă componentă",
    "message-selectable-list-empty": "Looks like there’s nothing here. Add a new record to get started!",
    "message-upload-email-file": "Import contacts from a CSV or TXT file.",
    "message-view-min-max-zoom": "Simbolul este vizibil între nivelurile de zoom `{minZoom}` și `{maxZoom}`.",
    "message.no-cover": "There is no picture.",
    "message.no-selected-icons": "There are no selected icons.",
    "message.no-survey-questions": "This survey has no general questions",
    "metrics": {
      "contributors": "{value, plural, =0 {niciun contribuitor} =1 {un contribuitor} other {# contribuitori}}",
      "publicFeatures": "{value, plural, =0 {niciun loc publicat} =1 {un loc publicat} other {# locuri publicate}}",
      "published sites": "{value, plural, =0 {niciun loc publicat} =1 {un loc publicat} other {# locuri publicate}}",
      "publishedSites": "{value, plural, =0 {niciun loc publicat} =1 {un loc publicat} other {# locuri publicate}}",
      "total sites": "{value, plural, =0 {niciun loc} =1 {un loc} other {# locuri}}",
      "totalContributors": "{value, plural, =0 {niciun contribuitor} =1 {un contribuitor} other {# contribuitori}}",
      "totalFeatures": "{value, plural, =0 {niciun loc} =1 {un loc} other {# locuri}}",
      "totalSites": "{value, plural, =0 {niciun loc} =1 {un loc} other {# locuri}}"
    },
    "metrics.publicSites": "metrics.publicSites",
    "min height": "înălțimea minimă",
    "minute": "minute",
    "missing-paragraph-message": "Articolul nu are nciun paragraf.",
    "missing-title-message": "Trebuie să adaugi un titlu de nivelul 1 ca titlul articolului.",
    "mobile": "mobil",
    "mobile options": "opțiuni mobil",
    "mode": "mode",
    "model": "model",
    "model-items": {
      "category": "category",
      "icon": "icon",
      "iconSet": "icon set",
      "map": "map",
      "subcategory": "subcategory",
      "team": "team"
    },
    "month": {
      "short": {
        "april": "Apr",
        "august": "Aug",
        "december": "Dec",
        "february": "Feb",
        "january": "Ian",
        "july": "Iul",
        "june": "Iun",
        "march": "Mar",
        "may": "Mai",
        "november": "Nov",
        "october": "Oct",
        "september": "Sep"
      }
    },
    "month-label": "luna",
    "mtcaptcha-message": "Poți evita mesaje de tip SPAM prin generarea unui cont mtCAPTCHA aici:",
    "multi project mode": "Modul multi-proiect",
    "name": "nume",
    "name and description": "name and description",
    "name used in data exports": "name used in data exports",
    "name-first": "Nume",
    "name-last": "Numele de familie",
    "name-salutation": "Salut",
    "name-title": "Titlu",
    "never": "niciodată",
    "new": "nou",
    "new article": "articol nou",
    "new base map": "adaugă fundal de hartă",
    "new calendar": "new calendar",
    "new campaign": "campanie nouă",
    "new data binding": "new data binding",
    "new event": "new event",
    "new feature": "element nou",
    "new icon": "simbol nou",
    "new icon set": "set nou de simboluri",
    "new issue": "new issue",
    "new language": "limbă nouă",
    "new map": "hartă nouă",
    "new message": "new message",
    "new newsletter": "new newsletter",
    "new page": "pagină noua",
    "new paragraph": "Paragraf nou",
    "new password": "parolă nouă",
    "new presentation": "prezentare nouă",
    "new record": "new {name}",
    "new site": "loc nou",
    "new space": "new space",
    "new survey": "new survey",
    "new team": "echipă nouă",
    "new title": "Titlu nou",
    "new user can join": "utilizatori noi se pot înregistra",
    "new-token-confirm-message": "Asigurați-vă că salvați acest token - nu va mai fi accesibil ulterior.",
    "new-token-expire-message": "Selectați data la care acest token API va expira.",
    "new-token-message": "Completați numele pentru acest token API astfel încât să vă amintiți în viitor de ce îl folosiți.",
    "newsletter": "newsletter",
    "newsletters": "newsletters",
    "no": "nu",
    "no maps available in this area.": "nu sunt hărți disponibile în zonă.",
    "no-answer": "No answer.",
    "no-icon-attributes-message": "Simbolul nu are definite atribute",
    "no-layers-set-message": "Nu este setat niciun strat.",
    "nominatim-url-info": "Vă rugăm să vă asigurați că respectați politica de utilizare a serviciului ales. Pentru mai multe informații, consultați pagina wiki la https://wiki.openstreetmap.org/wiki/Nominatim.",
    "none": "nimic",
    "not published": "nepublicat",
    "not set": "nu este setat",
    "not-found-features-search-message": "Căutarea ta - {term} - nu a returnat niciun element.",
    "not-found-features-suggestion-message": "Încearcă o căutare pe toate hărțile.",
    "not-joined-a-team-user": "{name} nu face parte din nicio echipă.",
    "not-joined-a-team-you": "Nu faci parte din nicio echipă.",
    "nothing": "nimic",
    "notifications": "notificări",
    "offsetX": "offset X",
    "offsetY": "offset Y",
    "on all zoom levels": "pe toate nivelurile de zoom",
    "only for administrators": "doar pentru administratori",
    "only one variable is allowed in the path": "numai o variabilă este permisă în cale",
    "only pending": "doar `în aşteptare`",
    "only published": "doar publicate",
    "only unpublished": "doar nepublicate",
    "opacity": "transparență",
    "open": "deschide",
    "option-panel": {
      "accent color": "accent color",
      "active-color": "active color",
      "allow-address": "allow users to select an address",
      "allow-existing-feature": "allow users to select a feature from the map",
      "allow-gps": "allow users to use their current location",
      "allow-manual": "allow users to select a site on the map",
      "anchor": "anchor",
      "attribute-name": "attribute name",
      "attribute-path": "attribute path",
      "attribute-style": "attribute style",
      "background-color": "background color",
      "border-color": "border color",
      "bs-property-align-items": "align items",
      "bs-property-bg-position-x": "background position x",
      "bs-property-bg-position-y": "background position y",
      "bs-property-bg-repeat": "background repeat",
      "bs-property-bg-size": "background size",
      "bs-property-border-radius": "border radius",
      "bs-property-border-size": "border size",
      "bs-property-border-visibility": "border visibility",
      "bs-property-btn": "style",
      "bs-property-display": "display",
      "bs-property-flex-direction": "direction",
      "bs-property-fw": "font weight",
      "bs-property-justify-content": "justify content",
      "bs-property-line-height": "line height",
      "bs-property-min-lines": "minimum lines height",
      "bs-property-size-btn": "size",
      "bs-property-text": "align",
      "bs-property-text-line-clamp": "line clamp",
      "bs-property-text-size": "size",
      "bs-property-width": "width",
      "button": "button",
      "button-label": "label",
      "button-type": "button type",
      "buttons": "buttons",
      "calendar": "calendar",
      "card-type": "card type",
      "categories": "categories",
      "category-list": "categories",
      "category-style": "category style",
      "change component type": "change component type",
      "color": "color",
      "colors": "colors",
      "component": "component",
      "component-margin": "component margin",
      "container": "container",
      "container-list": "container list",
      "container-properties": "container",
      "content-picker": "content picker",
      "copy": "copy",
      "count": "Count",
      "cover": "cover",
      "css-height": "height",
      "css-size": "size",
      "css-width": "width",
      "currencies": "currencies",
      "currency": "currency",
      "currency-style": "currency style",
      "custom-location-question": "custom location question",
      "custom-question": "custom question",
      "daily": "daily",
      "data-source": "data source",
      "default-category": "default category",
      "default-cover": "default cover",
      "default-value": "default value",
      "description": "description",
      "description-label": "description label",
      "destination": "destination",
      "details": "details",
      "effect": "Effect",
      "email-label": "email label",
      "empty-message": "empty message",
      "enable-full-map-view": "enable full map view",
      "enable-map-interaction": "Map interaction",
      "enable-pinning": "enable pinning",
      "entries": "entries",
      "example-model-id": "example model id",
      "extra-fields": "extra fields",
      "feature-hover": "On feature hover",
      "feature-selection": "On feature selection",
      "filter-index": "filter",
      "flex-config": "flex box configuration",
      "floating-labels": "enable floating labels",
      "fly-over-animation": "Fly over animation",
      "form": "form",
      "full-description": "use full description",
      "gutter": "gutter",
      "heading": "heading",
      "height": "height",
      "hover-color": "hover color",
      "icon-container": "icon container",
      "icon-set": "icon set",
      "icon-style": "icon style",
      "icons": "icons",
      "icons-label": "icons label",
      "image-size": "Image size",
      "input-group": "group input",
      "is-mandatory": "is required",
      "items-per-page": "items per page",
      "justify-content": "justify content",
      "label": "label",
      "label-customization": "label customization",
      "label-success": "success label",
      "layers": "layers",
      "layout": "layout",
      "link": "link",
      "link-name": "link name",
      "list-count": "list count",
      "logo": "logo",
      "map": "map",
      "margin": "margin",
      "max-items": "max items",
      "menu-button-title": "button {index}",
      "menu-button-title-child": "dropdown {index}",
      "message-label": "message label",
      "mode": "Mode",
      "model": "model",
      "model-name": "name",
      "monthly": "monthly",
      "name": "name",
      "name-label": "name label",
      "newsletter": "newsletter",
      "next-date-with-location": "next date with location",
      "next-occurrence": "next occurrence",
      "norepeat": "one time",
      "open-in-new-tab": "open in a new tab",
      "padding": "padding",
      "paddings": "paddings",
      "paragraph": "paragraph",
      "paragraph-index": "paragraph index (counting from 0)",
      "paste": "paste",
      "paste style": "paste style",
      "picture": "picture",
      "picture-options": "picture options",
      "pictures": "pictures",
      "placeholder-text": "placeholder text",
      "position-x": "x",
      "position-y": "y",
      "predefined colors": "predefined colors",
      "price": "price",
      "price-details": "price-details",
      "price-list": "price list",
      "primary-photo": "primary photo",
      "properties": "properties",
      "property": "property",
      "proportion": "proportion",
      "restrict-view": "Restrict view",
      "revisions": "revisions",
      "row-properties": "row",
      "schedule": "schedule",
      "selected color": "selected color",
      "send-to": "send to",
      "server-type": "server type",
      "shades": "shades",
      "show search": "show search",
      "show-base-map-selection": "Show base map selection",
      "show-content-after": "Show the content after",
      "show-photo-list": "show photo list",
      "show-primary-photo-attributions": "show primary photo attributions",
      "show-title": "show title",
      "show-user-location": "Show user location",
      "show-zoom-buttons": "Show zoom buttons",
      "size": "size",
      "size-mode": "size mode",
      "sizing-mode": "mode",
      "slot-name": "slot name",
      "slot-properties": "slot",
      "source": "source",
      "source-field": "source field",
      "source-model-key": "source model key",
      "space": "space",
      "stat-customization": "stat customization",
      "stats": "stats",
      "store-type": "store type",
      "style": "style",
      "styles": "styles",
      "subcategory-style": "subcategory style",
      "subject": "subject",
      "subtitle": "subtitle",
      "text": "text",
      "text-color": "text color",
      "text-font": "font family",
      "text-list": "text list",
      "text-style": "text style",
      "tiled": "tiled",
      "title": "title",
      "unlink global component": "unlink global component",
      "url": "url",
      "use-fixed-value": "value",
      "use-the-current-model": "use the current model",
      "use-the-default-model": "use the default model",
      "use-value-from": "use the value from",
      "value": "value",
      "version": "version",
      "vertical-align": "vertical align",
      "vertical-size": "vertical size",
      "visibility": "visibility",
      "weekly": "weekly",
      "width": "width",
      "with-search": "with search",
      "yearly": "yearly"
    },
    "optional": "opțional",
    "optional icons": "simboluri opționale",
    "options": "options",
    "or": "sau",
    "or go to the last slide": "or go to the last slide",
    "orange": "orange",
    "order": "order",
    "organization": "organizație",
    "original author": "autor original",
    "other names": "alte nume",
    "other-option": "Altele",
    "other-option-input-label": "Vă rugăm să specificați dacă ați selectat „Altele”.",
    "overlay": "suprapunere",
    "owners": "proprietari",
    "paddings": "spațieri",
    "page": "pagină",
    "page-not-found": {
      "contact-admin": "Te rugăm să contactezi administratorul dacă crezi că aceasta este o greșeală.",
      "message": "Asigură-te ca pagina există și nu a fost mutată.",
      "title": "Pagina nu a fost găsită"
    },
    "pages": "pagini",
    "pagination-label": "{current} din {total}",
    "paragraph": "paragraf",
    "parameters": "parameters",
    "parent icon": "simbol părinte",
    "parent-icon": "simbol părinte",
    "password": "parolă",
    "password to use for authentication": "parola folosită pentru autentificare",
    "password-change-success": "Ai schimbat parola cu succes pentru `{name}`.\"",
    "path": "cale",
    "personal settings": "setări personale",
    "photos": "fotografii",
    "pick a location on the map": "Alege un loc de pe hartă",
    "pick it on the map": "Pick it on the map",
    "picture": "imagine",
    "pictures": "imagini",
    "pink": "pink",
    "places": "locuri",
    "plain": "simplu",
    "point": "punct",
    "points": "puncte",
    "polygon": "poligon",
    "polygon marker": "semnul poligonului",
    "polygons": "poligoane",
    "position": "poziție",
    "position-details": "Cum vrei să selectezi locul?",
    "predefined area": "zonă predefinită",
    "predefined colors": "culori predefinite",
    "preferences": "preferințe",
    "preparing": "pregătire",
    "presentation": "prezentare",
    "presentations": "prezentări",
    "preview": "previzualizare",
    "preview-import": {
      "alert": "Nu se poate converti `{key}` la `{destination}` deoarece `{error}`",
      "empty-maps": "Înregistrarea trebuie să aibă cel puțin o hartă.",
      "empty-name": "Înregistrarea nu are nume.",
      "invalid-position": "Înregistrarea trebuie să conțină un geojson valid.",
      "invalid-type": "Înregistrarea trebuie să fie un obiect.",
      "missing-fields": "Înregistrarea trebuie să aibă toate câmpurile obligatorii `{vars}`"
    },
    "price offer": "price offer",
    "primary": "principal",
    "primary background color": "primary background color",
    "primary border color": "primary border color",
    "primary text color": "primary text color",
    "privacy Policy": "politica de confidențialitate",
    "private key": "cheia privată",
    "profile": "profil",
    "profile card": "profile card",
    "profile-current-status-message": "Acest emoji și mesaj vor apărea pe profilul și pe alte pagini ale aplicației.",
    "profile-fill-in-message": "Your public profile is not finished.",
    "profile-fill-in-message-link": "Click here to update your profile.",
    "profile-main-settings-message": "Această informație va apărea pe profilul tău.",
    "profile-public-avatar-message": "Poți seta avatarul tău aici.",
    "progress": "progres",
    "promote to admin": "promovează la administrator",
    "promote user": "promovează utilizator",
    "promote-user-question": "Ești sigur că vrei să promovezi utilizatorul `{name}`?",
    "promote-user-success-message": "Ai promovat cu succes pe `{name}` la nivel de administrator.",
    "property": "property",
    "property name": "numele proprietății",
    "proportion": "proportion",
    "propose": "propose",
    "public profile": "profil public",
    "publish": "publică",
    "publish now": "publish now",
    "purple": "purple",
    "question": "question",
    "question-description": "question for the feature description",
    "question-icons": "question for the icons that the contributor can choose",
    "question-message": {
      "duplicated-name": "There is already a question with the same name.",
      "empty-icon-list": "You haven't picked any icons. You need to select at least one icon to make the question relevant.",
      "hidden-description": "The user won't be asked to enter a description.",
      "hidden-email": "The user won't be asked to enter an email.",
      "hidden-icons": "The user won't be asked to pick icons.",
      "hidden-name": "The user won't be asked to enter a name.",
      "hidden-pictures": "The user won't be asked to upload pictures.",
      "hidden-position": "The user won't be asked to set a location.",
      "hidden-sounds": "The user won't be asked to upload sounds.",
      "no-name": "The name is not set. We recommend to use one or two words separated by a dash.",
      "no-question": "The question is not set.",
      "no-type": "The type is not set.",
      "no-user-questions": "There are no questions about the contributor's identity. The answer will be added anonymously."
    },
    "question-name": "question for the feature name",
    "question-pictures": "question for adding pictures",
    "question-position": "question for the feature location",
    "question-sounds": "question for adding sounds",
    "questions": "întrebări",
    "reCAPTCHA": "reCAPTCHA",
    "read more...": "citește mai mult...",
    "recaptcha-message": "Poți evita mesaje de tip SPAM prin generarea unui cont reCAPTCHA v3 aici:",
    "recent searches": "căutări recente",
    "record name": "record name",
    "record-status": {
      "draft": "draft",
      "pending": "pending",
      "sending": "sending",
      "sent": "sent"
    },
    "rectangle": "dreptunghi",
    "red": "red",
    "redirect": "redirect",
    "redirects": "redirects",
    "register": "înregistrează-te",
    "register here.": "creează un cont aici.",
    "register-section": {
      "accept-message": "Acceptă",
      "accept-message-privacy-policy": "I accept the privacy policy.",
      "accept-message-terms": "I accept the terms of this service.",
      "message-read-privacy-policy": "Click here to read the privacy policy.",
      "message-read-terms": "Click here to read the terms."
    },
    "register-success-message": "Contul tău a fost creat! Verifică-ți emailul pentru linkul de activare.",
    "registration": "înregistrare",
    "reject": "reject",
    "release date": "release date",
    "remoteId": "remoteId",
    "remove member": "șterge membru",
    "remove-member-message": "Ești sigur că vrei să ștergi pe `{name}` din echipă?",
    "repeat-daily-until": "daily between `{begin}` and `{end}`",
    "repeat-monthly-until": "monthly between `{begin}` and `{end}`",
    "repeat-weekly-until": "weekly between `{begin}` and `{end}`",
    "repeat-yearly-until": "yearly between `{begin}` and `{end}`",
    "repeats": "Repeats",
    "repetition": {
      "daily": "Daily",
      "monthly": "Monthly",
      "norepeat": "No",
      "weekly": "Weekly",
      "yearly": "Yearly"
    },
    "replace existing emails": "replace existing emails",
    "report a problem": "raportează o problemă",
    "report issues on Gitlab": "raporteaza probleme în Gitlab",
    "request a new one.": "cere unul nou.",
    "required": "Required",
    "resend e-mail": "Retrimite e-mail",
    "reset": "resetează",
    "reset password": "resetează parolă",
    "restored_site_alert_message": "Acest loc a fost restaurat dintr-o sesiune anterioară. Poți termina editarea sau poți reseta formularul.",
    "restored_site_alert_title": "Locul tau a fost restaurat!",
    "revert": "revert",
    "reviewed-on": "on",
    "revisions": "revisions",
    "role": "rol",
    "roles-guests": "Guest",
    "roles-leaders": "Leader",
    "roles-members": "Member",
    "roles-owners": "Owner",
    "roles-unknown": "unknown",
    "rotate": "rotește",
    "running": "în curs de execuție",
    "salutation-mr": "Dl",
    "salutation-mrs": "Dna",
    "salutation-ms": "Dra",
    "salutation-other": "Alta",
    "save": "salvează",
    "save as a new copy": "salvează o copie nouă",
    "save the pin location": "salvează localizarea punctului",
    "scale": "scară",
    "schedule": "schedule",
    "scheduled": "programat",
    "scope": "scop",
    "search": "caută",
    "search a place by name or enter a text": "search a place by name or enter a text",
    "search an address": "caută o adresă",
    "search by name or email": "caută după nume sau email",
    "search options": "search options",
    "search panel expands all available icons": "search panel expands all available icons",
    "search something here": "caută ceva aici",
    "search team": "search team",
    "search-with-results": "rezultatele căutării pentru « {term} »",
    "search-without-results": "nu există rezultate pentru « {term} »",
    "secondary": "secundare",
    "secondary background color": "secondary background color",
    "secondary border color": "secondary border color",
    "secondary text color": "secondary text color",
    "secret key": "cheie secretă",
    "select": "select",
    "select a date from the calendar": "select a date from the calendar",
    "select a file": "selectați un fișier",
    "select a value from the list": "alege o valoare din listă",
    "select all": "select all",
    "select file": "alege fișier",
    "select files": "select files",
    "select icons": "alege simboluri",
    "select one or more values from the list": "alege una sau mai multe opțiuni din listă",
    "select users that you want to add...": "alege utilizatorii pe care vrei să îi adaugi...",
    "selected background color": "selected background color",
    "selected color": "culoarea selectată",
    "selected picture": "selected picture",
    "selected text color": "selected text color",
    "selection": {
      "allItems": "toate elementele sunt selectate",
      "clear": "șterge selecția",
      "items": "{number, plural, =0 {nimic selectat} =1 {un element selectat} other {# elemente selectate}}"
    },
    "send": "trimite",
    "send newsletter": "send newsletter",
    "send test email": "trimite e-mail de test",
    "send to all recipients": "send to all recipients",
    "service available only for registered users": "serviciu disponibil doar pentru utilizatorii înregistrați",
    "set": "aplică",
    "set a css color": "alege o culoare css",
    "set a float number": "alege un număr cu virgulă mobilă",
    "set a list of integers separated by comma": "alege o listă de numere întregi separate de virgulă",
    "set an integer number": "alege un număr întreg",
    "set as pending": "setează în așteptare",
    "settings": "setări",
    "shades": "nuanțe",
    "shadow": "shadow",
    "shape": "formă",
    "show advanced details": "afișează detalii avansate",
    "show all": "arată tot",
    "show all features": "arată toate elementele",
    "show all icons": "afișează toate simbolurile",
    "show details": "arată detalii",
    "show download links": "afișați linkuri de descărcare",
    "show end slide": "show end slide",
    "show in fullscreen": "arată pe tot ecranul",
    "show intro": "show intro",
    "show less": "arată mai puțin",
    "show login": "show login",
    "show logo": "show logo",
    "show map": "arată hartă",
    "show more": "arată mai mult",
    "show private contributions": "arată contribuții private",
    "show team page": "show team page",
    "show the contributions calendar": "arată calendarul contribuțiilor",
    "show the map info box": "show the map info box",
    "show welcome presentation to new users": "Afișați prezentarea de bun venit noilor utilizatori",
    "showAsLineAfterZoom": "afișați ca linie după nivelul de zoom",
    "side picture": "fotografie alăturată",
    "sign in": "autentificare",
    "sign out": "deconectare",
    "site": "loc",
    "site key": "cheie site",
    "sites": "locuri",
    "size": "dimensiune",
    "skype": "skype",
    "slide": "slide",
    "slide-number": "slide {slideNumber}",
    "slug": "slug (componentă unică de identificare în URL)",
    "slug-double-dash": "The slug should not contain two consecutive dashes.",
    "slug-generic-invalid": "You can only use lower case characters, numbers and dashes.",
    "slug-invalid-chars": "The slug contains invalid chars.",
    "slug-no-space": "The slug should not contain spaces.",
    "slug-no-uppercase": "The slug should not contain upper case chars.",
    "slug-no-value": "This article has no slug. The id will be used to identify this article.",
    "slug-taken": "The slug is already used by another article.",
    "smtp": "smtp",
    "sounds": "sunete",
    "source": "source",
    "space": "space",
    "space dashboard": "space dashboard",
    "space-needed-message": "Se pare că în prezent nu ai spații disponibile. Mai întâi trebuie să creezi un spațiu.",
    "space-no-custom-font-style-message": "There is no customized font style.",
    "spaces": "spații",
    "spaces domains": "spaces domains",
    "spotlight-not-data-found": "spotlight-not-data-found",
    "start": "început",
    "start date": "data începerii",
    "startTLS": "startTLS",
    "started on": "început la",
    "starting start": "Starting `{start}`.",
    "stats": {
      "map-views": "Map views",
      "newsletter-emails": "Emails sent",
      "newsletter-interactions": "Emails interactions",
      "newsletter-open": "Emails opened",
      "newsletter-subscribers": "Subscribers",
      "survey-answer-contributors": "Survey contributors",
      "survey-contributions": "Survey contributions",
      "title": "Stats"
    },
    "status": "stare",
    "status message": "mesaj de status",
    "store type": "tipul magazinului",
    "style": "stil",
    "styles": "stiluri",
    "subcategory": "subcategorie",
    "subheading": "subtitlu",
    "subheading text": "text subtitlu",
    "subject": "subject",
    "submenu item": "submenu item",
    "submit": "trimite",
    "submitted": "submitted",
    "submitted-by": "by",
    "submitted-by-anonymous": "by an anonymous user",
    "subscription": {
      "domains": "Domains",
      "expire": "Until",
      "hours": "{value, plural, =0 {none} =1 {one hour} other {# hours}}",
      "name": "Name",
      "remaining-billable-support": "Billable support",
      "remaining-free-support": "Remaining support",
      "title": "Subscription"
    },
    "success": "succes",
    "suggest a photo": "sugerează o imagine",
    "suggest photo": "suggest photo",
    "suggestions": "sugestii",
    "surface": "suprafață",
    "survey": "survey",
    "survey answer": "survey answer",
    "survey answers": "survey answers",
    "survey card list": "lista de carduri de campanie",
    "survey form": "survey form",
    "survey options": "opțiuni campanii",
    "survey-answer-review-pending": "This contribution needs a review from some one from the team.",
    "survey-answer-review-processing": "This contribution is being processed.",
    "survey-answer-review-published": "This contribution is approved and published.",
    "survey-answer-review-rejected": "This contribution is rejected.",
    "survey-answer-review-unknown": "This contribution can not be reviewed.",
    "survey-answer-review-unpublished": "This contribution is approved and unpublished.",
    "survey-contribute-map-pending-message": "wants to contribute to",
    "survey-contribute-map-published-message": "contributed to",
    "survey-contribute-map-rejected-message": "did not contribute to",
    "survey-feature-description-disabled": "The user will not be prompted for a description.",
    "survey-feature-description-enabled": "The user will be prompted with the question:",
    "survey-feature-description-label": "întrebarea pentru câmpul de descriere",
    "survey-feature-icons-disabled": "The user will not be prompted to select icons.",
    "survey-feature-icons-enabled": "The user can select at least one icon and will be prompted with the question:",
    "survey-feature-icons-label": "simboluri",
    "survey-feature-location-enabled": "The user will be asked:",
    "survey-feature-location-label": "location",
    "survey-feature-name-disabled": "The feature name will be generated on submit and the user will not be prompted for a name.",
    "survey-feature-name-enabled": "The user will be prompted with the question:",
    "survey-feature-name-label": "întrebarea pentru câmpul de nume",
    "survey-feature-name-prefix": "prefixul numelui caracteristicii",
    "survey-feature-not-selected": "Before submitting the form, you need to select a feature on the map.",
    "survey-feature-pictures-default": "The user can add photos. They are not mandatory.",
    "survey-feature-pictures-enabled": "The user will be prompted with the question:",
    "survey-feature-pictures-label": "Photos",
    "survey-feature-pictures-mandatory": "The user can add photos. They are required.",
    "survey-location-field-message": "The user will be able to select a site:",
    "survey-locationAddress": "by searching an address",
    "survey-locationExistingFeature": "by using an existing map feature",
    "survey-locationGps": "by using their current location",
    "survey-locationManual": "by selecting a point on a map",
    "survey-no-address-found": "There are no addresses matching your search terms.",
    "survey-optional-icons-label": "Aceasta este lista de simboluri pe care o poate selecta colaboratorul.",
    "survey-optional-icons-question": "Optional icons list:",
    "survey-point-outside-extent": "The selected point is outside the map extent. It might not be interesting for this survey.",
    "survey-registered-users-only-message": "Pentru a contribui la această campanie, trebuie să vă conectați la contul dvs.",
    "survey-registered-users-only-title": "Autentificare necesară",
    "survey-registration-mandatory-label": "se limitează la utilizatorii înregistrați",
    "survey-settings-description-label-placeholder": "introduceți eticheta pentru întrebarea câmpului de descriere",
    "survey-settings-feature-name-prefix-placeholder": "introduceți un prefix personalizat pentru răspunsuri fără câmpul de nume",
    "survey-settings-icons-label-placeholder": "introduceți eticheta pentru întrebarea câmpului simboluri",
    "survey-settings-name-label-placeholder": "introduceți eticheta pentru întrebarea câmpului de nume",
    "survey-success-message": "Răspunsul tău a fost salvat și asteaptă să fie verificat.",
    "survey-success-title": "Răspunsul a fost salvat",
    "surveys": "surveys",
    "surveys for": "campanii pentru",
    "tablet": "tabletă",
    "tablet options": "opțiuni tabletă",
    "tagline": "slogan",
    "teal": "teal",
    "team": "echipă",
    "team dashboard": "team dashboard",
    "team maps": "hărțile echipei",
    "team surveys": "campaniile echipei",
    "team-guest-count": "{count, plural, =0 {niciun musafir} =1 {un musafir} other {# musafiri}}",
    "team-member-count": "{count, plural, =0 {niciun membru} =1 {un membru} other {# membrii}}",
    "team-needed-message": "Se pare că momentan nu ai echipe disponibile. Creează o echipă prima dată.",
    "team-role": {
      "guests": "guest",
      "leaders": "leader",
      "members": "member",
      "owners": "owner",
      "unknown": "unknown"
    },
    "teams": "echipe",
    "templates": "templates",
    "terms of Service": "termeni și condiții",
    "text": "text",
    "the attribute value is invalid.": "valoarea atributului este invalidă.",
    "the attributes will be removed on save.": "atributele se vor șterge la salvare.",
    "the email used to send the emails": "adresa de e-mail folosită pentru a trimite mesaje",
    "the new password can't be empty": "parola nouă nu poate fi goală",
    "the new password must have at least 10 chars": "parola nouă trebuie să conțină cel puțin 10 caractere",
    "the passwords do not match": "parolele nu coincid",
    "there are no changes": "nu sunt modificari",
    "there are no icons": "nu sunt simboluri",
    "there are no pictures": "nu sunt imagini",
    "there is no date interval set": "There is no date interval set.",
    "there is no description": "nu există descriere",
    "there is nothing to choose": "nu este nimic de ales",
    "this base map has no name": "acest fundal de hartă nu are nume",
    "this calendar does not have a custom record name": "this calendar does not have a custom record name",
    "this map has no tagline": "această hartă nu are slogan",
    "this message has no subject": "this message has no subject",
    "this page has no layout": "această pagină nu are o schemă",
    "this presentation has no layout": "această prezentare nu are o schemă",
    "this presentation has no name": "această prezentare nu are nume",
    "this site has no pictures.": "acest loc nu are imagini.",
    "this site is not linked to a map.": "acest loc nu este atribuit unei hărți.",
    "time interval": "time interval",
    "timeout": "expirat",
    "timezone": "Timezone",
    "title": "titlu",
    "title and article": "title and article",
    "title color": "culoarea titlului",
    "title with buttons": "titlu cu butoane",
    "title-import-emails": "How would you like to add emails?",
    "tls": "TLS",
    "to": "to",
    "toggle dropdown": "selectează din listă",
    "top": "sus",
    "topmost": "cel mai sus",
    "translate": "tradu",
    "translate to": "tradu în {language}",
    "translation": "traducere",
    "trigger": "trigger",
    "type": "tip",
    "type of encryption protocol to use": "tipul protocolului de criptare folosit",
    "typography": "typography",
    "unknown": "necunoscut",
    "unknown error": "eroare necunoscută",
    "unpublish": "anulează publicarea",
    "unpublish newsletter": "unpublish newsletter",
    "until end": "Until `{end}`.",
    "unverified": "neverificat",
    "update": "actualizează",
    "update fields and get a preview": "actualizați câmpurile și obțineți o previzualizare",
    "update password": "actualizează parola",
    "updates": "noutăți",
    "upload": "încarcă",
    "upload a file": "upload a file",
    "use my current location": "folosește localizarea mea curentă",
    "use the parent image": "folosiți imaginea părinte",
    "user": "user",
    "user is admin": "**{name}** este administrator.",
    "user is regular user": "**{name}** este un utilizator normal.",
    "user name": "nume utilizator",
    "user name to use for authentication": "numele de utilizator folosit la autentificare",
    "username or public name": "nume de utilizator sau numele public",
    "users": "utilizatori",
    "value": "valoare",
    "vector tiles url": "adresă de tip vector tiles",
    "verified": "verificat",
    "verify": "verifică",
    "vertical gutters": "rigole verticale",
    "view": "view",
    "view articles": "view articles",
    "view features": "vizualizează elementele",
    "view icons": "vizualizare simboluri",
    "view issues": "vezi probleme",
    "view log": "vizualizează jurnal",
    "view more": "vezi mai mult",
    "view site": "vizualizează loc",
    "view sites": "vizualizează locuri",
    "view the site in browse mode": "vizualizează locul în modul explorare",
    "visibility": "vizibilitate",
    "visibility on mobile": "vizibilitatea pe mobil",
    "visibility options": "visibility options",
    "visibility-pending": "în aşteptare",
    "visibility-private": "privat",
    "visibility-public": "public",
    "visible": "vizibil",
    "warning": "avertisment",
    "website url": "adresă website",
    "weight": "greutate",
    "welcome": "bine ai venit",
    "welcome email": "welcome email",
    "welcome presentation": "prezentare de bun venit",
    "welcome to": "bine ai venit pe",
    "what is map name?": "care este numele hărții?",
    "what is nearby?": "ce se află în apropiere?",
    "what is team name?": "care este numele echipei?",
    "what is the article slug?": "ce este slugul articolului?",
    "what is the base map name?": "care este numele fundalului de hartă?",
    "what is the calendar name?": "what is the calendar name?",
    "what is the data binding name?": "what is the data binding name?",
    "what is the event name?": "what is the {name} name?",
    "what is the feature name?": "Care este numele elementului?",
    "what is the icon name?": "care este numele simbolului?",
    "what is the map tagline?": "care este sloganul hărții?",
    "what is the message subject?": "what is the message subject?",
    "what is the newsletter description?": "what is the newsletter description?",
    "what is the newsletter name?": "what is the newsletter name?",
    "what is the page name?": "care este numele paginii?",
    "what is the page slug?": "ce este slugul paginii?",
    "what is the presentation name?": "what is the presentation name?",
    "what is the presentation slug?": "what is the presentation slug?",
    "what is the set name?": "care este numele setului?",
    "what is the space name?": "what is the space name?",
    "what is the survey name?": "care este numele campaniei?",
    "what's your username?": "care este numele tău de utilizator?",
    "when": "when",
    "who owns the picture?": "who owns the picture?",
    "width": "lăţime",
    "with icon": "cu simbolul `{name}`",
    "with issues": "with issues",
    "with map": "de pe harta `{name}`",
    "write a few words about the picture": "scrie câteva cuvinte despre imagine",
    "year": "an",
    "yellow": "yellow",
    "yes": "da",
    "yes and remove sites": "da și șterge locurile",
    "you are already loged in.": "ești deja autentificat",
    "you don't have an account?": "nu ai cont?",
    "your API tokens": "codurile tale de token API",
    "your account": "contul tău",
    "your account was activated.": "contul tău a fost activat.",
    "your api key": "cheia ta API",
    "your api token": "codul tău de token API",
    "your base maps": "fundalurile tale de hartă",
    "your icon sets": "seturile tale de simboluri",
    "your maps": "hărțile tale",
    "your profile": "profilul tău",
    "your sites": "locurile tale",
    "your teams": "echipele tale"
  }]];
});