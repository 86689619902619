define("manage/components/manage/answer-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @value={{this.value}}
    ...attributes
  >
    <:view>
      {{#if this.questions}}
        <AnswerList @value={{@value}} @questions={{this.questions}} />
      {{else}}
        <div class="alert alert-warning" role="alert">
          {{t "answer-message.no-contributor-questions"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit as |manager|>
      <Input::QuestionList @questions={{this.questions}} @value={{manager.value}} @onChange={{this.change}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "6A+9Hz2b",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,0,[\"value\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,0,[\"questions\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@value\",\"@questions\"],[[30,6],[30,0,[\"questions\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-warning\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"answer-message.no-contributor-questions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@questions\",\"@value\",\"@onChange\"],[[30,0,[\"questions\"]],[30,7,[\"value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n  \"]],[7]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@value\",\"manager\"],false,[\"manage/field-container\",\"if\",\"answer-list\",\"t\",\"input/question-list\"]]",
    "moduleName": "manage/components/manage/answer-list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageAnswerListComponent = _exports.default = (_class = class ManageAnswerListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value;
    }
    get questions() {
      return this.args.questions?.filter(a => a.isVisible) ?? [];
    }
    change(value) {
      this._value = value;
    }
    save() {
      return this.args.onSave(this.value);
    }
    cancel() {
      this._value = undefined;
      return this.args.onCancel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageAnswerListComponent);
});