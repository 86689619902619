define("manage/components/questions-view", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="questions-view">
    {{#each @value as |item|}}
      <div class="question-item mb-3">
        <div class="question">
          {{item.question}}
          {{#if item.isRequired}}
            <strong class="text-danger">*</strong>
          {{/if}}
        </div>
        <div class="help">
          {{item.help}}
        </div>
        <div class="type">
          {{item.type}}
        </div>
  
        {{#if (eq item.type "icons")}}
          <QuestionViewIcons @value={{item}}/>
        {{/if}}
  
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "xtxhHyzP",
    "block": "[[[10,0],[14,0,\"questions-view\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"question-item mb-3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"question\"],[12],[1,\"\\n        \"],[1,[30,2,[\"question\"]]],[1,\"\\n\"],[41,[30,2,[\"isRequired\"]],[[[1,\"          \"],[10,\"strong\"],[14,0,\"text-danger\"],[12],[1,\"*\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"help\"],[12],[1,\"\\n        \"],[1,[30,2,[\"help\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"type\"],[12],[1,\"\\n        \"],[1,[30,2,[\"type\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,2,[\"type\"]],\"icons\"],null],[[[1,\"        \"],[8,[39,4],null,[[\"@value\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@value\",\"item\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"question-view-icons\"]]",
    "moduleName": "manage/components/questions-view.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class QuestionsViewComponent extends _component2.default {}
  _exports.default = QuestionsViewComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuestionsViewComponent);
});