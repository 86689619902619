define("spaces/components/editor/redirect", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-editors-redirect" ...attributes>
    <Input::Options::Frame class="category-list" @title={{capitalize (t "option-panel.destination")}}>
      <Input::Options::Panel @config={{this.config}} @value={{@value.data.redirect}} @onChange={{this.change}} />
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "jK9oJV91",
    "block": "[[[11,0],[24,0,\"manage-editors-redirect\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"category-list\"]],[[\"@title\"],[[28,[37,1],[[28,[37,2],[\"option-panel.destination\"],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@config\",\"@value\",\"@onChange\"],[[30,0,[\"config\"]],[30,2,[\"data\",\"redirect\"]],[30,0,[\"change\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\"],false,[\"input/options/frame\",\"capitalize\",\"t\",\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/redirect.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageEditorsRedirectComponent = _exports.default = (_class = class ManageEditorsRedirectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        name: "destination",
        type: "link"
      }]);
    }
    change(redirect) {
      return this.args.onChange({
        redirect
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageEditorsRedirectComponent);
});