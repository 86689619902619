define("manage/components/questions-validation", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasReusedName}}
    <div class="alert alert-danger alert" role="alert">
      {{t "question-message.duplicated-name"}}
    </div>
  {{/if}}
  */
  {
    "id": "I7md7NLu",
    "block": "[[[41,[30,0,[\"hasReusedName\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-danger alert\"],[14,\"role\",\"alert\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"question-message.duplicated-name\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"t\"]]",
    "moduleName": "manage/components/questions-validation.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class QuestionsValidationComponent extends _component2.default {
    get selectedItem() {
      const index = this.args.index ?? 0;
      return this.args.value?.[index];
    }
    get hasReusedName() {
      const index = this.args.index ?? 0;
      const name = this.selectedItem?.name;
      if (!name) {
        return;
      }
      return this.args.value?.slice(0, index).find(a => a.name == name);
    }
  }
  _exports.default = QuestionsValidationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuestionsValidationComponent);
});