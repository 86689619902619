define("manage/components/input/manage/schedule", ["exports", "@ember/component", "manage/components/input/manage/calendar-entry-list", "@ember/template-factory"], function (_exports, _component, _calendarEntryList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::ContainerGroup
    @isMainValue={{true}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @onEdit={{@onEdit}}
    @title={{@title}}
    @editablePanel={{@editablePanel}}
    @savingPanel={{@savingPanel}}
    as |editMode|
  >
    {{#if editMode}}
      <Input::Schedule @value={{this.value}} @onChange={{this.change}} />
    {{else}}
      <Schedule @value={{this.value}} />
    {{/if}}
  </Input::ContainerGroup>
  */
  {
    "id": "Cm7v4Xvp",
    "block": "[[[8,[39,0],null,[[\"@isMainValue\",\"@onSave\",\"@onCancel\",\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\"],[true,[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]]]],[5]]]]]],[\"@onEdit\",\"@title\",\"@editablePanel\",\"@savingPanel\",\"editMode\"],false,[\"input/container-group\",\"if\",\"input/schedule\",\"schedule\"]]",
    "moduleName": "manage/components/input/manage/schedule.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class InputManageScheduleComponent extends _calendarEntryList.default {}
  _exports.default = InputManageScheduleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputManageScheduleComponent);
});