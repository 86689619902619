define("manage/components/manage/view-mode", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button {{tooltip "list view"}} class="btn btn-icon btn-list {{if (eq @value.viewMode "list") "btn-primary"}}" disabled={{eq @value.viewMode "list"}} type="button" {{on "click" (fn this.change "list")}}>
    <FaIcon @icon="list"/>
  </button>
  
  <button {{tooltip "card view"}} class="btn btn-icon btn-grid {{if (eq @value.viewMode "grid") "btn-primary"}}" disabled={{eq @value.viewMode "grid"}} type="button" {{on "click" (fn this.change "grid")}}>
    <FaIcon @icon="grip"/>
  </button>
  */
  {
    "id": "hxvMkwqL",
    "block": "[[[11,\"button\"],[16,0,[29,[\"btn btn-icon btn-list \",[52,[28,[37,1],[[30,1,[\"viewMode\"]],\"list\"],null],\"btn-primary\"]]]],[16,\"disabled\",[28,[37,1],[[30,1,[\"viewMode\"]],\"list\"],null]],[24,4,\"button\"],[4,[38,2],[\"list view\"],null],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"change\"]],\"list\"],null]],null],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@icon\"],[\"list\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[16,0,[29,[\"btn btn-icon btn-grid \",[52,[28,[37,1],[[30,1,[\"viewMode\"]],\"grid\"],null],\"btn-primary\"]]]],[16,\"disabled\",[28,[37,1],[[30,1,[\"viewMode\"]],\"grid\"],null]],[24,4,\"button\"],[4,[38,2],[\"card view\"],null],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"change\"]],\"grid\"],null]],null],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@icon\"],[\"grip\"]],null],[1,\"\\n\"],[13]],[\"@value\"],false,[\"if\",\"eq\",\"tooltip\",\"on\",\"fn\",\"fa-icon\"]]",
    "moduleName": "manage/components/manage/view-mode.hbs",
    "isStrictMode": false
  });
  let ManageViewModeComponent = _exports.default = (_class = class ManageViewModeComponent extends _component2.default {
    change(newValue) {
      this.args.value.viewMode = newValue;
      return this.args.onChange?.(this.args.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageViewModeComponent);
});