define("manage/components/manage/link-map", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/array", "models/transforms/link-map", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _array, _linkMap, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}} @value={{@value}} @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{this.cancel}}
    class="manage-link-map" ...attributes>
    <:view>
      {{#unless this.hasValue}}
        <div class="alert alert-info" role="alert">
          {{"The list is empty."}}
        </div>
      {{/unless}}
  
      {{#each-in this.value as |source destination|}}
        <div class="mb-2 redirect-item">
          {{t 'from'}} <b>{{source}}</b> {{t "to"}}
          <b>{{destination.niceString}}</b>
        </div>
      {{/each-in}}
    </:view>
  
    <:edit>
      <Input::ListWithAdd {{did-insert this.createPairs}} class="mt-3" @plusLabel={{t "item"}} @value={{this.pairs}}
        @factoryValue={{this.factoryValue}} @onChange={{this.change}} as |value change|>
        <Input::LinkPair class="redirect-item" @onChange={{change}} @value={{value}} @space={{@space}} />
      </Input::ListWithAdd>
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "pb7QYFWj",
    "block": "[[[8,[39,0],[[24,0,\"manage-link-map\"],[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,6],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"hasValue\"]]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,\"The list is empty.\"],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[30,0,[\"value\"]]],null],null,[[[1,\"      \"],[10,0],[14,0,\"mb-2 redirect-item\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"from\"],null]],[1,\" \"],[10,\"b\"],[12],[1,[30,8]],[13],[1,\" \"],[1,[28,[35,4],[\"to\"],null]],[1,\"\\n        \"],[10,\"b\"],[12],[1,[30,7,[\"niceString\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[7,8]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"mt-3\"],[4,[38,6],[[30,0,[\"createPairs\"]]],null]],[[\"@plusLabel\",\"@value\",\"@factoryValue\",\"@onChange\"],[[28,[37,4],[\"item\"],null],[30,0,[\"pairs\"]],[30,0,[\"factoryValue\"]],[30,0,[\"change\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,7],[[24,0,\"redirect-item\"]],[[\"@onChange\",\"@value\",\"@space\"],[[30,10],[30,9],[30,11]]],null],[1,\"\\n    \"]],[9,10]]]]],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"destination\",\"source\",\"value\",\"change\",\"@space\"],false,[\"manage/field-container\",\"unless\",\"each\",\"-each-in\",\"t\",\"input/list-with-add\",\"did-insert\",\"input/link-pair\"]]",
    "moduleName": "manage/components/manage/link-map.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let Pair = (_class = class Pair {
    constructor() {
      _initializerDefineProperty(this, "source", _descriptor, this);
      _initializerDefineProperty(this, "destination", _descriptor2, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "source", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "destination", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let ManageLinkMapComponent = _exports.default = (_class2 = class ManageLinkMapComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "pairs", _descriptor3, this);
    }
    factoryValue() {
      return new Pair();
    }
    createPairs() {
      let pairs = (0, _array.A)();
      for (const key in this.args.value?.value) {
        const pair = new Pair();
        pair.source = key;
        pair.destination = this.args.value?.value?.[key];
        pairs.addObject(pair);
      }
      this.pairs = pairs;
    }
    get hasValue() {
      return Object.keys(this.value).length > 0;
    }
    get value() {
      if (this.pairs) {
        const result = {};
        for (const pair of this.pairs) {
          result[pair.source] = pair.destination;
        }
        return new _linkMap.LinkMap(result);
      }
      return this.args.value?.value ?? {};
    }
    change(value) {
      this.pairs = value;
    }
    cancel() {
      this.pairs = null;
      return this.args.onCancel();
    }
    save() {
      return this.args.onSave?.(this.value);
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "pairs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createPairs", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createPairs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "change"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageLinkMapComponent);
});