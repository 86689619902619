define("spaces-view/components/state-filters/feature-attribute", ["exports", "@ember/component", "spaces-view/components/state-filters/calendar-attribute", "@ember/object", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _calendarAttribute, _object, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="state-filters-attribute"
    {{did-insert this.setup}}
    {{did-update this.setup @state}}
    ...attributes
  >
    {{#if this.hasSearch}}
      <Filters::SearchOptions
        @niceValue={{this.niceValue}}
        @title={{this.title}}
        @placeholder={{this.placeholder}}
        @onReset={{this.reset}}
        @onSearch={{this.search}}
      >
        {{#if this.results}}
          <hr class="m-0" />
          <div class="list-group list-group-flush">
            {{#each this.results as |value|}}
              <button
                type="button"
                class="list-group-item list-group-item-action"
                {{on "click" (fn this.select value)}}
              >
                {{value}}
              </button>
            {{/each}}
          </div>
        {{/if}}
      </Filters::SearchOptions>
    {{else}}
      <Filters::Options
        @value={{this.niceValue}}
        @title={{this.title}}
        @onChange={{this.reset}}
        class="limit-height"
      >
        <div class="list-group list-group-flush">
          {{#each this.results as |value|}}
            <button
              type="button"
              class="list-group-item list-group-item-action"
              {{on "click" (fn this.select value)}}
            >
              {{value}}
            </button>
          {{/each}}
        </div>
      </Filters::Options>
    {{/if}}
  </div>
  */
  {
    "id": "cFdWr+KU",
    "block": "[[[11,0],[24,0,\"state-filters-attribute\"],[17,1],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"setup\"]],[30,2]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasSearch\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@niceValue\",\"@title\",\"@placeholder\",\"@onReset\",\"@onSearch\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"placeholder\"]],[30,0,[\"reset\"]],[30,0,[\"search\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"results\"]],[[[1,\"        \"],[10,\"hr\"],[14,0,\"m-0\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"select\"]],[30,3]],null]],null],[12],[1,\"\\n              \"],[1,[30,3]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,8],[[24,0,\"limit-height\"]],[[\"@value\",\"@title\",\"@onChange\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"reset\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"          \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"select\"]],[30,4]],null]],null],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@state\",\"value\",\"value\"],false,[\"did-insert\",\"did-update\",\"if\",\"filters/search-options\",\"each\",\"-track-array\",\"on\",\"fn\",\"filters/options\"]]",
    "moduleName": "spaces-view/components/state-filters/feature-attribute.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersFeatureAttributeComponent = _exports.default = (_class = class StateFiltersFeatureAttributeComponent extends _calendarAttribute.default {
    search(term) {
      (0, _runloop.cancel)(this.queryTimer);
      if (!term || term.length < 3) {
        return;
      }
      this.queryTimer = (0, _runloop.later)(async () => {
        try {
          this.results = await this.parentRecord?.getAttributeValues(this.attributeName, term, 10);
        } catch (err) {
          console.log(err);
          this.results = [];
        }
      }, 500);
    }
    async setup() {
      const mapId = this.args.options?.map?.id || this.args.options?.map;
      if (!mapId) {
        return;
      }
      try {
        this.parentRecord = await this.store.findRecord('map', mapId);
        this.results = await this.parentRecord.getAttributeValues(this.attributeName, '', this.hasSearch ? 5 : 50);
      } catch (err) {
        console.log(err);
        this.results = [];
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersFeatureAttributeComponent);
});