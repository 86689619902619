define("manage/components/question-view-icons", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="question-view-icons" {{did-insert this.setup}}>
    {{#if this.icons}}
      {{#each this.icons as |icon|}}
        <Icon @src={{icon}} />
      {{/each}}
    {{else}}
      <div class="alert alert-danger" role="alert">
        {{t "question-message.empty-icon-list"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "tVqExdCk",
    "block": "[[[11,0],[24,0,\"question-view-icons\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"icons\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"icons\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@src\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger\"],[14,\"role\",\"alert\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"question-message.empty-icon-list\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"icon\"],false,[\"did-insert\",\"if\",\"each\",\"-track-array\",\"icon\",\"t\"]]",
    "moduleName": "manage/components/question-view-icons.hbs",
    "isStrictMode": false
  });
  let QuestionViewIconsComponent = _exports.default = (_class = class QuestionViewIconsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "icons", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    async setup() {
      if (!Array.isArray(this.args.value?.options)) {
        return;
      }
      let result = [];
      for (const id of this.args.value.options) {
        let icon = this.store.peekRecord('icon', id);
        if (!icon) {
          icon = await this.store.findRecord('icon', id);
        }
        if (icon) {
          result.push(icon);
        }
      }
      this.icons = result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "icons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuestionViewIconsComponent);
});