define("spaces/components/highlight", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template", "@ember/runloop", "@ember/string", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _template, _runloop, _string, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HighlightedElement = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _class2, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive --}}
  <div class="highlight" {{on "mouseover" this.updateFrames}} {{on "mouseout" this.removeFrames}} {{did-update this.setupSelection @selection}} {{did-insert this.setup}}>
    {{yield}}
  
    {{#each this.list as |value|}}
      <HighlightFrame @value={{value}} @onSelect={{@onSelect}}/>
    {{/each}}
  </div>
  */
  {
    "id": "NvJgG0EJ",
    "block": "[[[11,0],[24,0,\"highlight\"],[4,[38,0],[\"mouseover\",[30,0,[\"updateFrames\"]]],null],[4,[38,0],[\"mouseout\",[30,0,[\"removeFrames\"]]],null],[4,[38,1],[[30,0,[\"setupSelection\"]],[30,1]],null],[4,[38,2],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"list\"]]],null]],null],null,[[[1,\"    \"],[8,[39,6],null,[[\"@value\",\"@onSelect\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[13]],[\"@selection\",\"value\",\"@onSelect\",\"&default\"],false,[\"on\",\"did-update\",\"did-insert\",\"yield\",\"each\",\"-track-array\",\"highlight-frame\"]]",
    "moduleName": "spaces/components/highlight.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let HighlightedElement = _exports.HighlightedElement = (_class = class HighlightedElement {
    constructor(rootElement, element, factor = 1, selectionType = "") {
      _initializerDefineProperty(this, "_element", _descriptor, this);
      _initializerDefineProperty(this, "rootElement", _descriptor2, this);
      _initializerDefineProperty(this, "factor", _descriptor3, this);
      _initializerDefineProperty(this, "selectionType", _descriptor4, this);
      _initializerDefineProperty(this, "parent", _descriptor5, this);
      _initializerDefineProperty(this, "child", _descriptor6, this);
      _initializerDefineProperty(this, "titleWidth", _descriptor7, this);
      _initializerDefineProperty(this, "xTitle", _descriptor8, this);
      this.rootElement = rootElement;
      this.element = element;
      this.factor = factor;
      this.selectionType = selectionType;
    }
    get element() {
      const originalElementFound = this.rootElement.contains(this._element);
      if (!originalElementFound) {
        const path = this._element?.attributes?.getNamedItem?.("data-path")?.value;
        return this.rootElement.querySelector(`[data-path='${path}']`);
      }
      return this._element;
    }
    set element(value) {
      this._element = value;
    }
    get isHighlight() {
      return true;
    }
    get isPrimary() {
      return this.selectionType == "primary";
    }
    set isPrimary(value) {
      if (this.selectionType === "selection") {
        return;
      }
      this.selectionType = value ? "primary" : "";
      if (value) {
        for (const item of [...this.allParents, ...this.allChildren]) {
          item.selectionType = "";
        }
      }
    }
    get allParents() {
      const parents = [];
      let tmp = this.parent;
      while (tmp) {
        parents.push(tmp);
        tmp = tmp.parent;
      }
      return parents;
    }
    get allChildren() {
      const children = [];
      let tmp = this.child;
      while (tmp) {
        children.push(tmp);
        tmp = tmp.child;
      }
      return children;
    }
    get showName() {
      if (!this.child) {
        return true;
      }
      return this.child.name != this.name;
    }
    get xTitleEnd() {
      const distance = this.parent?.showName ? 3 : 1;
      return this.xTitle + this.titleWidth + distance;
    }
    get normalizedName() {
      return `${(0, _string.dasherize)(this.name || "unknown").replace(/[\W_]+/g, "-")}-${(0, _string.dasherize)(this.type || "unknown")}`;
    }
    hasTitleOnTheSameLine(other) {
      return Math.abs(this.top - other.top) < 9;
    }
    overlapsTitle(other) {
      if (!this.hasTitleOnTheSameLine(other)) {
        return false;
      }
      const endsBeforeOther = this.xTitleEnd <= other.xTitle;
      const startsAfterOther = this.xTitle >= other.xTitleEnd;
      if (endsBeforeOther && !startsAfterOther) {
        return false;
      }
      if (startsAfterOther && !endsBeforeOther) {
        return false;
      }
      return true;
    }
    updateXTitle() {
      this.xTitle = this.left;
      if (this.isPrimary) {
        return this.parent?.updateXTitle();
      }
      const allChildren = this.allChildren;
      const overlaps = allChildren.find(a => this.overlapsTitle(a));
      if (!overlaps || !this.allChildren.length) {
        this.xTitle = this.left;
        return this.parent?.updateXTitle();
      }
      const endX = allChildren.filter(a => this.hasTitleOnTheSameLine(a)).map(a => a.xTitleEnd).sort().reverse();
      for (const value of endX) {
        this.xTitle = value;
        const overlaps = allChildren.find(a => this.overlapsTitle(a));
        if (!overlaps) {
          break;
        }
      }
      this.parent?.updateXTitle();
    }
    get titleStyle() {
      return (0, _template.htmlSafe)(`left: ${this.xTitle * this.factor}px; top: ${(this.top - 18) * this.factor}px`);
    }
    get isHidden() {
      const frame = this.element.getBoundingClientRect();
      return frame.width == 0 || frame.height == 0;
    }
    get top() {
      if (this.isHidden) {
        return this.parent?.top;
      }
      const frame = this.element.getBoundingClientRect();
      const rootFrame = this.rootElement.getBoundingClientRect();
      return frame.top - rootFrame.top;
    }
    get left() {
      if (this.isHidden) {
        return this.parent?.left;
      }
      const frame = this.element.getBoundingClientRect();
      const rootFrame = this.rootElement.getBoundingClientRect();
      return frame.left - rootFrame.left;
    }
    get style() {
      const frame = this.element.getBoundingClientRect();
      return (0, _template.htmlSafe)(`width: ${frame.width * this.factor}px; height: ${frame.height * this.factor}px; left: ${this.left * this.factor}px; top: ${this.top * this.factor}px;`);
    }
    get name() {
      return this.element.attributes.getNamedItem("data-name").value;
    }
    get path() {
      return this._element.attributes.getNamedItem("data-path")?.value;
    }
    get type() {
      if (!this.element.hasAttribute("data-type")) {
        return "";
      }
      return this.element.attributes.getNamedItem("data-type").value;
    }
    get gid() {
      if (!this._element.hasAttribute("data-gid")) {
        return "";
      }
      return this._element.attributes.getNamedItem("data-gid").value;
    }
    get editor() {
      if (!this.element.hasAttribute("data-editor")) {
        return "";
      }
      return this.element.attributes.getNamedItem("data-editor").value;
    }
    get title() {
      return `${this.name} (${this.type})`;
    }
    toJSON() {
      const result = {};
      const keys = ["name", "type", "gid", "path", "editor"];
      for (const key of keys) {
        if (this[key]) {
          result[key] = this[key];
        }
      }
      return result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "factor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectionType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "parent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "child", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "titleWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "xTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);
  let HighlightComponent = _exports.default = (_class2 = class HighlightComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "rootElement", _descriptor9, this);
      _initializerDefineProperty(this, "list", _descriptor10, this);
    }
    setup(element) {
      this.rootElement = element;
      this.setupSelection();
    }
    setupSelection() {
      if (!this.rootElement) {
        return true;
      }
      if (!this.args.selection) {
        this.list = [];
        return;
      }
      const attributes = {};
      const fields = ["name", "type", "path", "gid"];
      for (const field of fields) {
        if (this.args.selection[field]) {
          attributes[field] = this.args.selection[field];
        }
      }
      const selector = Object.keys(attributes).map(a => `[data-${a}="${attributes[a]}"]`).join("");
      const element = this.rootElement.querySelector(selector);
      this.list = [new HighlightedElement(this.rootElement, element, this.args.factor, "selection")];
      this.list[0].updateXTitle();
    }
    hiddenChildren(element) {
      let result = [];
      for (const child of element.children) {
        const frame = child.getBoundingClientRect();
        const isHidden = frame.width == 0 || frame.height == 0;
        if (child.hasAttribute("data-name") && isHidden) {
          result.push(child);
        }
      }
      return result;
    }
    updateFrames(ev) {
      if (this.args.selection) {
        return;
      }
      let currentNode = ev.target;
      (0, _runloop.cancel)(this.removeTimer);
      if (currentNode.parentElement?.classList.contains("highlight-title") || currentNode.classList.contains("highlight-title")) {
        return;
      }
      if (ev.altKey) {
        return;
      }
      let nodes = [];
      while (currentNode != this.rootElement) {
        nodes.push(...this.hiddenChildren(currentNode));
        nodes.push(currentNode);
        currentNode = currentNode.parentElement;
      }
      nodes = nodes.filter(a => a.hasAttribute("data-name")).reverse();
      this.list = nodes.map(a => new HighlightedElement(this.rootElement, a, this.args.factor));
      if (!this.list.length) {
        return;
      }
      const lastElement = this.list[this.list.length - 1];
      if (!lastElement) {
        return;
      }
      lastElement.isPrimary = true;
      for (let i = 1; i < this.list.length; i++) {
        this.list[i].parent = this.list[i - 1];
      }
      for (let i = 0; i < this.list.length - 1; i++) {
        this.list[i].child = this.list[i + 1];
      }
      (0, _runloop.later)(() => {
        lastElement.updateXTitle();
      }, 10);
    }
    removeFrames(ev) {
      if (this.args.selection) {
        return;
      }
      if (ev.altKey) {
        return;
      }
      this.removeTimer = (0, _runloop.later)(() => {
        this.list = [];
        this.removeTimer = null;
      }, 10);
    }
  }, (_descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "rootElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "list", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setupSelection", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setupSelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateFrames", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateFrames"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeFrames", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeFrames"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HighlightComponent);
});