define("spaces-view/components/slideshow", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="d-none" {{did-insert this.setup}}></div>
  
  {{#if this.slideshowTarget}}
    {{#in-element this.slideshowTarget}}
      <div
        class="page-col-gallery page-col-gallery-modal page-col-gallery-slideshow d-flex flex-column
          {{if this.isSlideshowPresentable 'presentable'}}
          {{if this.isSlideshowPresenting 'presenting'}}"
        {{on "transitionend" this.checkHideSlideshow}}
      >
        <div class="gallery-header p-2 mb-2 d-flex">
          <button
            type="button"
            class="btn btn-dark btn-close-modal"
            {{on "click" this.prepareHideSlideshow}}
          >
            <FaIcon @icon="times" />
          </button>
          <div class="title p-2 flex-fill">
            {{@title}}
          </div>
        </div>
  
        <div class="large-picture-list">
          <Swiper>
            {{#each @value as |picture|}}
              <div
                class="swiper-slide
                  {{if (eq picture.id @selectedId) 'selected'}}"
              >
                <PictureWithOptions
                  class="page-col-picture cursor-pointer"
                  @name={{picture.name}}
                  @meta={{picture.meta}}
                  @src={{picture.picture}}
                  @hash={{picture.hash}}
                  @options={{this.largePictureValues}}
                />
              </div>
            {{/each}}
          </Swiper>
        </div>
      </div>
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "bRpPeIUe",
    "block": "[[[11,0],[24,0,\"d-none\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"slideshowTarget\"]],[[[40,[[[1,\"    \"],[11,0],[16,0,[29,[\"page-col-gallery page-col-gallery-modal page-col-gallery-slideshow d-flex flex-column\\n        \",[52,[30,0,[\"isSlideshowPresentable\"]],\"presentable\"],\"\\n        \",[52,[30,0,[\"isSlideshowPresenting\"]],\"presenting\"]]]],[4,[38,4],[\"transitionend\",[30,0,[\"checkHideSlideshow\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"gallery-header p-2 mb-2 d-flex\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-dark btn-close-modal\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"prepareHideSlideshow\"]]],null],[12],[1,\"\\n          \"],[8,[39,5],null,[[\"@icon\"],[\"times\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"title p-2 flex-fill\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"large-picture-list\"],[12],[1,\"\\n        \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,2]],null]],null],null,[[[1,\"            \"],[10,0],[15,0,[29,[\"swiper-slide\\n                \",[52,[28,[37,9],[[30,3,[\"id\"]],[30,4]],null],\"selected\"]]]],[12],[1,\"\\n              \"],[8,[39,10],[[24,0,\"page-col-picture cursor-pointer\"]],[[\"@name\",\"@meta\",\"@src\",\"@hash\",\"@options\"],[[30,3,[\"name\"]],[30,3,[\"meta\"]],[30,3,[\"picture\"]],[30,3,[\"hash\"]],[30,0,[\"largePictureValues\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,0,[\"slideshowTarget\"]]],null]]],[]],null]],[\"@title\",\"@value\",\"picture\",\"@selectedId\"],false,[\"did-insert\",\"if\",\"in-element\",\"-in-el-null\",\"on\",\"fa-icon\",\"swiper\",\"each\",\"-track-array\",\"eq\",\"picture-with-options\"]]",
    "moduleName": "spaces-view/components/slideshow.hbs",
    "isStrictMode": false
  });
  let SlideshowComponent = _exports.default = (_class = class SlideshowComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      _initializerDefineProperty(this, "isSlideshowVisible", _descriptor2, this);
      _initializerDefineProperty(this, "isSlideshowPresentable", _descriptor3, this);
      _initializerDefineProperty(this, "isSlideshowPresenting", _descriptor4, this);
      _initializerDefineProperty(this, "slideshowTarget", _descriptor5, this);
    }
    get largePictureValues() {
      return {
        sizing: 'auto',
        classes: [],
        attributions: 'below'
      };
    }
    setup() {
      this.slideshowTarget = document.querySelector('#slideshow-target');
      (0, _runloop.later)(() => {
        this.fullscreen.freezeBody();
        this.isSlideshowPresentable = true;
      }, 1);
    }
    willDestroy() {
      this.fullscreen.unfreezeBody();
    }
    prepareHideSlideshow() {
      this.isSlideshowPresentable = false;
      (0, _runloop.later)(() => {
        const modal = this.slideshowTarget.querySelector('.page-col-gallery-slideshow');
        if (!modal) {
          return;
        }
        const style = getComputedStyle?.(modal);
        if (style.opacity == 0) {
          this.checkHideSlideshow();
        }
      }, 10);
    }
    checkHideSlideshow() {
      if (!this.isSlideshowPresentable) {
        return this.hideSlideshow();
      }
      this.isSlideshowPresenting = true;
    }
    hideSlideshow() {
      this.isSlideshowVisible = false;
      this.isSlideshowPresentable = false;
      this.isSlideshowPresenting = false;
      this.args?.onHide();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSlideshowVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSlideshowPresentable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSlideshowPresenting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "slideshowTarget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepareHideSlideshow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "prepareHideSlideshow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHideSlideshow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkHideSlideshow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideSlideshow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideSlideshow"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SlideshowComponent);
});