define("manage/components/manage/card-team", ["exports", "@ember/component", "manage/components/manage/card", "@ember/service", "@ember/template-factory"], function (_exports, _component, _card, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-card-layout manage-card-team">
  
    {{#if @record.canEdit}}
      <div class="options">
        <Dropdown class="float-end">
          <ModelActions::Record @record={{@record}} @onDelete={{@onDelete}} />
        </Dropdown>
      </div>
    {{/if}}
  
    <div class=" d-flex align-items-center">
      <div class="me-2">
        <div class="cover" style={{this.bgCover}}></div>
      </div>
      <div class="flex-shrink-1">
        <div class="name">{{@record.name}}</div>
        <div class="role">{{t (concat "roles-" @record.currentUserRole)}}</div>
        <div class="description {{unless @record.firstParagraph "text-danger"}}">{{this.firstParagraph}}</div>
      </div>
    </div>
    <div class="action-container">
      <LinkTo class="btn btn-main-action" @route="manage.dashboards.dashboard" @model={{@record.id}}>
        {{t "open"}}
      </LinkTo>
    </div>
  </div>
  */
  {
    "id": "PbZYhY+X",
    "block": "[[[10,0],[14,0,\"manage-card-layout manage-card-team\"],[12],[1,\"\\n\\n\"],[41,[30,1,[\"canEdit\"]],[[[1,\"    \"],[10,0],[14,0,\"options\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"float-end\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@record\",\"@onDelete\"],[[30,1],[30,2]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\" d-flex align-items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"me-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"cover\"],[15,5,[30,0,[\"bgCover\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex-shrink-1\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"role\"],[12],[1,[28,[35,3],[[28,[37,4],[\"roles-\",[30,1,[\"currentUserRole\"]]],null]],null]],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[\"description \",[52,[51,[30,1,[\"firstParagraph\"]]],\"text-danger\"]]]],[12],[1,[30,0,[\"firstParagraph\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"action-container\"],[12],[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn btn-main-action\"]],[[\"@route\",\"@model\"],[\"manage.dashboards.dashboard\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"open\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@record\",\"@onDelete\"],false,[\"if\",\"dropdown\",\"model-actions/record\",\"t\",\"concat\",\"unless\",\"link-to\"]]",
    "moduleName": "manage/components/manage/card-team.hbs",
    "isStrictMode": false
  });
  let ManageCardTeamComponent = _exports.default = (_class = class ManageCardTeamComponent extends _card.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get firstParagraph() {
      if (this.args.record?.firstParagraph) {
        return this.args.record.firstParagraph;
      }
      return this.intl.t("message-no-team-description");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageCardTeamComponent);
});