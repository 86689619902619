define("spaces-view/components/view/list", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="page-col-list">
    {{#each @value.data.textList as |text|}}
      <TextWithOptions class="list-item d-flex" @tag="div" @options={{@value.data.textStyle}} @deviceSize={{@deviceSize}}>{{!
        }}<div><FaIcon
            @icon={{@value.data.iconStyle.name}}
            @size="{{@value.data.iconStyle.size}}x"
            class="me-2 text-{{@value.data.iconStyle.color}}"
          /></div><div>{{text}}</div>{{!
    }}</TextWithOptions>
    {{/each}}
  </div>
  */
  {
    "id": "gdSnGihn",
    "block": "[[[10,0],[14,0,\"page-col-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"data\",\"textList\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,0,\"list-item d-flex\"]],[[\"@tag\",\"@options\",\"@deviceSize\"],[\"div\",[30,1,[\"data\",\"textStyle\"]],[30,3]]],[[\"default\"],[[[[10,0],[12],[8,[39,3],[[16,0,[29,[\"me-2 text-\",[30,1,[\"data\",\"iconStyle\",\"color\"]]]]]],[[\"@icon\",\"@size\"],[[30,1,[\"data\",\"iconStyle\",\"name\"]],[29,[[30,1,[\"data\",\"iconStyle\",\"size\"]],\"x\"]]]],null],[13],[10,0],[12],[1,[30,2]],[13]],[]]]]],[1,\"\\n\"]],[2]],null],[13]],[\"@value\",\"text\",\"@deviceSize\"],false,[\"each\",\"-track-array\",\"text-with-options\",\"fa-icon\"]]",
    "moduleName": "spaces-view/components/view/list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class PageColListComponent extends _component2.default {}
  _exports.default = PageColListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColListComponent);
});