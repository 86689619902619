define("manage/components/input/text-file", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@ember/template-factory"], function (_exports, _component, _component2, _object, _testWaiters, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input class="form-control" type="file" accept={{@accept}} {{on "change" this.readFile}} ...attributes />
  */
  {
    "id": "feXCqPfr",
    "block": "[[[11,\"input\"],[24,0,\"form-control\"],[24,4,\"file\"],[16,\"accept\",[30,1]],[17,2],[4,[38,0],[\"change\",[30,0,[\"readFile\"]]],null],[12],[13]],[\"@accept\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "manage/components/input/text-file.hbs",
    "isStrictMode": false
  });
  let waiter = (0, _testWaiters.buildWaiter)('input-text-file-waiter');
  let InputTextFileComponent = _exports.default = (_class = class InputTextFileComponent extends _component2.default {
    readFile(event) {
      const selectedFile = event.target.files[0];
      let token = waiter.beginAsync();
      const reader = new FileReader();
      reader.onload = async e => {
        try {
          await this.args.onChange?.(atob(e.target.result.split('base64,')[1]));
        } catch (err) {
          console.error(err);
        } finally {
          waiter.endAsync(token);
        }
      };
      reader.onerror = () => {
        waiter.endAsync(token);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "readFile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "readFile"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputTextFileComponent);
});